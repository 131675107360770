import { Component, OnInit, Output } from '@angular/core';
import { saveAs } from 'file-saver';
import { PortfolioService } from 'src/app/services/portfolio/portfolio.service';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss', './agreement.component.mobile.scss'],
})
export class AgreementComponent implements OnInit {
  agreementSignedStatus = [
    "PAYMENT_PENDING",
    "PAYMENT_COMPLETED",
    "PAYMENT_FAILED",
    "PAYMENT_UNDER_VERIFICATION",
    "AGREEMENT_APPROVED",
    "AGREEMENT_SIGNED",
    "AGREEMENT_UNDER_REVIEW",
    "COMPLETED",
  ]
  agreementData: any[] = [];
  agreementStatus: boolean = true;
  isDesktopView: boolean = true;
  isAgreementSignedYet: boolean = false;
  constructor(private portfolioService: PortfolioService, private requestService: RequestService, private responsiveService: ResponsiveService) { }
  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop
    })
    this.callAPI();
  }
  isDownloadSection: boolean = false;
  isUploadSection: boolean = false;
  files: File[] = [];

  toggleSection(section: string): void {
    if (section === `downloadSection`) {
      this.isDownloadSection = !this.isDownloadSection;
      this.isUploadSection = false;
    } else if (section === `uploadSection`) {
      this.isUploadSection = !this.isUploadSection;
      this.isDownloadSection = false;
    }
  }
  callAPI() {
    let userId = localStorage.getItem('userId');
    this.portfolioService.getData(userId).subscribe((res: any) => {
      if (res.statusCode >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res['message'] || "Technical Error",
        })
      } else {
        const latestRequestStatus = res['response'][res['response'].length - 1]['status'];
        if (!this.agreementSignedStatus.includes(latestRequestStatus)) {
          this.isAgreementSignedYet = false;
          return;
        }
        this.isAgreementSignedYet = true;
        for (const iterator of res['response']) {
          if (iterator['vehicleLeaseAgreementStatus'] === 'SIGNED' || iterator['serviceAgreementStatus'] === 'SIGNED') {
            if (iterator['vehicleLeaseAgreementStatus'] === 'SIGNED') {
              const agreement1 = {
                userId: iterator['userId'],
                requestId: iterator['requestId'],
                agreementName: `VEHICLE_LEASE_AGREEMENT`,
              }
              this.agreementData.push(agreement1);
            }
            if (iterator['serviceAgreementStatus'] === 'SIGNED') {
              const agreement2 = {
                userId: iterator['userId'],
                requestId: iterator['requestId'],
                agreementName: `SERVICE_AGREEMENT`,
              }
              this.agreementData.push(agreement2);
            }
          } else {
            this.agreementStatus = false;
          }
        }


      }
    }, err => {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      })
    })
  }

  downloadAgreement(userId: string, reqId: string, documentName: string) {
    this.requestService.downloadSignedAgreementCopy(reqId, documentName).subscribe((res: any) => {
      if (res['statusCode'] >= 300) {
        Swal.fire({
          title: "Error",
          icon: "info",
          text: res['message'] || "Technical Error",
        });
      } else {
        saveAs(res, `Blu-Assure-Agreement-${userId}-${reqId}.pdf`);
      }
    }, (err: any) => {
      Swal.fire({
        title: "Error",
        icon: "info",
        text: "Technical Error",
      });
    })
  }
  previewAgreement(reqId: string, documentName: string) {
    this.requestService.downloadSignedAgreementCopy(reqId, documentName).subscribe((res: any) => {
      if (res['statusCode'] >= 300) {
        Swal.fire({
          title: "Error",
          icon: "info",
          text: res['message'] || "Technical Error",
        });
      } else {
        const blob = new Blob([res], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        URL.revokeObjectURL(blobUrl);
      }
    }, (err: any) => {
      Swal.fire({
        title: "Error",
        icon: "info",
        text: "Technical Error",
      });
    })
  }
}

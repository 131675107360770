<ng-template [ngIf]="isDesktopView">
    <section>
        <div class="mainContainer">
            <div>
                <div class="card wrapper">
                    <div class="card-content">
                        <div class="header d-flex justify-content-center">
                            <div>Select Car Variant To Invest</div>
                        </div>
                        <br>
                        <div class="d-flex" style="overflow-x:scroll;">
                            <div *ngFor="let carVariant of carVariants">
                                <div class="single-card">
                                    <div class="content">{{carVariant.carName}} - ₹<span class="header" style="font-size: 18px;">{{carVariant.carPrice}}</span> /car
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <img class='img-size' [src]="carVariant.imagePath">
                                    </div>
                                    <div class="d-flex justify-content-between" style="margin-left: 14px; margin-right:14px">
                                        <div>Number Of Cars</div>
                                       <div><img src="assets/svgs/minus.svg" (click)="reduceCars(carVariant)" style="cursor: pointer;">  
                                        <input [(ngModel)]="carVariant.numCars" (ngModelChange)="calculateTotalAmount(carVariant)" class="input-size"/> 
                                        <img src="assets/svgs/plus.svg" (click)="addCars(carVariant)" style="cursor: pointer;width: 16px;
                                        height: 16px;">  
                                     </div>
                                    </div>
                                    <hr class="bottom-line"/>
                                    <div class="d-flex justify-content-center" style="margin-bottom:23px">
                                        <div>
                                            Amount to be invested - ₹<span class="header" style="font-size: 14px;">{{carVariant.amountInvested}}</span>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center" style="margin-top: 40px;">
                            <button type="button" class="btn btn-outline-primary custom-button" (click)="navigateToReturnCalculator()"
                            >Calculate returns</button>
                            <button type="button" [ngClass]="{'btn-disabled': !enableInvestNowButton}" [disabled]="!enableInvestNowButton" class="btn btn-primary custom-button" (click)="navigateToDocumentVerification()" style="margin-left: 18px;">
                                Invest Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <br>
</ng-template>
<ng-template [ngIf]="!isDesktopView">
    <div class="mainContainer mobile-width">
        <div>  
            <div class="header mobile-header">
                <div>Select Car Variant To Invest</div>
            </div>
            <br>
            <div *ngFor="let carVariant of carVariants">
                <div class="single-card mobile-card">
                    <div class="mobile-content">{{carVariant.carName}} - ₹<span class="header content-font" >{{carVariant.carPrice}}</span> /car
                    </div>
                    <div class="d-flex justify-content-center">
                        <img class='img-size' style="width: 100%;" [src]="carVariant.imagePath">
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="content-font">Number Of Cars</div>
                        <div><img src="assets/svgs/minus.svg" (click)="reduceCars(carVariant)" style="cursor: pointer;">  
                        <input [(ngModel)]="carVariant.numCars" (ngModelChange)="calculateTotalAmount(carVariant)" class="input-size"/> 
                        <img src="assets/svgs/plus.svg" class="icon-style" (click)="addCars(carVariant)">  
                        </div>
                    </div>
                    <hr class="mobile-bottom-line"/>
                    <div class="d-flex justify-content-center">
                        <div class="content-font">
                            Amount to be invested - ₹<span class="header content-font">{{carVariant.amountInvested}}</span>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center" style="margin-top: 4px;">
                <button type="button" style='font-size: 14px;'class="btn btn-outline-primary custom-button" (click)="navigateToReturnCalculator()"
                >Calculate returns</button>
                <button type="button" style='font-size: 14px;margin-left: 12px;' [ngClass]="{'btn-disabled': !enableInvestNowButton}" [disabled]="!enableInvestNowButton" class="btn btn-primary custom-button" (click)="navigateToDocumentVerification()">
                    Invest Now
                </button>
            </div>
        </div>
    </div>

</ng-template>

<!-- Removed this modal for now. This modal asks user name and address -->
<!-- <div *ngIf="addIndividualDetailsModal">
    <p-dialog [(visible)]="addIndividualDetailsModal" [style]="{ width: '28vw' }" [modal]="true" class="main-wrapper"
        [ngClass]="{'mobile-view': !isDesktopView}" [draggable]="false" [resizable]="false">
        <ng-template pTemplate="header">
            <div class="header d-flex justify-content-center">
                <span>Add Details</span>
            </div>
        </ng-template>
        <label for="entity-name mt-2">Entity Name</label>
        <input pInputText type="text" name="first-name" class="form-control input-text"
                placeholder="Enter your name of entity" [(ngModel)]="entityName"/>
        <label for="entity-name" style="margin-top:16px">Registered address of entity</label>
        <textarea [(ngModel)]='entityAddress' class="form-control input-text" placeholder="Enter address">
            {{entityAddress}}</textarea>
        <div class="d-flex justify-content-center" style="margin-top: 28px;">
            <button (click)='navigateToDocumentVerification()' type="button" class="btn btn-outline-primary custom-button"
            >Skip</button>
            <button (click)="addIndividualDetailsModal = false; showDocumentModel()" type="button" class="btn btn-primary custom-button" style="margin-left: 18px;">
                Save
            </button>
        </div>
    </p-dialog>
</div> -->

<p-dialog [ngClass]="{'mobile-view': !isDesktopView}" [showHeader]="false" [(visible)]="toggleShowModel" [modal]="true"
    class="main-wrapper" [draggable]="false" [resizable]="false" *ngIf="toggleShowModel">
    <div style="padding: 10px; text-align: right;align-items: right;margin-left: auto;margin-right: 0px;"
        *ngIf="isDesktopView">
        <!-- <button type="button" class="close" aria-label="Close" > -->
        <span aria-hidden="true" style="color: #3E4958;font-size: large;cursor: pointer;" (click)="cancel()"><i
            class="fa fa-times"></i></span>
        <!-- </button> -->
      </div>
    <div class="modal-content-wrapper">
        <div class="heading">
            <div class="modal-primary-text">Visualise Return for {{noOfCars}} {{apiRes.assetName}}</div>
            <div class="blue-text" *ngIf="isDesktopView">
                <span (click)="dt.exportCSV()" style="cursor: pointer;">
                    <span style="padding-left:4px"><i class="pi pi-download" ></i></span> &nbsp;Download 
                </span> 
            </div>
            <span aria-hidden="true" style="color: #3E4958;font-size: large;cursor: pointer;" (click)="cancel()"
                *ngIf="!isDesktopView"><i class="fa fa-times"></i></span>
        </div>
        <br/>
        <div>
            <p-table [value]="returnDetails" [columns]="cols" #dt>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                    <ng-container *ngFor="let col of columns">
                        <th  *ngIf="col.isVisible">{{col.header}}</th>
                        <!-- <th>Monthly Earnings</th>
                        <th>GST</th>
                        <th>EMI</th>
                        <th>Net Earnings(exc GST)</th> -->
                    <!-- </tr> -->
                    </ng-container>
                    </tr>
                    <tr *ngIf="displayNone">
                        <td colspan="5"
                          style="font-size: 13px !important; padding: 10% !important; text-align: center;">
                          No data found
                        </td>
                      </tr>
                </ng-template>
                <ng-template pTemplate="body" let-earning let-columns="columns">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.isVisible">
                                <ng-container [ngSwitch]="col.field" >
                                    <ng-container *ngSwitchCase="'month'">
                                        {{ earning.month  }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'monthlyEarning'">
                                        {{earning.monthlyEarning | currency : "INR" : "symbol-narrow" :'0.0-0' }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'gst'">
                                        {{ earning.gst }}%
                                    </ng-container>
                                    <!-- <ng-container *ngIf="showEmi"> -->
                                        <ng-container  *ngSwitchCase="'emi'">
                                            {{ earning.emi  | currency : "INR" : "symbol-narrow" : '0.0-0'}}
                                        </ng-container>
                                    <!-- </ng-container> -->
                                 
                                    <ng-container *ngSwitchCase="'netEarning'">
                                        {{ earning.netEarning  | currency : "INR" : "symbol-narrow" : '0.0-0' }}
                                    </ng-container>
                                </ng-container>
                            </td>
                            <!-- <td>{{ earning.month | date: "dd MMMM, yyyy" }}</td>
                            <td>
                               {{earning.monthlyEarning | currency : "INR" : "symbol-narrow" : "4.2-2" }}
                            </td>
                            <td>{{ earning.gst }}%</td>
                            <td>{{ earning.emi  | currency : "INR" : "symbol-narrow" : "4.2-2"}}</td>
                            <td>{{ earning.netEarning  | currency : "INR" : "symbol-narrow" : "4.2-2" }}</td> -->
                        </ng-container>
                    </tr>
                </ng-template>
                <!-- <ng-template pTemplate="footer">
                    <tr>
                        <td>{{ lastMonth | date: "dd MMMM, yyyy" }}</td>
                        <td>
                            {{totalMonthlyEarning | currency : "INR" : "symbol-narrow" : "4.2-2" }}
                        </td>
                        <td>-</td>
                        <td>{{ totalEmi | currency : "INR" : "symbol-narrow" : "4.2-2"}}</td>
                        <td>{{ totalNetEarning  | currency : "INR" : "symbol-narrow" : "4.2-2" }}</td>
                    </tr>
                </ng-template> -->
            </p-table>
        </div>
        <br *ngIf="isDesktopView">
        <div class="grey-text tds-text">
            <sup>*</sup>Monthly payments will be made after deducting TDS
        </div>
        <br *ngIf="isDesktopView">
        <div class="heading sub-heading">
            <div>Selected Investment Amount:<span  class="modal-primary-text"> {{totalInvestment| currency : "INR" : "symbol-narrow" : '0.0-0' }} </span></div>
            <div [ngClass]="{'mobile-actions': !isDesktopView}">
                <div class="blue-text" *ngIf="!isDesktopView">
                    <span (click)="dt.exportCSV()" style="cursor: pointer;">
                        <span><i style="margin-right:8px" class="pi pi-download"></i></span>Download
                    </span>
                </div>
                <button type="button" class="btn btn-primary" (click)="goToRequestPage()">Invest Now</button>
            </div>
        </div>
    </div>
</p-dialog>
<div class="wrapper" *ngIf="noOfCars != 0" [ngClass]="{'mobile-view': !isDesktopView}">
    <div class="text-contaoner">
        <div class="primary-title">
            <span>Vehicle Summary</span>
        </div>
        <br />
        <div class="secondary-title">
            <span>Check all your vehicle details here</span>
            <span>Total Number of leased cars :<span class="noOfCars"> {{noOfCars}}</span></span>
        </div>
    </div>
    <br />
    <div>
        <div class="main-block">

            <p-table [value]="carDetails" styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Sr No</th>
                        <th>Car Details</th>
                        <th>Leased on</th>
                        <th>Tenure maturity</th>
                        <th>Kms travelled</th>
                        <th>Insurance policy</th>
                        <th>Document RC</th>
                        <th>Zone</th>
                        <th>CO2 saved</th>
                        <!-- <th> Track</th> -->
                    </tr>
                    <tr *ngIf="displayNone">
                        <td colspan="9"
                            style="font-size: 13px !important; padding: 10% !important; text-align: center;">
                            No data found
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-car let-i="rowIndex">
                    <tr>
                        <td>{{ i+1 }}</td>
                        <td>
                            <div>
                                <span>Car Name: {{car.vehicleName}}</span>
                                <br />
                                <span>Car Specifications:</span>
                                <br />
                                <ol type="a" class="list-wrapper">
                                    <li>Vehcile No: {{car.registrationNo ?? "NA"}}</li>
                                    <li>Fuel Type: {{car.fuelType}}</li>
                                    <li>Range: {{car.range}}</li>
                                    <li>Battery Capacity: {{car.batteryCapacity}}</li>
                                </ol>
                            </div>
                        </td>
                        <td>{{ car.leasedOn | date: "dd MMMM, yyyy" }}</td>
                        <td>{{ car.leasedTill | date: "dd MMMM, yyyy" }}</td>
                        <td>{{ car.odometerReading ?? 0 }} Kms</td>
                        <td><a href={{car.insurancePolicy}} target="_blank" *ngIf="car.insurancePolicy"><span
                                    style="padding-left:4px"><i class="pi pi-download"></i></span> &nbsp;Download</a>
                        </td>
                        <td><a href={{car.documentRc}} target="_blank" *ngIf="car.documentRc"><span
                                    style="padding-left:4px"><i class="pi pi-download"></i></span> &nbsp;Download</a>
                        </td>
                        <td>{{ car.geography }} </td>
                        <td>{{ car.co2Saved ?? 0}} Kgs</td>
                        <!-- <td > <span class="track"><i class="fa fa-location-arrow"  aria-hidden="true"></i> </span></td> -->
                    </tr>
                </ng-template>
            </p-table>

        </div>
    </div>

</div>
<div class="image-container" *ngIf="noOfCars == 0">
    <img src="../../../../../assets/images/no-return.svg" alt="">
    <p class="pending-header">No vehicle summary found</p>
</div>
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var gtag:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private router: Router){
    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd));

    window.history.forward();
    navEndEvent$.subscribe((e: any) => {
      gtag('config', 'G-9WVJ0LGGLT', {'page_path':e.urlAfterRedirects});
    });
  }
}

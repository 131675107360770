<ng-template [ngIf]="isDesktopView">
    <div>
        <section>
            <div class="card-container">
                <div class="card single-card" *ngFor="let item of uspCards">
                    <div class="card-text">
                        <div>
                            <img [src]="item.img" alt="" />
                        </div>
                        <div>
                            <p class="sub-heading">{{item.text}}</p>
                        </div>
                        <div>
                            <h4 class="heading">{{item.title}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-template>

<ng-template [ngIf]="!isDesktopView">
    <div class="mobile-view">
        <section>
            <div class="container">
                <div class="info-card" *ngFor="let item of uspCards">
                    <div class="info-card-text">
                        <div>
                            <img [src]="item.img" alt="" />
                        </div>
                        <div>
                            <p class="sub-heading">{{item.text}}</p>
                        </div>
                        <div>
                            <h4 class="heading">{{item.title}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-template>
<div class="about-vehicle-container" [ngClass]="{ 'mobile-view': !isDesktopView }">
<div *ngFor="let car of carsData;let i = index" style="margin-bottom: 24px;">
    <div class="header">
        <p class="title">
            {{i+1}}. {{car.carName}}
        </p>
    </div>
<ng-container *ngIf="!isDesktopView">
    <div class="vehicle-image-container" [style.backgroundImage]="'url(' + car.vehicleImagePath + ')'">
    </div>
</ng-container>
<div class="table-container" style="margin-top: 8px;">
    <div class="d-flex">
        <table>
            <tr>
                <th class="table-header">Specifications</th>
                <th class="table-header">{{car.spec}}</th>
            </tr>
            <tr>
                <td>Boot Space</td>
                <td>{{car.bootSpace}}</td>
            </tr>
            <tr>
                <td>0% to 80% with fast charger</td>
                <td>{{car.fastCharging}}</td>
            </tr>
            <tr>
                <td>0% to 80% with slow charger</td>
                <td>{{car.slowCharging}}</td>
            </tr>
            <tr>
                <td>Range</td>
                <td>{{car.range}}</td>
            </tr>
            <tr>
                <td>Battery Capacity</td>
                <td>{{car.batteryCapacity}}</td>
            </tr>

        </table>
        <div *ngIf="isDesktopView" class="vehicle-image-container" style="margin-left:30px; border-radius: 14px;" [style.backgroundImage]="'url(' + car.vehicleImagePath + ')'">
    </div>
    </div>
</div>
</div>
</div>
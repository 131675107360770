import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-doc-list-model',
  templateUrl: './doc-list-model.component.html',
  styleUrls: ['./doc-list-model.component.scss', './doc-list-model.component.mobile.scss']
})
export class DocListModelComponent implements OnInit, OnChanges{

  toggleShowModel: boolean = false;
  docList:any =[]
  @Input() requestType:string = "Individual";
  @Input() showModel:boolean = false;
  @Output() hideModel:EventEmitter<boolean> = new EventEmitter<boolean>;
  @Output() changeScreen = new EventEmitter<string>();
  isDesktopView: boolean = true;

  constructor(private requestService: RequestService, private responsiveService: ResponsiveService) { }

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    this.generateDocList();
  }
  ngOnChanges(changes: SimpleChanges): void {
      if(changes['showModel']){
        this.toggleShowModel  = this.showModel;
      }
  }

  generateDocList(){
    this.docList = [
      {
        id: 1,
        title: "KYC document",
        docList: ["Aadhar Card", "Pan Card", "GST Certificate"]
      }
      // {
      //   id:2,
      //   title:"Financials",
      //   docList:["2 latest ITR with Computation","Balance Sheet and more.."]
      // },
      // {
      //   id:3,
      //   title:"Other Documents",
      //   docList:["Aadhar Card","Driving License, voter id card, passport.."]
      // }
    ]
  }

  cancel(){
    this.hideModel.emit(false);
  }

  proceedToFileUpload(){
    this.cancel();
    let newRequestObj = this.requestService.getNewRequestObj();
    this.requestService.setRequestType(this.requestType);
    let userId = localStorage.getItem('userId');
    let userIdInNo = 0;
    if(userId !== null)
      userIdInNo = parseInt(userId,10);
      const carItems = localStorage.getItem('carVariants');
      let carVariants = carItems ? JSON.parse(carItems) : [];
      let totalCars = 0;
      let unitFinancialDetailsId:any = [];
      carVariants.map((car:any) => {
          unitFinancialDetailsId.push({id: car.id, noOfAssets: car.numCars })
          totalCars = totalCars + car.numCars;
      })
    let data:any = {
      'noOfAssets': totalCars,
      'requestType': this.requestType,
      'userId':userIdInNo,
      'paymentType':'UPFRONT_PAYMENT',
      'unitFinancialDetailsId':unitFinancialDetailsId
    }
    this.requestService.createNewRequest(data).subscribe((response:any)=>{
      if(response['statusCode']>=300){
        Swal.fire({
          title: "Error",
          icon: "error",
          text:  response['message'] || "Technical Error",
        });
      }else{
        this.requestService.setLatestRequestStatus(`KYC_INITIATED`);
        this.requestService.setLatestRequestId(response['response']['requestId']);
        this.changeScreen.emit();
      }
    })

  }
  


}

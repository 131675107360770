import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, pipe } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class CanActivateRouteGuard implements CanActivate {
  constructor( private router: Router,private authService:AuthenticationService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const context = this;
    let rolesURL = {  "MENU_DASHBOARD_ASSETS" : "/dashboard/assets",
                      "MENU_DASHBOARD_PORTFOLIO":  "/dashboard/portfolio",
                     }
    let userMenuRoles={}
    return new Promise(function canActivate(resolve, reject) {
      if(!localStorage.getItem("authToken"))
        {
            context.router.navigateByUrl('/auth/login');
          return reject(false);
        }

      context.authService.checkToken().subscribe(async (responseServer: any) => {
        if (responseServer.statusCode >= 300) {
          localStorage.removeItem('authToken')
          
          Swal.fire({
            title: "Error",
            icon: "error",
            text:  responseServer['message'] || "Unable to validate user",
          }).then(res=>{
            context.router.navigateByUrl('/auth/login');
          });
          return reject(false);
        }
        else {
          localStorage.setItem('ssoId', responseServer.response.userDetails.ssoId)
          localStorage.setItem('email',responseServer.response.userDetails.email)
          localStorage.setItem('phoneNo',responseServer.response.userDetails.phone)
          localStorage.setItem('firstName',responseServer.response.userDetails.firstName)
          localStorage.setItem('lastName',responseServer.response.userDetails.lastName)
        }
        return resolve(true);

      }, (error) => { });
    });
  }

}

<div class="file-upload-container">
    <div class="custom-dropzone" ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>
            <div>
                <img src="../../../../../assets/svgs/upload.svg" alt="" srcset="" />
                <button *ngIf="isDesktopView" class="btn btn-secondary" type="button" (click)="onSelect($event)">
                    Browse Files
                </button>
                <p *ngIf="isDesktopView">or</p>
                <p *ngIf="isDesktopView">drag and drop files here</p>
                <br *ngIf="!isDesktopView">
                <p class="file-upload-label" *ngIf="!isDesktopView">Click here to upload</p>
                <p>(File size : maximum 5 MB)</p>
            </div>
        </ngx-dropzone-label>
        <input type="file" #fileInput style="display: none" (change)="onSelect($event)" />
        <ngx-dropzone-preview class="preview" ngProjectAs="ngx-dropzone-preview" *ngFor="let file of files"
            [file]="file" [removable]="true" (removed)="onRemove(file)">
            <ngx-dropzone-label>{{ file.name }} ({{ file.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
    </div>
    <div class="actions">
        <div class="skip cursor" (click)="skipFile()" *ngIf="showSkip">
            Skip
        </div>
        <button class="btn btn-primary btn-upload" type="button" (click)="uploadFiles()"
            [ngClass]="{ 'btn-disabled': !hasFilesSelected() }">
            Upload
        </button>
    </div>
</div>
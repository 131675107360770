<p-dialog class="dialog" [ngClass]="{'mobile-view': isMobileView}" [(visible)]="visible" [modal]="true"
  [draggable]="false" [resizable]="false" [closable]="false" [showHeader]="false" #dlg>
  <div class="container">
    <br>
    <div class="header">
      <h4>Enter OTP to verify</h4>
    </div>
    <br>
    <div class="sub-header">Enter the 6-digit verification code sent to</div>
    <strong class="receiver-text">{{ receiver }}</strong>
    <strong *ngIf="showFailedOTPMessage" class="wronOtpMessage">Wrong OTP entered</strong>
    <br>
    <div class="otp-row">
      <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)" #ngxotp>
      </ngx-otp-input>
    </div>
    <br>
    <div class="form-item">
      <button type="button" class="btn btn-primary customFormButton" [ngClass]="{'btn-disabled': !otpEntered}"
        (click)="onVerifyClick()">
        Verify
      </button>
    </div>
    <br>
    <div class="resend-otp" id="countdown" *ngIf="!resendOtpEnabled">{{timerText}}</div>
    <div class="resend-otp btn" id="countdown" *ngIf="resendOtpEnabled" (click)="resendOtp()">Resend OTP</div>
  </div>
</p-dialog>
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class AesService {

  constructor() { }

  secretkey = environment.AESKey; //Length 32
  key = CryptoJS.enc.Utf8.parse(this.secretkey);
  iv = CryptoJS.enc.Utf8.parse(this.secretkey.substring(0, 16));

  encrypt(data:any) {
    return CryptoJS.AES.encrypt(data, this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  }

  decrypt(encryptedData:any) {
    let decrypted = CryptoJS.AES.decrypt(encryptedData, this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}

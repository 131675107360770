import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 10000000) {
      // If value is greater than or equal to 1 crore, format it in crores with 2 decimal places.
      const croreValue = (value / 10000000).toFixed(2);
      return  '₹ ' + croreValue + ' Cr';
    } else {
      // If value is less than 1 crore, format it in lakhs with 2 decimal places.
      const lakhValue = (value / 100000).toFixed(2);
      return '₹ ' + lakhValue + ' Lakh';
    }
  }
}

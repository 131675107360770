<p-dialog [(visible)]="toggleShowInvoiceCreationModal" [modal]="true" class="main-wrapper"
    [ngClass]="{'mobile-view': !isDesktopView}" [draggable]="false" [resizable]="false" *ngIf="toggleShowInvoiceCreationModal">
    <div class="modal-header-custom"
        style="padding: 10px; text-align: right;align-items: right;margin-left: auto;margin-right: 0px;">
        <div class="headerText">
            Provide Invoice Number
        </div>
        <!-- <button type="button" class="close" aria-label="Close" > -->
        <span aria-hidden="true" style="color: #3E4958;font-size: large;cursor: pointer;" (click)="cancel()"><i
                class="fa fa-times"></i></span>
        <!-- </button> -->
    </div>
    <div class="content">
        <div class="input-form">
            <div class="p-float-label">
                <input pInputText type="text" name="invoiceNumber" [(ngModel)]="invoiceNumber" maxlength="255" style="width:100%" value=""
                    placeholder="" id="invoiceNumber" (keyup)="validInvoiceNo()"  ngModel />
                <label for="email">Enter Invoice Number </label>
            </div>
            <br/>
            <button type="button" class="btn btn-primary"  style="width:100%"
                    [ngClass]="{'btn-disabled': !enableContinueButton}"
                    [disabled]="!enableContinueButton" (click)="handleContinueButton()">Continue</button>
        </div>
        
    </div>
</p-dialog>
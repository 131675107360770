<p-dialog [(visible)]="toggleShowPaymentUpload" [modal]="true" class="main-wrapper"
    [ngClass]="{'mobile-view': !isDesktopView}" [draggable]="false" [resizable]="false" *ngIf="toggleShowPaymentUpload">
    <div class="modal-header-custom"
        style="padding: 10px; text-align: right;align-items: right;margin-left: auto;margin-right: 0px;">
        <div class="headerText">
            Upload Payment Slip
        </div>
        <!-- <button type="button" class="close" aria-label="Close" > -->
        <span aria-hidden="true" style="color: #3E4958;font-size: large;cursor: pointer;" (click)="cancel()"><i
                class="fa fa-times"></i></span>
        <!-- </button> -->
    </div>
    <div class="content">
        <app-file-upload (processUpload)="uploadCompleted($event)"></app-file-upload>
    </div>
</p-dialog>
import { ErrorHandler,CUSTOM_ELEMENTS_SCHEMA, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './main/login/login.module';
import { DashboardModule } from './main/dashboard/dashboard.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AesService } from './services/aes/aes.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { CommonsModule } from './main/commons/commons.module';
import { FormsModule } from '@angular/forms';
import { AppInterceptor } from './app.interceptor';
import { GlobalErrorHandler } from './guards/global-error-handler';
import { NgxSpinnerModule } from "ngx-spinner";
import { registerLocaleData } from '@angular/common';
import localeIn from '@angular/common/locales/en-IN';

registerLocaleData(localeIn);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    DashboardModule,
    HttpClientModule,
    CommonsModule,
    FormsModule,
    NgxSpinnerModule
  ],
  providers: [
    AesService, 
    AuthenticationService,
    {
      provide:HTTP_INTERCEPTORS,
      useClass:AppInterceptor,
      multi:true

    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    { provide: LOCALE_ID, useValue: 'en-IN' }
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

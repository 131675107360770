import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  API_AGREEMENT_DOWNLOAD,
  API_CREATE_BANK_DETAILS,
  API_CREATE_NEW_REQUEST,
  API_GET_LATEST_REQUEST_STATUS,
  API_GET_REQUEST_DETAILS,
  API_KYC_DOC_LIST,
  API_LEGALITY_SIGN,
  API_LEGALITY_SIGN_DOCUMENT,
  API_LEGALITY_USER_SIGNED,
  API_UPDATE_LATEST_REQUEST_STATUS,
  API_URL_ASSURE_QUERIES
} from 'src/app/config/api.routes';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private _http: HttpClient) {
  }

  latestRequestStatus: string = '';
  latestRequestId: number = 0;
  newRequestObj: any = {};
  noOfDownPaymentCars: any = 10;
  noOfUpFrontPaymentCars: any = 10;

  getLatestRequestStatusFromBE(userId: any): Observable<any> {
    return this._http.get(`${API_GET_LATEST_REQUEST_STATUS}/${userId}`);
  }

  setLatestRequestStatusToBE(requestId: any, status: string): Observable<any> {
    const data = {
      requestId: requestId,
      status: status
    }
    return this._http.put(API_UPDATE_LATEST_REQUEST_STATUS, data);
  }

  getLatestRequestStatus() {
    return localStorage.getItem('latestRequestStatus');
  }

  setLatestRequestStatus(status: string) {
    this.latestRequestStatus = status;
    localStorage.setItem('latestRequestStatus', status);
  }

  createNewRequest(data: any): Observable<any> {
    return this._http.post(API_CREATE_NEW_REQUEST, data);
  }

  setNoOfAssets(data: any) {
    localStorage.setItem('noOfAssets', data);
    this.newRequestObj.noOfAssets = data;
  }

  setNoOfDownPaymentCars(data: any) {
    localStorage.setItem('noOfDownPaymentCars', data);
    this.noOfDownPaymentCars = data;
  }

  setNoOfUpFrontPaymentCars(data: any) {
    localStorage.setItem('noOfUpFrontPaymentCars', data);
    this.noOfUpFrontPaymentCars = data;
  }

  getNoOfDownPaymentCars() {
    return this.noOfDownPaymentCars;
  }

  getNoOfUpFrontPaymentCars() {
    return this.noOfUpFrontPaymentCars;
  }

  setRequestType(data: any) {
    localStorage.setItem('requestType', data);
    this.newRequestObj.requestType = data;
  }

  getRequestType() {
    return localStorage.getItem('requestType');
  }

  setPaymentType(data: string) {
    localStorage.setItem('paymentType', data);
    this.newRequestObj.paymentType = data;
  }

  setUnitDetailsId(data: any) {
    localStorage.setItem('unitDetailsId', data);
    this.newRequestObj.unitDetailsId = data;
  }

  getNewRequestObj() {
    let noOfAssets = localStorage.getItem('noOfAssets');
    let noOfAssetsInNum = 0;
    if (noOfAssets !== null)
      noOfAssetsInNum = parseInt(noOfAssets, 10);

    let unitDetailsId = localStorage.getItem('unitDetailsId');
    let unitDetailsIdInNo = 0;
    if (unitDetailsId !== null)
      unitDetailsIdInNo = parseInt(unitDetailsId, 10);
    return {
      'noOfAssets': noOfAssetsInNum,
      'paymentType': localStorage.getItem('paymentType'),
      'unitDetailsId': unitDetailsIdInNo
    };
  }

  setLatestRequestId(data: number) {
    this.latestRequestId = data;
    localStorage.setItem('latestRequestId', data + '');
  }

  getLatestRequestId() {
    let latestReqId = localStorage.getItem('latestRequestId')
    let latestReqIdInNo = 0;
    if (latestReqId !== null)
      latestReqIdInNo = parseInt(latestReqId, 10);
    return latestReqIdInNo;
  }

  getDocumentsList(requestType: any, paymentType: any): Observable<any> {
    return this._http.get(`${API_KYC_DOC_LIST}/${requestType}/${paymentType}`)
  }

  getRequestDetails(requestId: any): Observable<any> {
    return this._http.get(`${API_GET_REQUEST_DETAILS}/${requestId}`);
  }

  initializeLegalitySignProcess(requestId: any, agreementName: string): Observable<any> {
    return this._http.post(`${API_LEGALITY_SIGN}/${requestId}/${agreementName}`, {});
  }

  sendLegalityDocId(reqId: any, agreementName: string): Observable<any> {
    return this._http.post(`${API_LEGALITY_SIGN_DOCUMENT}/${reqId}/${agreementName}`, {});
  }

  updateAgreementSigned(reqId: any, agreementName: string): Observable<any> {
    return this._http.put(`${API_LEGALITY_USER_SIGNED}/${reqId}/${agreementName}`, {});
  }

  createBankDetails(body: any): Observable<any> {
    return this._http.post(API_CREATE_BANK_DETAILS, body);
  }

  downloadSignedAgreementCopy(requestId: any, documentType: string): Observable<Blob> {
    return this._http.get(`${API_AGREEMENT_DOWNLOAD}/${requestId}/${documentType}`, {responseType: 'blob'});
  }

  sendAssureQueries(data: any) {
    return this._http.post(API_URL_ASSURE_QUERIES, data)
  }
}

<app-navbar></app-navbar>

<div class="main-container" *ngIf="isDesktopView">
    <div class="card-container">
        <img src="assets/svgs/bank.svg" alt="">
        <span class="order-title">Order Status</span>
        <!-- <p class="order-desc">We kindly request you to carefully review the agreement to ensure all the terms and conditions align with our mutual understanding. If you have any questions, concerns, or require any clarifications, we encourage you to reach out to us directly. Our team is readily available to assist you.</p> -->

        <div class="table-container">
        <p-table [columns]="cols" [value]="requestsTableDto" style="width: 100% !important; overflow: hidden;">
            <ng-template pTemplate="header" let-columns>
                <tr class="table-row">
                    <th *ngFor="let col of columns" class="table-header" [ngStyle]="{width: col.width, border: '2px solid blue'}">
                        <p>{{col.label}}</p>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns" class="table-cell" [ngStyle]="{width: col.width}">
                        <div *ngIf="col.field == 'Order_Id'">
                            {{rowData.orderId}}
                        </div>

                        <div *ngIf="col.field == 'Order_Created'">
                            {{rowData.orderCreated}}
                        </div>

                        <div *ngIf="col.field == 'Order_Description'">
                            <div *ngFor="let item of rowData.orderDescription"> 
                                <span><b>Car variant</b> : {{item.assetsDetails.name}}</span><br>
                                <span><b>Number of Cars</b> : {{item.noOfAssets}}</span><br>
                                <span><b>Total Amount</b> : {{item.totalInvestedByUser}}</span><br><br>
                            </div>
                        </div>

                        <div *ngIf="col.field == 'Order_Status'" class="status-container">
                            {{rowData.orderStatus}}
                        </div>


                        <div *ngIf="col.field == 'Action'">
                            <button class="action-cta" *ngIf="rowData.orderStatus != 'COMPLETED'" (click)="clickAction('NOT_COMPLETED')">Complete the Process</button>
                            <button class="action-cta" *ngIf="rowData.orderStatus == 'COMPLETED'" (click)="clickAction('COMPLETED')">VIEW</button>
                        </div>


                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    </div>

</div>






<div class="main-container-mobile" *ngIf="!isDesktopView">
    <div class="card-container-mobile">
        <img src="assets/svgs/bank.svg" alt="">
        <span class="order-title-mobile">Order Status</span>
        <!-- <p class="order-desc-mobile">We kindly request you to carefully review the agreement to ensure all the terms and conditions align with our mutual understanding. If you have any questions, concerns, or require any clarifications, we encourage you to reach out to us directly. Our team is readily available to assist you.</p> -->

        <div class="table-container table-container-mobile">
             
            <table *ngFor="let item of requestsTableDto">
                <tr>
                    <td>
                        Order ID
                    </td>
                    <td>
                        {{item['orderId']}}
                    </td>
                </tr>

                <tr>
                    <td>
                        Order Created
                    </td>
                    <td>
                        {{item['orderCreated']}}
                    </td>
                </tr>

                <tr>
                    <td>
                        Order Description
                    </td>

                    <td>
                       <div class="desc-table" *ngFor="let variant of item['orderDescription']">
                        Car variant : {{variant['assetsDetails']['name']}}
                        Number of Cars : {{variant['noOfAssets']}}
                        Total Amount : {{variant['totalInvestedByUser']}}
                       </div>
                    </td>

                </tr>


                <tr>
                    <td>
                        Order Status
                    </td>
                    <td>
                       <span class="status-container-mobile"> {{item['orderStatus']}}</span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Action
                    </td>
                    <td>
                        <button class="action-cta-mobile" *ngIf="item['orderStatus'] != 'COMPLETED'" (click)="clickAction('NOT_COMPLETED')">Complete the Process</button>
                        <button class="action-cta-mobile" *ngIf="item['orderStatus'] == 'COMPLETED'" (click)="clickAction('COMPLETED')">VIEW</button>
                    </td>
                </tr>
            </table>




       
    </div>
    </div>

</div>
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';
import { AssetsService } from 'src/app/services/assets/assets.service';
@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss','./bank-details.component.mobile.scss'],
})
export class BankDetailsComponent  implements OnInit, OnChanges{
  @Output() changeScreen = new EventEmitter<string>();
  @Output() showUploadPaymentUpload:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()  updateBankDetailsToBE:boolean =false;

  showTable: boolean = true;
  showPaymentUpload: boolean = false;
  isDesktopView: boolean = true;
  bankDetails :any = []
  togglePaymentSlipUpload: boolean = false;
  selectedBankDetail: any;
  vehicleIdToCarMap:any= {};
  carVariants : any = [];
  constructor(private fb: FormBuilder, private requestService: RequestService, private responsiveService: ResponsiveService, private assetsService: AssetsService) {
  }
  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    this.getVehicleDetails()
  }

  getVehicleDetails()
  {
    this.assetsService.getUnitEconmonics().subscribe((result:any)=>{
      if(result.statusCode >= 300){
          Swal.fire({
            title: "Error",
            icon: "error",
            text: result['message'],
          });
        }
      else
      {
        result.response.map((vehicle:any) => {
          this.vehicleIdToCarMap[vehicle.unitFinancialDetailsId] = vehicle.assetName;
        })
        this.getRequestDetails();
      }
      },(err)=>{
        Swal.fire({
          title: "Error",
          icon: "info",
          text: "Technical Error",
        });
      }
    )
  }
  getRequestDetails(){
    let requestId = localStorage.getItem('latestRequestId');
    this.requestService.getRequestDetails(requestId).subscribe((res:any)=>{
     if(res['statusCode']>=300){
      Swal.fire({
        title: "Error",
        icon: "info",
        text:  res['message'] || "Technical Error",
      });
     }else{
      res.response.paymentContractList.map((paymentContract:any) => {
        let failureMessage = ''
        if(paymentContract.paymentStatus === 'FAILED')
        {
          failureMessage = 'Payment verification failed, please try again'
        }
        this.bankDetails.push({
          "ifscCode": "ICIC0000583",
          "accountNumber": "058305006557",
          "accountHolderName": "Jubilant Motorworks Private Limited",
          "bankName": "ICICI Bank Limited",
          "carName": this.vehicleIdToCarMap[paymentContract.vehicleId],
          "assetUrl": this.getImagePath(this.vehicleIdToCarMap[paymentContract.vehicleId]),
          'uploadStatus': paymentContract.paymentStatus,
          "paymentId": paymentContract.id,
          "failureMessage": failureMessage
        })
      })
     }
    },(err)=>{
      Swal.fire({
        title: "Error",
        icon: "info",
        text: "Technical Error",
      });
    })

  }

  getImagePath(carName:any)
  {
    let imgPath = '';
    if(carName.includes('TATA'))
    {
      imgPath = 'assets/images/tata-tigor.png';
    }
    if(carName.includes('MG'))
    {
      imgPath = 'assets/images/mg.png';
    }
    if(carName.includes('BYD'))
    {
      imgPath = 'assets/images/byd.png';
    }
    if(carName.includes('Citroen'))
    {
      imgPath = 'assets/images/citroen.jpg';
    }
    return imgPath;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['updateBankDetailsToBE'] && this.updateBankDetailsToBE){
        this.uploadBankDetails();
    }
  }
  onSubmit() {

  }
  onConfirm(bankDetail:any) {
    this.selectedBankDetail = bankDetail;
    this.showPaymentUpload = true;
    this.togglePaymentSlipUpload = true;
  }


  uploadBankDetails(){
    //change screen and status
    this.requestService.setLatestRequestStatusToBE(localStorage.getItem("latestRequestId"), 'PAYMENT_PENDING').subscribe((res) => {
      if (res['statusCode'] >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res['message'] || "Technical Error",
        });
      } else {
        this.requestService.setLatestRequestStatus('PAYMENT_PENDING');
        this.changeScreen.emit();
      }
    }, (err: any) => {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
    })
  }

  handlePaymentSlipModel(value: any) {
    this.togglePaymentSlipUpload = value;
  }

  handleFileUpload(bankDetail:any)
  {
    this.selectedBankDetail = bankDetail.bankDetail;
  }
}

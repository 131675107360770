<div class="faq-container" [ngClass]="{ 'mobile-view': !isDesktopView }">
    <p class="heading">
        Frequently asked questions
    </p>
    <div *ngFor="let question of activeFaq" class="faq-item">
        <div class="faq-question cursor" (click)="toggleQuestion(question)">
            {{ question.isOpened ? question.questionTitleClose : question.questionTitle }}
            <img *ngIf="!question.isOpened" src="../../../../../assets/images/plus.svg" alt="" srcset="">
            <img *ngIf="question.isOpened" src="../../../../../assets/images/minus.svg" alt="" srcset="">
        </div>
        <div class="faq-answer" *ngIf="question.isOpened">
            <h6>↳</h6>
            <ul [innerHTML]="question.questionContent"></ul>
        </div>
    </div>
    <!-- Uncomment to shown FAQs in chunks of 6 -->
    <!-- <div class="cursor more-faq" (click)="toggleActiveFaq()">
        <p *ngIf="activeFaq.length == 6">+ More</p>
        <p *ngIf="activeFaq.length != 6">- Less</p>
    </div> -->
</div>
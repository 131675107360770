<div class="bank-details-screen" [ngClass]="{'mobile-view': !isDesktopView}">
    <div class="d-flex justify-content-center">
        <img src="../../../../../assets/svgs/bank.svg">
    </div>
    <div class="d-flex justify-content-center">
        <p class="header">Upload Payment Slip</p>
    </div>
    <br *ngIf="!isDesktopView">
    <p class="sub-heading" *ngIf="!showTable">Please provide your Bank details In order to proceed with your transaction
        and to receive returns, we kindly request that you provide us with your payment details. Please ensure that you
        provide accurate and complete information to facilitate a smooth and successful payment process.</p>
    <p class="sub-heading" style="margin-bottom: 0px;" *ngIf="showTable">Below are the bank details. Please proceed with transferring the specified
        amount to the dealer's account. Once the payment is done, kindly upload the payment slip to confirm the
        transaction.
    </p>
    <br *ngIf="!isDesktopView">
    <ng-container *ngIf="showTable">
        <ng-container *ngFor="let bankDetail of bankDetails">
            <div class="blue-header">Payment Details for {{bankDetail.carName}}</div>
                <div class="car-payment-screens">
                    <div class="d-flex">
                        <img *ngIf='isDesktopView' class="img-style" [src]="bankDetail.assetUrl">
                        <div class="info-table-container">
                            <table class="info-table sub-heading">
                                <tr>
                                    <td>Bank Name</td>
                                    <td>{{ bankDetail.bankName }}</td>
                                </tr>
                                <tr>
                                    <td>Account Holder Name</td>
                                    <td>{{ bankDetail.accountHolderName }}</td>
                                </tr>
                                <tr>
                                    <td>Account Number</td>
                                    <td>{{ bankDetail.accountNumber }}</td>
                                </tr>
                                <tr>
                                    <td>IFSC Code</td>
                                    <td>{{ bankDetail.ifscCode }}</td>
                                </tr>
                            </table>
                            <br>
                            <div *ngIf="bankDetail.uploadStatus != 'COMPLETED' && bankDetail.uploadStatus != 'PENDING'  && bankDetail.uploadStatus != 'FAILED'" class="d-flex justify-content-end btn-style">
                                <button class="btn btn-primary" style="width: 60%;font-size:14px" type="button" (click)="onConfirm(bankDetail)">Upload Payment Slip</button>
                            </div>
                            <div *ngIf="bankDetail.uploadStatus === 'COMPLETED'" class="progress-text" style="color:#34B56B">
                                <img src="assets/svgs/success.svg">  Payment is verified
                            </div>
                            <div *ngIf="bankDetail.uploadStatus === 'PENDING'" class="progress-text" style="color:#FDAD13">
                                <img src="assets/svgs/pending.svg"> Your payment is under review. It will take 24 - 48 hours.
                            </div>
                            <div *ngIf="bankDetail.uploadStatus === 'FAILED'" class="d-flex">
                                <div class="progress-text" style="color:#E90000">
                                   <div style="margin-top: 10px;"> <img src="assets/svgs/failed.svg"> {{bankDetail.failureMessage}}</div>
                                </div> <button class="btn btn-primary retry-button" type="button" (click)="onConfirm(bankDetail)">Retry</button>
                            </div>
                        </div>
                    </div>
                </div>
        </ng-container>
    </ng-container>
</div>
<app-payment-slip-upload-modal *ngIf="togglePaymentSlipUpload"
  [selectedBankDetail] = "selectedBankDetail"
  (showUploadPaymentUpload)="handlePaymentSlipModel($event)"
  (isFileUploadSuccess)="handleFileUpload($event)"></app-payment-slip-upload-modal>
import { Component, Input, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-about-bluassure',
  templateUrl: './about-bluassure.component.html',
  styleUrls: ['./about-bluassure.component.scss', './about-bluassure.component.mobile.scss']
})
export class AboutBluassureComponent implements OnInit {
  constructor(private responsiveService: ResponsiveService) { }
  uspCards: any = []
  isDesktopView: boolean = true;
  customOptions: OwlOptions = {
  }

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    this.uspCards = [
      {
        id: 1,
        title: '10 car',
        text: 'Minimum buy in',
        img: '../../../../../assets/images/car-blue.svg'
      },
      {
        id: 2,
        title: 'FAME subsidy',
        text: 'Subsidised purchase',
        img: '../../../../../assets/images/subsidised-blue.svg'
      },
      {
        id: 3,
        title: '14% +',
        text: 'Returns (IRR)',
        img: '../../../../../assets/images/percentage-blue.svg'
      },
      {
        id: 4,
        title: 'Management',
        text: 'End to end account',
        img: '../../../../../assets/images/rupee-blue.svg'
      },
      {
        id: 5,
        title: '4 Years',
        text: 'Time to Maturity',
        img: '../../../../../assets/images/calendar-blue.svg'
      },
      {
        id: 6,
        title: 'Every Month',
        text: 'Advance Payment',
        img: '../../../../../assets/svgs/payment-blue.svg'
      },
      {
        id: 7,
        title: '40% YoY',
        text: 'Depreciation Allowance',
        img: '../../../../../assets/svgs/depreciation-blue.svg'
      },
      {
        id: 8,
        title: 'Assured BuyBack',
        text: 'End of term',
        img: '../../../../../assets/svgs/loan-benifits-blue.svg'
      }
    ]
  }

}

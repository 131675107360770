<app-navbar></app-navbar>
<div [ngClass]="{'mobile-view': !isDesktopView}">
  <div class="progresss progresss-mobile">
    <div class="step-wrapper step-done">
      <div class="step">
        <img src="../../../../assets/svgs/step-done.svg" alt="" srcset="">
        <div class="step-name">Sign Up</div>
      </div>
    </div>
    <div class="step-wrapper" [ngClass]="getProgressBarStatus().invest.class">
      <div class="line"></div>
      <div class="step">
        <img [src]="getProgressBarStatus().invest.imgSource">
        <div class="step-name">Car Variant</div>
      </div>
    </div>
    <div class="step-wrapper" [ngClass]="getProgressBarStatus().document.class">
      <div class="line"></div>
      <div class="step">
        <img [src]="getProgressBarStatus().document.imgSource" alt="" srcset="">
        <div class="step-name">Documents</div>
      </div>
    </div>
    <div class="step-wrapper" [ngClass]="getProgressBarStatus().agreement.class">
      <div class="line"></div>
      <div class="step">
        <img [src]="getProgressBarStatus().agreement.imgSource" alt="" srcset="">
        <div class="step-name">Agreement</div>
      </div>
    </div>
    <div class="step-wrapper" [ngClass]="getProgressBarStatus().paymnet.class">
      <div class="line"></div>
      <div class="step">
        <img [src]="getProgressBarStatus().paymnet.imgSource" alt="" srcset="">
        <div class="step-name">Payment</div>
      </div>
    </div>

    <div class="step-wrapper" [ngClass]="getProgressBarStatus().return.class">
      <div class="line"></div>
      <div class="step">
        <img [src]="getProgressBarStatus().return.imgSource" alt="" srcset="">
        <div class="step-name">Track Return</div>
      </div>
    </div>
  </div>
  <div [ngSwitch]="activeScreen">
    <!-- signup -->
    <app-user-type-screen class="screen-container" *ngSwitchCase="'USER_TYPE_SCREEN'" 
      (changeScreen)="updateLatestReqStatus()" (showDocumentsModel)="handleDocumentsModel($event)" />
  <!-- car variant invest -->
  <app-invest *ngSwitchCase="'INVESTMENT_SCREEN'" (changeScreen)="updateLatestReqStatus()"
  (showDocumentsModel)="handleDocumentsModel($event)"></app-invest>
    <app-document-upload class="screen-container" *ngSwitchCase="'FILE_UPLOAD_SCREEN'"
      (changeScreen)="updateLatestReqStatus()" />
  <!-- document -->
      <app-document-verification class="screen-container" *ngSwitchCase="'DOCUMENT_VERIFICATION_SCREEN'"
      (changeScreen)="updateLatestReqStatus()" />
   <!-- agreement    -->
    <app-agreement-done class="screen-container" *ngSwitchCase="'AGREEMENT_DONE_SCREEN'"
      (changeScreen)="updateLatestReqStatus()" />
   <!-- payment -->
      <app-payment-details class="screen-container" *ngSwitchCase="'PAYMENT_DETAILS_SCREEN'"
      (changeScreen)="updateLatestReqStatus()" />
    <!-- payment -->
      <app-bank-details class="screen-container" *ngSwitchCase="'BANK_DETAILS_SCREEN'"
      (changeScreen)="updateLatestReqStatus()" (showUploadPaymentUpload)="handlePaymentSlipModel($event)"
      [updateBankDetailsToBE]="updateBankDetailsToBE" />
  </div>
</div>


<app-doc-list-model [requestType]="requestTypeSelected" (hideModel)="hideDocListModel($event)"
  [showModel]="toggleDocumentModel" (changeScreen)="updateLatestReqStatus()"></app-doc-list-model>
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ScreenService } from 'src/app/services/screen/screen.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request/request.service';
declare var gtag:any;

@Component({
  selector: 'app-invest',
  templateUrl: './invest.component.html',
  styleUrls: ['./invest.component.scss','./invest.component.mobile.scss']
})
export class InvestComponent implements OnInit {
  @Output() changeScreen = new EventEmitter<string>();
  @Output() showDocumentsModel:EventEmitter<string>= new EventEmitter<string>();
  constructor(private screenService: ScreenService, private router: Router, private assetsService: AssetsService, private spinner: NgxSpinnerService, private responsiveService: ResponsiveService, private requestService: RequestService) { }
  unitEcoApiRes:any = {
    "assetName": "",
    "unitTotalCost":0,
    "unitDownPayment":0,
    "unitLoanAmount":0,
    "unitEmi":0,
    "perEmiIrr":0,
    "irr":0
  }

  carVariants :any = [
  ];
  isDesktopView: boolean = true;
  addIndividualDetailsModal: boolean = false;
  entityName: string = '';
  entityAddress: string = '';
  showDocumentList: boolean = false;
  carsWithQuantity : number = 0;
  enableInvestNowButton: boolean = false;
  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    this.getUnitEconomics();
  }

  getUnitEconomics(){
    this.spinner.show();
    this.assetsService.getUnitEconmonics().subscribe((result:any)=>{
      this.spinner.hide();
      if(result.statusCode >= 300){
          Swal.fire({
            title: "Error",
            icon: "error",
            text: result['message'],
          });
          this.unitEcoApiRes = {
            "assetName": "",
            "unitTotalCost":0,
            "unitDownPayment":0,
            "unitLoanAmount":0,
            "unitEmi":0,
            "perEmiIrr":0,
            "irr":0
          }
        }else{
          this.unitEcoApiRes = result['response'][0];
          const response = result.response;
          response.map((carData : any) => {
            let imgPath = '';
            if(carData.assetName.includes('TATA'))
            {
              imgPath = 'assets/images/tata-tigor.png';
            }
            if(carData.assetName.includes('MG'))
            {
              imgPath = 'assets/images/mg.png';
            }
            if(carData.assetName.includes('BYD'))
            {
              imgPath = 'assets/images/byd.png';
            }
            if(carData.assetName.includes('Citroen'))
            {
              imgPath = 'assets/images/citroen.jpg';
            }
            this.carVariants.push(
              {
                id: carData.unitFinancialDetailsId,
                carName: carData.assetName,
                carPrice: carData.unitTotalCost,
                imagePath: imgPath,
                numCars: 0,
                amountInvested: 0,
              }
            )
          })
        }
      
    },(err)=>{
      this.spinner.hide();
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
      this.unitEcoApiRes = {
        "assetName": "",
        "unitTotalCost":0,
        "unitDownPayment":0,
        "unitLoanAmount":0,
        "unitEmi":0,
        "perEmiIrr":0,
        "irr":0
      }
    })
  }

  changeAssetsScreen(){
    this.screenService.setAssetsScreenName('ASSETS_HOME');
  }

  calculateTotalAmount(carVariant:any)
  {
    carVariant.amountInvested = carVariant.numCars * carVariant.carPrice;
    this.enableInvestNowButton = false;
    let totalCars = 0;
    this.carVariants.map((car:any)=> {
      if(car.numCars>0){
        totalCars = totalCars + car.numCars;
      }
    })
    if(totalCars>=10)
    {
      this.enableInvestNowButton = true;
    }
  }

  reduceCars(carVariant:any)
  {
    if(carVariant.numCars>0)
      {
      carVariant.numCars--;
      }
    this.calculateTotalAmount(carVariant)
  }

  addCars(carVariant:any)
  {
    carVariant.numCars++;
    this.calculateTotalAmount(carVariant)
  }

  navigateToReturnCalculator()
  {
    const queryParams = { type: 'RETURN_CALCULATOR' };
    this.router.navigate(['/dashboard/assets'], { queryParams });
  }

  navigateToDocumentVerification()
  {
    let carVariants = this.carVariants.filter((car:any) => {
      return car.numCars > 0;
    });

    localStorage.setItem('carVariants', JSON.stringify(carVariants))
    let userId = localStorage.getItem("userId");
    this.requestService.setLatestRequestStatus(`INITIATED`);
    this.changeScreen.emit();
  }

}

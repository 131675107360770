import {environment} from '../../environments/environment'

export const BASE_URL: string = environment.baseUrl;
export const COOKIE_URL: string = environment.cookieURL;
export const COOKIE_SUFFIX: string = environment.cookieSuffix;


// Authentication Routes

export const API_URL_LOGIN = `${BASE_URL}/api/v1/auth/login`;
export const API_URL_AUTHENTICATE = `${BASE_URL}/api/v1/auth/authenticate/user`;
export const API_URL_VERIFY_OTP_LOGIN = `${BASE_URL}/api/v1/auth/verify/otp`;
export const API_URL_SIGNUP = `${BASE_URL}/api/v1/auth/signup`;
export const API_URL_CHECK_TOKEN = `${BASE_URL}/api/v1/auth/check/token`;


//portfolio page
export const API_GET_USER_PORTFOLIO = `${BASE_URL}/api/v1/portfolio/user`;
export const API_GET_USER_PORTFOLIO_DETAILS = `${BASE_URL}/api/v1/portfolio/user/details`;
export const API_GET_VEHICLE_SUMMARY = `${BASE_URL}/api/v1/request/vehicle/details`;
export const API_GET_ALL_REQUEST_VEHICLE_SUMMARY = `${BASE_URL}/api/v1/request/vehicle/details`;

//invoice
export const API_GET_ALL_INVOICES = `${BASE_URL}/api/v1/invoice/get/all`;
export const API_GENERATE_INVOICE = `${BASE_URL}/api/v1/invoice/generate`;
export const API_GENERATE_INVOICE_SIGN_URL = `${BASE_URL}/api/v1/agreement/get/authbridge/invoice/sign/url`;
export const API_UPDATE_INVOICE_SIGN_STATUS = `${BASE_URL}/api/v1/agreement/get/authbridge/invoice/update/signed/doc`;
export const API_GET_INVOICE_SIGN_STATUS = `${BASE_URL}/api/v1/agreement/get/authbridge/invoice/sign/status`;

//kyc
export const API_FILE_UPLOAD_KYC = `${BASE_URL}/api/v1/kyc/upload`;
export const API_KYC_DOC_LIST = `${BASE_URL}/api/v1/kyc/documents/list`;

//request
export const API_GET_LATEST_REQUEST_STATUS = `${BASE_URL}/api/v1/portfolio/user`;
export const API_UPDATE_LATEST_REQUEST_STATUS = `${BASE_URL}/api/v1/request/update/status`;
export const API_CREATE_NEW_REQUEST = `${BASE_URL}/api/v1/request`;
export const API_GET_REQUEST_DETAILS = `${BASE_URL}/api/v1/request/details`;
export const API_CREATE_BANK_DETAILS = `${BASE_URL}/api/v1/request/bank/details`;
export const API_LEGALITY_SIGN = `${BASE_URL}/api/v1/agreement/create`;
export const API_LEGALITY_SIGN_DOCUMENT = `${BASE_URL}/api/v1/agreement/esign/details`;
export const API_LEGALITY_USER_SIGNED = `${BASE_URL}/api/v1/agreement/update/status`;
export const API_AGREEMENT_DOWNLOAD = `${BASE_URL}/api/v1/kyc/download`;


//assets page
export const API_GET_UNIT_ECONOMICS = `${BASE_URL}/api/v1/asset/details`

//contact us page
export const API_SEND_HELP_QUERIES = `${BASE_URL}/api/v1/contactus`
export const API_URL_ASSURE_QUERIES = `${BASE_URL}/api/v1/contactus`;


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_SEND_HELP_QUERIES } from '../config/api.routes';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private _http: HttpClient) { }
  sendQueries(data: any) {
    return this._http.post(API_SEND_HELP_QUERIES, data)
  }
}

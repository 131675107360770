import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilesService } from 'src/app/services/files/files.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
@Component({
  selector: 'app-payment-slip-upload-modal',
  templateUrl: './payment-slip-upload-modal.component.html',
  styleUrls: ['./payment-slip-upload-modal.component.scss','./payment-slip-upload-modal.component.mobile.scss']
})
export class PaymentSlipUploadModalComponent implements OnInit, OnChanges {

  toggleShowPaymentUpload: boolean = true;
  isDesktopView: boolean = true;
  @Output() showUploadPaymentUpload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isFileUploadSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedBankDetail:any;
  constructor(private fileUploadService: FilesService, private responsiveService: ResponsiveService, private requestService: RequestService) { }

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop
    })
  }
  ngOnChanges(changes: SimpleChanges): void {

  }

  cancel() {
    this.toggleShowPaymentUpload = false;
    this.showUploadPaymentUpload.emit(false);
  }

  uploadCompleted(files: any) {
    let userId = localStorage.getItem('userId') || "";
    let requestId = localStorage.getItem('latestRequestId') || "";
    let formData = new FormData();
    formData.append('file', files[0]);
    formData.append('userId', userId);
    formData.append('requestId', requestId);
    if(this.selectedBankDetail.carName.includes('TATA'))
    {
      formData.append('kycDocType', 'PAYMENT_RECEIPT_TATA');
    }
    if(this.selectedBankDetail.carName.includes('MG'))
    {
      formData.append('kycDocType', 'PAYMENT_RECEIPT_MG');
    }
    if(this.selectedBankDetail.carName.includes('BYD'))
    {
      formData.append('kycDocType', 'PAYMENT_RECEIPT_BYD');
    }
    console.log("this.selectedBankDetail.carName ", this.selectedBankDetail.carName);
    if(this.selectedBankDetail.carName.includes('Citroen'))
    {
      formData.append('kycDocType', 'PAYMENT_RECEIPT_CITROEN');
    }
    formData.append('paymentId', this.selectedBankDetail.paymentId);
    this.fileUploadService.uploadFile(formData).subscribe((res: any) => {
      this.handlePageStatus()
      if (res['statusCode'] >= 300) {
        this.selectedBankDetail.uploadStatus = 'FAILED';
      } else {
        this.selectedBankDetail.uploadStatus = 'PENDING';
      }
      this.selectedBankDetail.failureMessage = res.message;
      this.isFileUploadSuccess.emit({bankDetail: this.selectedBankDetail});
      this.showUploadPaymentUpload.emit(false);
      this.setCarVariantsData()
    }, (err: any) => {
      this.selectedBankDetail.uploadStatus = 'FAILED';
      this.selectedBankDetail.failureMessage = "Something went wrong, please try again";
      this.isFileUploadSuccess.emit({bankDetail: this.selectedBankDetail});
      this.showUploadPaymentUpload.emit(false);
      this.setCarVariantsData()
      this.handlePageStatus()
    })
  }

  setCarVariantsData()
  {
    let carVariants = JSON.parse(localStorage.getItem('carVariants') || '[]');
    carVariants.map((carVariant:any)=> {
      if (carVariant.carName === this.selectedBankDetail.carName) {
        carVariant.paymentUploadStatus = this.selectedBankDetail.uploadStatus;
        carVariant.failureMessage = this.selectedBankDetail.failureMessage;
      }
    })
    localStorage.setItem('carVariants', JSON.stringify(carVariants));
  }

  handlePageStatus()
  {
    this.requestService.setLatestRequestStatusToBE(localStorage.getItem("latestRequestId"), 'BANK_DETAILS_PENDING').subscribe((res:any) => {
      if (res['statusCode'] >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res['message'] || "Technical Error",
        });
      } else {
        this.requestService.setLatestRequestStatus('BANK_DETAILS_PENDING');
      }
    }, (err: any) => {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
    })
  }
}


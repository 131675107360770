<ng-template [ngIf]="isDesktopView">
  <nav class="navbar fixed-top navbar-expand-lg navbar-light">
    <div class="navbar-content-wrapper">
      <a class="navbar-brand" href="/dashboard">
        <img src="../../../../assets/svgs/blu_logo.svg" width="150" height="30" alt="BluSmart" />
      </a>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto nav-items">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/dashboard/assets']" routerLinkActive="active"
              (click)="scrollToTop()">Home</a>
            <div class="bar" [routerLink]="['/dashboard/assets']" routerLinkActive="active-bar"></div>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/dashboard/portfolio']" routerLinkActive="active"
              (click)="scrollToTop()">My Portfolio</a>
            <div class="bar" [routerLink]="['/dashboard/portfolio']" routerLinkActive="active-bar"></div>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/dashboard/order']" routerLinkActive="active"
              (click)="scrollToTop()">My Order</a>
            <div class="bar" [routerLink]="['/dashboard/order']" routerLinkActive="active-bar"></div>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/dashboard/contactus']" routerLinkActive="active"
              (click)="scrollToTop()">Contact Us</a>
            <div class="bar" [routerLink]="['/dashboard/contactus']" routerLinkActive="active-bar"></div>
          </li>
        </ul>
        <!-- <div class="nav-items">
          <img src="../../../../assets/svgs/notification-icon.svg" alt="" srcset="">
        </div> -->
        <div class="avatar-container nav-items" (click)="menu.toggle($event);handleIcon()">
          <p-avatar [label]="avatarLabel" shape="circle" class="avatar avatar-elements"></p-avatar>
          <span class="avatar-elements">{{userName}}</span>
          <span class="avatar-elements"><i [class]="icon"></i></span>
        </div>
        <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
      </div>
    </div>

  </nav>
</ng-template>
<ng-template [ngIf]="!isDesktopView">
  <div class="mobile-view">
    <nav class="navbar">
      <div class="logo">
        <a class="navbar-brand" href="/dashboard">
          <img src="../../../../assets/svgs/blu_logo.svg" height="20" alt="BluSmart" />
        </a>
      </div>
      <div class="avatar-container nav-items" (click)="menu.toggle($event);handleIcon()">
        <p-avatar [label]="avatarLabel" shape="circle" class="avatar avatar-elements"></p-avatar>
        <span class="avatar-elements">{{userName}}</span>
        <span class="avatar-elements"><i [class]="icon"></i></span>
      </div>
      <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
    </nav>
    <nav class="bottombar">
      <ul class="navbar-nav ml-auto nav-items">
        <li class="nav-item">
          <img src="../../../../assets/images/about-blusmart.svg" [routerLink]="['/dashboard/assets']"
            routerLinkActive="img-active">
          <a class="nav-link" [routerLink]="['/dashboard/assets']" routerLinkActive="active"
            (click)="scrollToTop()">Assets</a>
        </li>
        <li class="nav-item">
          <img src="../../../../assets/svgs/portfolio-icon.svg" [routerLink]="['/dashboard/portfolio']"
            routerLinkActive="img-active">
          <a class="nav-link" [routerLink]="['/dashboard/portfolio']" routerLinkActive="active"
            (click)="scrollToTop()">Portfolio</a>
        </li>

        <li class="nav-item">
          <img src="../../../../assets/svgs/my_order.svg" [routerLink]="['/dashboard/order']"
            routerLinkActive="img-active">
          <a class="nav-link" [routerLink]="['/dashboard/order']" routerLinkActive="active"
            (click)="scrollToTop()">My Orders</a>
        </li>

        <li class="nav-item">
          <img src="../../../../assets/svgs/contact-us-icon.svg" [routerLink]="['/dashboard/contactus']"
            routerLinkActive="img-active">
          <a class="nav-link" [routerLink]="['/dashboard/contactus']" routerLinkActive="active"
            (click)="scrollToTop()">Contact</a>
        </li>
      </ul>
    </nav>
  </div>
</ng-template>
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';
declare var gtag:any;

@Component({
  selector: 'app-user-type-screen',
  templateUrl: './user-type-screen.component.html',
  styleUrls: ['./user-type-screen.component.scss','./user-type-screen.component.mobile.scss']
})
export class UserTypeScreenComponent implements OnInit {
  @Output() changeScreen = new EventEmitter<string>();
  @Output() showDocumentsModel:EventEmitter<string>= new EventEmitter<string>();
  isDesktopView: boolean = true;
  constructor(private requestService: RequestService, private router: Router, private responsiveService: ResponsiveService) { }
  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
  }
  onUserTypeSelect(userType: string) {
    // INTERMEDIDATE PAGE DOCS REQUIRED
    // WHEN CONTINUED FROM THERE RENDER HERE
    // change state from INITIATED to KYC_INITIATED and call to update userType
    let userId = localStorage.getItem("userId");
    this.requestService.setLatestRequestStatusToBE(userId, `KYC_INITIATED`).subscribe((result) => {
      if (result.statusCode >= 300) {
        Swal.fire({
          title: "Error",
          icon: "info",
          text: result['message'] || "Update Status failed",
        });
      } else {
        this.requestService.setLatestRequestStatus(`KYC_INITIATED`);
        this.changeScreen.emit();
      }
    })
  }

  showDocumentModel(requestType:string){
    if(requestType =='INDIVIDUAL'){
      gtag('event', 'ASSURE_APP_PROCEED_AS_INDIVIDUAL', {
        'event_category': 'PROCEED_AS_INDIVIDUAL_CLICKED',
        'event_label': 'Proceed as Individual',
        'value': 'User clicked on Proceed as Individual'   
      })
    }
    if(requestType =='CORPORATE'){
      gtag('event', 'ASSURE_APP_PROCEED_AS_CORPORATE', {
        'event_category': 'PROCEED_AS_CORPORATE_CLICKED',
        'event_label': 'Proceed as Corporate',
        'value': 'User clicked on Proceed as Corporate'   
      })
    }
    this.showDocumentsModel.emit(requestType);
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AssetsComponent } from './assets/assets.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { RequestComponent } from './request/request.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CanActivateRouteGuard } from 'src/app/guards/can-activate-route.guard';
import { OrderComponent } from './orderstatus/order/order.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/assets',
    pathMatch: 'full',
    canActivate: [CanActivateRouteGuard]
  },
  {
    path: 'assets',
    component: AssetsComponent,
    pathMatch: 'full',
    canActivate: [CanActivateRouteGuard]
  },
  {
    path: 'portfolio',
    component: PortfolioComponent,
    pathMatch: 'full',
    canActivate: [CanActivateRouteGuard]
  },
  {
    path: 'request',
    component: RequestComponent,
    pathMatch: 'full',
    canActivate: [CanActivateRouteGuard]
  },
  {
    path: 'contactus',
    component: ContactusComponent,
    pathMatch: 'full',
    canActivate: [CanActivateRouteGuard]
  },
  {
    path: 'order',
    component: OrderComponent,
    pathMatch: 'full',
    canActivate: [CanActivateRouteGuard]
  }
  // Add any other login-related routes if needed
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }

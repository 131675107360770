import Swal from 'sweetalert2';
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs";
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { environment } from "../environments/environment";
import { AuthenticationService } from './services/authentication/authentication.service';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private _cookieService: CookieService, private authService: AuthenticationService, private _router: Router) {
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const token = this._cookieService.get(`accessToken${COOKIE_SUFFIX}`);
    const token = localStorage.getItem('authToken');
    // const hub = this._cookieService.get('selectedHub');
    // const project = this._cookieService.get('selectedProject');
    let updatedRequest;

    const header: any = {};
    for (const headerKey of request.headers.keys()) {
      header[headerKey] = request.headers.get(headerKey);
    }
    // if (hub) {
    //   header.hub = hub;
    // }
    // if (project) {
    //   header.project = project;
    // }
 
    if (token) {
      header.Authorization = `Bearer ${token}`;
      updatedRequest = request.clone({
        headers: new HttpHeaders(header)
      });

    } else {
      updatedRequest = request.clone({
        headers: new HttpHeaders(header)
      });
    }

    return next.handle(updatedRequest).pipe(
      tap(
        event => {
          // logging the http response to browser's console in case of a success
          if (event instanceof HttpResponse) {
            //console.log('api call success :', event.body.statusCode);
            if (event.body.statusCode == 401) {

              // console.log("App Interceptor")
              // Swal.fire({
              //   icon: 'info',
              //   title: 'Hmm..',
              //   text: event.body.message,
              // })
              localStorage.removeItem('authToken');
              this._router.navigateByUrl('/auth/login');
            }
            else {
            }
          }
        },
        error => {
          // logging the http response to browser's console in case of a failuer
          if (error instanceof HttpErrorResponse) {
            // console.log('api call error :',error);
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Oops...',
            //   text: 'Something went wrong!',
            // })
          }
        }
      )
    );
  }
}

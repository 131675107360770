import { Component, OnInit } from '@angular/core';
import { ContactUsService } from 'src/app/services/contact-us.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { UserService } from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss','./contactus.component.mobile.scss']
})
export class ContactusComponent implements OnInit {
  constructor(private contactUsService: ContactUsService, private userService: UserService, private responsiveService: ResponsiveService) { }
  message: string = '';
  isDesktopView: boolean = true;

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
  }

  onSubmit() {
    const data = {
      userId: localStorage.getItem('userId'),
      queries: this.message
    }
    this.contactUsService.sendQueries(data).subscribe((res: any) => {
      if (res['statusCode'] >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res['message'] || "Technical Error",
        });
      } else {
        Swal.fire({
          title: 'Success!',
          icon: 'success',
          text: 'Queries sent successfully.',

        });
        this.message = '';
      }

    }, (err: any) => {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
    });
  }

}

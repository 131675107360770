<ng-template [ngIf]="isDesktopView">
    <div class="full-container">
        <div class="row">
            <div class="col-md-6 image-div">
            </div>
            <div class="col-md-6 form-container">
                <div class="centered-card">
                    <div class="form">

                        <div class="form-item">
                            <img src="../../../assets/svgs/blu_logo.svg" alt="Blusmart" />
                        </div>

                        <div class="form-item">
                            <h3 class="header">{{loginHeaderText}}</h3>
                        </div>
                        <div class="form-item">
                            <h6 class="sub-header">{{loginSubHeaderText}}</h6>
                        </div>

                        <!-- <div class="form-item">
                            <button type="button" class="btn customFormButton btn-primary">Continue with Google</button>
                        </div> -->

                        <!-- <div class="form-item">
                            <label class="seperator-or">Or</label>
                        </div> -->

                        <div class="form-item">
                            <div class="p-float-label form-element-width">
                                <input pInputText type="email" name="email" [(ngModel)]="email" maxlength="255"
                                    class="form-control customFormInput input-text" value="" placeholder="" id="email"
                                    (keyup)="debouncedValidateEmail()" (paste)="validateEmailOnPaste($event)" ngModel />
                                <label for="email">Enter your email ID</label>
                            </div>
                        </div>

                        <div class="form-item">
                            <button type="button" class="btn btn-primary customFormButton"
                                (click)="validateUserForLogin()" [ngClass]="{'btn-disabled': !enableContinueButton}"
                                [disabled]="!enableContinueButton">Continue</button>
                        </div>

                        <!-- <div  class="form-item">
                            <p class="grey-text">{{signInLinkLabelText}}<span class="blue-text">{{signInLinkText}}</span> </p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="!isDesktopView">
    <div class="mobile-view">
        <div class="container">
            <div class="image">
                <img src="../../../assets/images/login-hero-image-min.webp" alt="Blu-Smart Cabs">
            </div>
            <div class="login">
                <h3 class="header">{{loginHeaderText}}</h3>
                <h3 class="sub-header">{{loginSubHeaderText}}</h3>
                <div class="p-float-label input-email">
                    <input pInputText type="email" name="email" [(ngModel)]="email" maxlength="255" class="" value=""
                        placeholder="" id="email" (keyup)="debouncedValidateEmail()"
                        (paste)="validateEmailOnPaste($event)" ngModel />
                    <label for="email">Enter your email ID</label>
                </div>
                <button type="button" class="btn btn-primary" (click)="validateUserForLogin()"
                    [ngClass]="{'btn-disabled': !enableContinueButton}"
                    [disabled]="!enableContinueButton">Continue</button>
            </div>
        </div>
    </div>
</ng-template>
<app-otp-modal [receiver]="email" [visible]="otpModelVisible" [userId]="userId" [isMobileView]="!isDesktopView"
    (verifyClicked)="responseFromOtpModal($event)" (resendOtpClicked)="resendOtpClicked()"
    *ngIf="otpModelVisible"></app-otp-modal>
<p-toast></p-toast>
<app-navbar></app-navbar>
<app-side-nav [ngClass]="{'side-nav': isDesktopView,'mobile-side-nav': !isDesktopView}">
</app-side-nav>

<div [ngSwitch]="activeScreen" [ngClass]="{'main-container': isDesktopView, 'mobile-main-container': !isDesktopView}">
    <app-portfolio-home *ngSwitchCase="'SIDE_NAV_DASHBOARD'" />
    <app-agreement *ngSwitchCase="'SIDE_NAV_AGREEMENT'" />
    <app-financials *ngSwitchCase="'SIDE_NAV_FINANCIALS'" />
    <app-vehicle-summary *ngSwitchCase="'SIDE_NAV_VEHICLE_SUMMARY'" />
    <app-invoices *ngSwitchCase="'INVOICES'" />
    <app-faq *ngSwitchCase="'SIDE_NAVFAQ'" />
</div>
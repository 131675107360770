import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_GET_UNIT_ECONOMICS } from 'src/app/config/api.routes';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor(private _http: HttpClient) { }

  getUnitEconmonics(){
    return this._http.get(`${API_GET_UNIT_ECONOMICS}`)
   }
}

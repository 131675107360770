import { Component, OnInit } from '@angular/core';
import { checkActiveSection } from '../../../utils/helper/navigation.helper';
import { Subscription } from 'rxjs';
import { ScreenService } from 'src/app/services/screen/screen.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit{

  activeScreen = 'ASSETS_HOME';
  isDesktopView = true;
  private screenNameSubscription: Subscription;

  constructor(private screenService: ScreenService, private responsiveService: ResponsiveService) {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    });
    this.screenNameSubscription = this.screenService.assetsActiveScreenName$.subscribe((data) => {
      this.activeScreen = data;
    });

  }

  ngOnInit() {
  }

  determineScreen(screen:string){
    return this.activeScreen == screen;
  }

  ngOnDestroy() {
    this.screenNameSubscription.unsubscribe();
  }
}

<p-dialog [(visible)]="toggleShowInvoiceConcentModal" [modal]="true" class="main-wrapper"
    [ngClass]="{'mobile-view': !isDesktopView}" [draggable]="false" [resizable]="false" *ngIf="toggleShowInvoiceConcentModal">
    <div class="modal-header-custom"
        style="padding: 10px; text-align: right;align-items: right;margin-left: auto;margin-right: 0px;">
        <div class="headerText">
         
        </div>
        <!-- <button type="button" class="close" aria-label="Close" > -->
        <span aria-hidden="true" style="color: #3E4958;font-size: large;cursor: pointer;" (click)="cancel()"><i
                class="fa fa-times"></i></span>
        <!-- </button> -->
    </div>
    <div class="content">
        <div class="input-form">
                    <div style="width: 100%; height: 300px;">
                        <iframe  id="pdf-frame" style="width: 100%; height: 100%;" frameborder="0"></iframe>
                    </div>
                    <br/>
                <div>
                  <input type="checkbox" name="tnc" id="terms" (change)="updateCheckboxStatus($event)" />
                
                <span for="terms"> &nbsp; I hereby declare that I have read the invoice and the details mentioned are correct
                </span>
            </div>
              <div style="display: flex;flex-direction: row; justify-content: space-between;">
                <button class="btn btn-outline-danger" style="width: 45%; height: 42px;" (click)="handleScreen('CANCEL')">Cancel</button>
              <button class="btn btn-primary" style="width: 45%; height: 42px;" [disabled]="!isCheckboxChecked"  (click)="handleScreen('SEND')">Send</button>
              
              </div>
        </div>
        
    </div>
</p-dialog>
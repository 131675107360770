import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownPaymentComponent } from './down-payment/down-payment.component';
import { UpfrontPaymentComponent } from './upfront-payment/upfront-payment.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AvatarModule } from 'primeng/avatar';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';
import { ButtonModule } from 'primeng/button';
import { ScreenService } from 'src/app/services/screen/screen.service';
import { DialogModule } from 'primeng/dialog';
import { OtpModalComponent } from './otp-modal/otp-modal.component';
import { InputTextModule } from 'primeng/inputtext';
import { OneDigitDirective } from './directive/one.digit.directive';
import { SideNavComponent } from './side-nav/side-nav.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PaymentSlipUploadModalComponent } from './payment-slip-upload-modal/payment-slip-upload-modal.component';
import { DocListModelComponent } from './doc-list-model/doc-list-model.component';
import { MenuModule } from 'primeng/menu';
import { RequestService } from 'src/app/services/request/request.service';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { VisualiseReturnComponent } from './visualise-return/visualise-return.component';
import { TableModule } from 'primeng/table';
import { NumberFormatPipe } from '../../../app/utils/pipe/currency.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { InvoiceGenerateModalComponent } from './invoice-generate-modal/invoice-generate-modal.component';
import { InvoiceConcentModalComponent } from './invoice-concent-modal/invoice-concent-modal.component';
@NgModule({
  declarations: [
    DownPaymentComponent,
    UpfrontPaymentComponent,
    NavbarComponent,
    OtpModalComponent,
    OneDigitDirective,
    SideNavComponent,
    FileUploadComponent,
    PaymentSlipUploadModalComponent,
    DocListModelComponent,
    VisualiseReturnComponent,
    NumberFormatPipe,
    InvoiceGenerateModalComponent,
    InvoiceConcentModalComponent
  ],
  imports: [
    CommonModule,
    AvatarModule,
    RouterModule,
    FormsModule,
    InputNumberModule,
    SliderModule,
    ButtonModule,
    InputTextModule,
    DialogModule,
    NgxDropzoneModule,
    MenuModule,
    NgxOtpInputModule,
    TableModule,
    DropdownModule
  ],
  exports: [
    NavbarComponent,
    DownPaymentComponent,
    UpfrontPaymentComponent,
    OtpModalComponent,
    SideNavComponent,
    FileUploadComponent,
    PaymentSlipUploadModalComponent,
    DocListModelComponent,
    NumberFormatPipe,
    InvoiceGenerateModalComponent,
    InvoiceConcentModalComponent
  ],
  providers: [ScreenService,RequestService],
})
export class CommonsModule { }

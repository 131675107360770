<div class="payment-details-screen" [ngClass]="{'mobile-view': !isDesktopView}">
  <div class="d-flex justify-content-center">
    <img src="../../../../../assets/svgs/bank.svg">
  </div>
  <div class="d-flex justify-content-center">
    <p class="header">Payment Details</p>
  </div>
  <div class="table-container">
    <div class="d-flex">
        <table>
            <tr>
                <th class="table-header td-width" >Car Variant</th>
                <th class="table-header td-width" >Car Value</th>
                <th class="table-header td-width" >Number of Cars</th>
                <th class="table-header td-width" >Investment Value</th>
            </tr>
            <tr *ngFor="let car of carVariants;">
                <td>{{car.carName}}</td>
                <td>{{car.carPrice}}</td>
                <td>{{car.numCars}}</td>
                <td>₹{{car.numCars * car.carPrice}}</td>
            </tr>
            <tr>
              <td class="header-row last-td">Total Amount</td>
                <td class="header-row last-td">-</td>
                <td class="header-row last-td">-</td>
                <td class="header-row last-td">₹{{totalAmount}}</td>
            </tr>
        </table>
    </div>
</div>
  <div class="d-flex justify-content-between mt-2">
    <span [ngClass]="{'mobile-tnc': !isDesktopView}">
      <div>
        <input type="checkbox" name="tnc" id="terms" (change)="updateCheckboxStatus($event)" />
      </div>
      <span for="terms">I have read the
        <a class="terms-and-condition" target="_blank"
          href="https://d1bw6nzj7jblqg.cloudfront.net/static/Assure+terms+%26+conditions.pdf">terms & condition
        </a>
      </span>
    </span>
   
    <button class="btn btn-primary" style="width: 45%; height: 42px;"
    [ngClass]="{'btn-disabled': !isCheckboxChecked , 'button-width': !isDesktopView}"
    [disabled]="!isCheckboxChecked"
    (click)="handleScreen()">Proceed</button>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private authToken:any;
  
  private accessToken:any;

  private firstName:any;

  private lastName:any;


  private ssoId:any;

  private userEmailId:any

  private userId:any;
  // public userId$ = this.userId.asObservable();

  constructor() { }

  setAuthToken(data: string) {
    this.authToken =data;
  }

  getAuthToken() {
    return this.authToken;
  }

  setAccessToken(data: string) {
    this.accessToken=data;
  }

  getAccessToken() {
    return this.accessToken;
  }

  setFirstName(data: string) {
    this.firstName = data;
  }

  getFirstName() {
    return this.firstName;
  }

  setLastName(data: string) {
    this.lastName =data;
  }

  getLastName(data: string) {
    return this.lastName;
  }

  setUserEmailId(data: string) {
    this.userEmailId = data;
  }

  getUserEmailId() {
    return this.userEmailId;
  }

  setUserId(data: string) {
    this.userId = data;
  }
  
  getUserId(){
    return this.userId;
  }

  setSsoId(data: string) {
    this.ssoId = data;
  }

  getSsoId(data: string) {
    return this.ssoId;
  }

  clearValues(){
    this.authToken = null;
    this.accessToken = null;
    this.firstName = null;
    this.lastName = null;
    this.ssoId = null;
    this.userEmailId =null
    this.userId= null;
  }
}

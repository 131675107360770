import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Slider } from 'primeng/slider';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { DropdownOption } from 'src/app/shared/interfaces/dropdown-item';
declare var gtag:any;

@Component({
  selector: 'app-upfront-payment',
  templateUrl: './upfront-payment.component.html',
  styleUrls: ['./upfront-payment.component.scss','./upfront-payment.component.mobile.scss']
})
export class UpfrontPaymentComponent implements OnInit{ 
  minValueForCars:number =10;
  maxValueForCars:number =125;
  maxValueForSlider : number = 115;
  noOfCars:number = this.minValueForCars;
  sliderNoOfCars:number = 0;
  @ViewChild('sliderRef')
  slider!: Slider;
  labelToDisplay:any = [];
  toggleShowWarning:boolean = false;
  @Input() parentComponentName: string = '';
  @Input() apiRes:any = {
    "unitTotalCost":0,
    "perEmiIrr":0,
    "irr":0
  };
  @Input() allCarDetails:any[] = [];
  vehicleOptions: DropdownOption[] = [];
  toggleVisualiseReturn:boolean = false;
  isDesktopView: boolean = true;
  selectedCar?: DropdownOption;
  scrapPricing: number = 123800;
  irrValueUI ="14.1%";
  constructor(private router: Router, private requestService: RequestService, private responsiveService: ResponsiveService) {
    this.noOfCars = requestService.getNoOfUpFrontPaymentCars();
  }

  // calculations 

  costOfSingleAsset = this.apiRes['unitTotalCost'];
  preEmiIrr= this.apiRes['perEmiIrr'];
  irrRate = 18;

  

  ngOnInit() {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop=>{
      this.isDesktopView = isDesktop;
    })
    this.calculateLabelValues(); 
    this.processVehicleOptions();
  }

  processVehicleOptions()
  {
    this.allCarDetails.map((item)=> {
      this.vehicleOptions.push({name: item.assetName, id: item.assetUrl})
    })
    this.selectedCar = this.vehicleOptions[0];
    this.apiRes = this.allCarDetails[0];
    this.calculateCarReturn()
  }

  calculateCarReturn()
  {
    
    this.allCarDetails.map((item)=>{
      if(item.assetName === this.selectedCar?.name)
      {
        this.apiRes = item;
      }
    });
    this.costOfSingleAsset = this.apiRes['unitTotalCost'];
    this.preEmiIrr= this.apiRes['perEmiIrr'];
    this.irrRate = this.apiRes['irr'];
    if(this.selectedCar?.name.includes('TIGOR'))
      {
        this.scrapPricing = 123800
        this.irrValueUI = "14.1%"
      }
    if(this.selectedCar?.name === 'Citroen EC3 Feel')
    {
      this.scrapPricing = 120500
      this.irrValueUI = "14.1%"
    }
    if(this.selectedCar?.name.includes('MG'))
    {
      this.scrapPricing = 189800
      this.irrValueUI = "14.5%"
    }
    this.calculateLabelValues();
  }

  calculateLabelValues(){
    this.labelToDisplay = [
      {
        title:'Total investment :',
        amount: this.noOfCars* this.costOfSingleAsset
      },
      {
        title:'Total lease earnings :',
        amount: this.noOfCars* this.preEmiIrr* 48
      },
      {
        title:'Value of assets at the end of term :',
        amount: this.noOfCars* this.scrapPricing
      }
    ]
  }



  handleNoOfCars(){
    this.noOfCars = this.sliderNoOfCars + this.minValueForCars;
    if(this.noOfCars<this.minValueForCars){
      this.noOfCars=this.minValueForCars;
      this.slider.handleValue =this.minValueForCars;
    }
      
    if(this.noOfCars >this.maxValueForCars){
      this.noOfCars =this.maxValueForCars;
      this.slider.handleValue = this.maxValueForCars;
    }
    this.requestService.setNoOfUpFrontPaymentCars(this.noOfCars);
    this.calculateLabelValues();
  }

  showWarning(){
    this.toggleShowWarning = ! this.toggleShowWarning;
  }

  goToRequestPage(paymentType:string){

    if(paymentType == 'UPFRONT_PAYMENT'){
      gtag('event', 'ASSURE_APP_PAY_FULL_PAYMENT', {
        'event_category': 'PAY_FULL_PAYMENT_CLICKED',
        'event_label': 'Pay Full Payment',
        'value': 'User clicked on Pay full payment'   
      })
    }

    this.requestService.setNoOfAssets(this.noOfCars);
    this.requestService.setUnitDetailsId(this.apiRes['unitFinancialDetailsId']);
    this.requestService.setPaymentType(paymentType);
    this.requestService.setLatestRequestStatus('INVEST_NOW');
    this.router.navigateByUrl("/dashboard/request");
  }
  closeVisualiseModle(value:boolean){
    this.toggleVisualiseReturn = value;
  }

  openVisualiseModel(){
    this.toggleVisualiseReturn = ! this.toggleVisualiseReturn;
  }
}

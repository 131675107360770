<div class="wrapper" *ngIf="noOfInvoices != 0" [ngClass]="{'mobile-view': !isDesktopView}">
    <div class="text-contaoner">
        <div class="primary-title">
            <span>Invoices</span>
        </div>
        <br />
        <div class="secondary-title">
            <span>Upload your invoices to get return on time</span>
        </div>
    </div>
    <br />
    <div>
        <div class="main-block">

            <p-table [value]="allInvoices" styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Sr No</th>
                        <th>Order Id </th>
                        <th>Month</th>
                        <th>Generate Invoice</th>
                        <th>Invoices</th>
                        <th>Status</th>
                        <!-- <th> Track</th> -->
                    </tr>
                    <tr *ngIf="displayNone">
                        <td colspan="6"
                            style="font-size: 13px !important; padding: 10% !important; text-align: center;">
                            No data found
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-invoice let-i="rowIndex">
                    <tr>
                        <td>{{ i+1 }}</td>
                        <td>
                            {{ invoice.requestId  }}
                        </td>
                        <td>
                            {{ invoice.createdAt | date: "MMMM, yyyy" }}
                        </td>
                        <td>
                            
                            <span *ngIf="invoice.isInvoiceGenerated ==  false" (click)="generateInvoice(invoice.requestId,invoice.createdAt)" class="linkText">
                                Generate
                            </span>
                            <span *ngIf="invoice.isInvoiceGenerated ==  true">
                                Generated
                            </span>
                           
                        </td>
                        <td>
                            <a href={{invoice.invoiceUrl}} target="_blank" *ngIf="invoice.invoiceUrl" ><span
                                style="padding-left:4px"><i class="pi pi-download"></i></span> &nbsp;Download</a>
                        </td>
                        <td>
                           <div [ngClass]="applyClass(invoice.status)"  class="inv"> {{ invoice.status }}</div>
                        </td>

                        </tr>
                </ng-template>
            </p-table>

        </div>
    </div>

</div>
<div class="image-container" *ngIf="noOfInvoices == 0">
    <img src="../../../../../assets/images/no-return.svg" alt="">
    <p class="pending-header">No data found</p>
</div>

<app-invoice-generate-modal *ngIf="toggleInvoiceGenerate"
  [selectedRecord] = "selectedRecord"
  (showGenerateInvoiceUpload)="handlePaymentSlipModel($event)"
  (isContinueClicked)="handleContinueButtonOnInvoiceModal($event)"
  ></app-invoice-generate-modal>

  <app-invoice-concent-modal *ngIf="toggleInvoiceConcent"
  [selectedRecord] = "concentData"
  (showGenerateInvoiceUpload)="handlePaymentConcentModel($event)"
  (isContinueClicked)="handleContinueButtonOnInvoiceModal($event)"
  (refreshDate)="handlePageRefresh($event)"
  ></app-invoice-concent-modal>
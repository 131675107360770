import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_GENERATE_INVOICE, API_GENERATE_INVOICE_SIGN_URL, API_GET_ALL_INVOICES, API_GET_ALL_REQUEST_VEHICLE_SUMMARY, API_GET_INVOICE_SIGN_STATUS, API_GET_USER_PORTFOLIO, API_GET_USER_PORTFOLIO_DETAILS, API_GET_VEHICLE_SUMMARY, API_UPDATE_INVOICE_SIGN_STATUS } from 'src/app/config/api.routes';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  constructor(private _http: HttpClient) { }

  getData(userId: any) {
    return this._http.get(`${API_GET_USER_PORTFOLIO}/${userId}`)
  }

  getPortfolioData(userId: any){
    return this._http.get(`${API_GET_USER_PORTFOLIO_DETAILS}/${userId}`)
  }

  getVehicleSummary(requestId: any) {
    return this._http.get(`${API_GET_VEHICLE_SUMMARY}/${requestId}`)
  }

  getAllRequestVehicleSummary(data: any) {
    return this._http.post(`${API_GET_ALL_REQUEST_VEHICLE_SUMMARY}`,data)
  }

  getAllInvoices() {
    return this._http.get(`${API_GET_ALL_INVOICES}`)
  }

  geterateInvoice(request:any){
    return this._http.post(`${API_GENERATE_INVOICE}`, request,{ responseType: "blob" });
  }

  geterateInvoiceSignUrl(request:any){
    return this._http.post(`${API_GENERATE_INVOICE_SIGN_URL}`, request);
  }

  getInvoiceSignStatus(request:any){
    return this._http.post(`${API_GET_INVOICE_SIGN_STATUS}`, request);
  }

  updateInvoiceSignStatus(request:any){
    return this._http.post(`${API_UPDATE_INVOICE_SIGN_STATUS}`, request);
  }


}

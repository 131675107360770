import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { SignupComponent } from './signup/signup.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from "primeng/button";
import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { CommonsModule } from '../commons/commons.module';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';



@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    InputTextModule,
    DialogModule,
    ButtonModule,
    CardModule,
    InputNumberModule,
    DropdownModule,
    CommonsModule,
    FormsModule,
    ToastModule
  ],
  providers:[
  ],
  exports:[LoginComponent]
})
export class LoginModule { }

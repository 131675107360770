import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { AssetsComponent } from './assets/assets.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { AboutBluassureComponent } from './assets/about-bluassure/about-bluassure.component';
import { ReturnCalculatorComponent } from './assets/return-calculator/return-calculator.component';
import { CommonsModule } from '../commons/commons.module';
import { AssetsHomeComponent } from './assets/assets-home/assets-home.component';
import { InvestComponent } from './assets/invest/invest.component';
import { ScreenService } from 'src/app/services/screen/screen.service';
import { ButtonModule } from 'primeng/button';
import { PortfolioHomeComponent } from './portfolio/portfolio-home/portfolio-home.component';
import { AgreementComponent } from './portfolio/agreement/agreement.component';
import { FinancialsComponent } from './portfolio/financials/financials.component';
import { VehicleSummaryComponent } from './portfolio/vehicle-summary/vehicle-summary.component';
import { FaqComponent } from './portfolio/faq/faq.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { RequestComponent } from './request/request.component';
import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { PortfolioService } from 'src/app/services/portfolio/portfolio.service';
import { UserTypeScreenComponent } from './request/user-type-screen/user-type-screen.component';
import { PaymentDetailsComponent } from './request/payment-details/payment-details.component';
import { BankDetailsComponent } from './request/bank-details/bank-details.component';
import { InputTextModule } from 'primeng/inputtext';
import { ReactiveFormsModule } from '@angular/forms';
import { DocumentVerificationComponent } from './request/document-verification/document-verification.component';
import { DocumentUploadComponent } from './request/document-upload/document-upload.component';
import { PaymentVerifyScreenComponent } from './request/payment-verify-screen/payment-verify-screen.component';
import { RequestService } from 'src/app/services/request/request.service';
import { AgreementDoneComponent } from './request/agreement-done/agreement-done.component';
import { AboutVehicleComponent } from './assets/about-vehicle/about-vehicle.component';
import { ContactusComponent } from './contactus/contactus.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DialogModule } from 'primeng/dialog';
import { OrderComponent } from './orderstatus/order/order.component';
import { InvoicesComponent } from './portfolio/invoices/invoices.component';

@NgModule({
  declarations: [
    DashboardComponent,
    AssetsComponent,
    PortfolioComponent,
    AboutBluassureComponent,
    ReturnCalculatorComponent,
    AssetsHomeComponent,
    InvestComponent,
    PortfolioHomeComponent,
    AgreementComponent,
    FinancialsComponent,
    VehicleSummaryComponent,
    FaqComponent,
    RequestComponent,
    UserTypeScreenComponent,
    PaymentDetailsComponent,
    BankDetailsComponent,
    DocumentVerificationComponent,
    DocumentUploadComponent,
    PaymentVerifyScreenComponent,
    AgreementDoneComponent,
    AboutVehicleComponent,
    ContactusComponent,
    OrderComponent,
    InvoicesComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    CommonsModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    ChartModule,
    TableModule,
    InputTextareaModule,
    FormsModule,
    CarouselModule,
    DialogModule
  ],
  providers: [ScreenService, PortfolioService, RequestService],
  exports: [DashboardComponent]
})
export class DashboardModule { }

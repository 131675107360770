<ng-template [ngIf]="isDesktopView">
  <div class="main-container">
    <div class="doc-container">
      <div class="doc-header">
        <p class="heading">Upload Documents</p>
        <p class="sub-heading">
          You’re very close to embarking on your Assure by BluSmart journey. Just upload your documents for verification and get all
          set to go.
        </p>
      </div>
      <div class="doc-content">
        <div class="doc-upload">
          <span>Upload Documents</span>
          <span> - {{ currentDocumentName }}</span>
          <br />
          <br />
          <div *ngIf="currentDocumentName != ''">
            <app-file-upload (processUpload)="uploadCompleted($event)" (processSkip)="skipFileUpload()"
              [showSkip]="isFileOptional(currentDocumentName)"></app-file-upload>
          </div>
          <div class="doc-upload-done" *ngIf="currentDocumentName == ''">
            <div>
              <img src="../../../../assets/svgs/doc-upload-done.svg" alt="" srcset="">
              <p>All the documents are successfully added</p>
              <p class="doc-uploaded-sub-heading">Click on proceed for document verification</p>
            </div>
            <button class="btn btn-primary" (click)="lockFilesUploaded()">Proceed</button>
          </div>

        </div>
        <div class="doc-list">
          <div class="doc-upload-progress-container">
            <ng-container *ngFor="let documents of documentsList; let i = index">
              <div class="doc-upload-progress">
                <div class="doc-upload-list-heading" (click)="toggleExpansion(i)">
                  <div>
                    <div class="circle"
                      [ngStyle]="{'background-color': isAllVerified(documents.documents) ? '#00AB00' : '#D9D9D9'}">
                      {{ i + 1 }}
                    </div>
                    <div>
                      {{documents.type}}
                    </div>
                  </div>
                  <img
                    [src]="expandedIndex === i ? '../../../../assets/svgs/up-arrow.svg' : '../../../../assets/svgs/down-arrow.svg'"
                    alt="Expand/Collapse">
                </div>
                <div class="doc-upload-list-content" *ngIf="expandedIndex === i">
                  <ul>
                    <li *ngFor="let doc of documents.documents">
                      <img [src]="getIconSource(doc.status)" /> {{ doc.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="help">
            <img src="../../../../assets/svgs/doc-help.svg" alt="" srcset="">
            <div>
              <p>Could not find what you are looking for?</p>
              <p>Mail Us on <a href="mailto:">help@blusmart.com</a></p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="table-container" *ngIf="isSingleFileUploaded">
      <div class="table-main">
        <div class="custom-table">
          <div class="table-row header">
            <div class="table-cell uploaded-files">Document Type</div>
            <div class="table-cell uploaded-files">Uploaded Files</div>
            <div class="table-cell size">Size</div>
            <div class="table-cell action">Action</div>
          </div>
          <ng-container *ngFor="let docGroup of documentsList">
            <ng-container *ngFor="let doc of docGroup.documents">
              <div class="table-row" *ngIf="doc.status === 'VERIFIED'">
                <div class="table-cell uploaded-files">{{ doc.name }}</div>
                <div class="table-cell uploaded-files">{{ doc.fileName }}</div>
                <div class="table-cell size">{{ doc.fileSize }}</div>
                <div class="table-cell action">
                  <div class="delete-icon" (click)="onDeleteWrapper(doc)"></div>

                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="table-space"></div>
    </div>
  </div>
</ng-template>
<ng-template [ngIf]="!isDesktopView">
  <div class="mobile-view">
    <div class="doc-container">
      <div class="doc-header">
        <p class="heading">Upload Documents</p>
        <p class="sub-heading">
          You’re very close to embarking on your Assure by BluSmart journey. Just upload your documents for verification and get all
          set to go.
        </p>
      </div>
      <div class="doc-content">
        <div class="doc-upload">
          <span>Upload Documents</span>
          <span> - {{ currentDocumentName }}</span>
          <br />
          <br />
          <div *ngIf="currentDocumentName != ''">
            <app-file-upload (processUpload)="uploadCompleted($event)" (processSkip)="skipFileUpload()"
              [showSkip]="isFileOptional(currentDocumentName)"></app-file-upload>
          </div>
          <div class="doc-upload-done" *ngIf="currentDocumentName == ''">
            <div>
              <img src="../../../../assets/svgs/doc-upload-done.svg" alt="" srcset="">
              <p>All the documents are successfully added</p>
              <p class="doc-uploaded-sub-heading">Click on proceed for document verification</p>
            </div>
            <button class="btn btn-primary" (click)="lockFilesUploaded()">Proceed</button>
          </div>

        </div>
        <div class="doc-list">
          <div class="doc-upload-progress-container">
            <ng-container *ngFor="let documents of documentsList; let i = index">
              <div class="doc-upload-progress">
                <div class="doc-upload-list-heading" (click)="toggleExpansion(i)">
                  <div>
                    <div class="circle"
                      [ngStyle]="{'background-color': isAllVerified(documents.documents) ? '#00AB00' : '#D9D9D9'}">
                      {{ i + 1 }}
                    </div>
                    <div class="header-text">
                      {{documents.type}}
                    </div>
                  </div>
                  <img
                    [src]="expandedIndex === i ? '../../../../assets/svgs/up-arrow.svg' : '../../../../assets/svgs/down-arrow.svg'"
                    alt="Expand/Collapse">
                </div>
                <div class="doc-upload-list-content" *ngIf="expandedIndex === i">
                  <ul>
                    <li *ngFor="let doc of documents.documents">
                      <div>
                        <img [src]="getIconSource(doc.status)" />
                        <div>
                          {{ doc.name }}
                        </div>
                      </div>
                      <div class="delete-icon" (click)="onDeleteWrapper(doc)" *ngIf="doc.status === 'VERIFIED' || doc.status === 'SKIPPED'">
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- <div class="help">
            <img src="../../../../assets/svgs/doc-help.svg" alt="" srcset="">
            <div>
              <p>Could not find what you are looking for?</p>
              <p>Mail Us on <a href="mailto:">help@blusmart.com</a></p>
            </div>
  
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="table-container" *ngIf="isSingleFileUploaded">
      <div class="table-main">
        <div class="custom-table">
          <div class="table-row header">
            <div class="table-cell uploaded-files">Document Type</div>
            <div class="table-cell uploaded-files">Uploaded Files</div>
            <div class="table-cell size">Size</div>
            <div class="table-cell action">Action</div>
          </div>
          <ng-container *ngFor="let docGroup of documentsList">
            <ng-container *ngFor="let doc of docGroup.documents">
              <div class="table-row" *ngIf="doc.status === 'VERIFIED'">
                <div class="table-cell uploaded-files">{{ doc.name }}</div>
                <div class="table-cell uploaded-files">{{ doc.fileName }}</div>
                <div class="table-cell size">{{ doc.fileSize }}</div>
                <div class="table-cell action">
                  <div class="delete-icon" (click)="onDeleteWrapper(doc)"></div>
  
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="table-space"></div>
    </div> -->
  </div>
</ng-template>
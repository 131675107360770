<p-dialog [(visible)]="toggleShowModel" [modal]="true" class="main-wrapper" [draggable]="false" [resizable]="false"
    *ngIf="toggleShowModel" (onClose)="cancel()" [style]="{'width': (isDesktopView) ? '450px' : '90%'}"
    [ngClass]="{'mobile-view': !isDesktopView}">
    <div style="padding: 10px; text-align: right;align-items: right;margin-left: auto;margin-right: 0px;">
        <!-- <button type="button" class="close" aria-label="Close" > -->
        <span aria-hidden="true" style="color: #3E4958;font-size: large;cursor: pointer;" (click)="cancel()"><i
                class="fa fa-close"></i></span>
        <!-- </button> -->
    </div>
    <div class="modal-content-wrapper">
        <div class="heading-wrapper">
            <div class="modal-primary-text">
                You are signed in as an {{requestType}}?
            </div>
            <br>
            <div class="modal-secondary-text">
                In the next step we will ask for the documents mentioned below
            </div>
        </div>
        <div class="doc-list-wrapper">
            <ol class="no-decimal" >
                <li class="main-list" *ngFor="let mainListItem of docList">
                    <span>{{mainListItem.title}}</span>
                    <ul class="sub-list" >
                        <li class="sub-list-item" *ngFor="let item of mainListItem.docList">
                            {{item}}
                        </li>
                    </ul>
                </li>
            </ol>
        </div>
        <br>
        <div class="modal-button-wrapper">
            <button type="button" class="btn btn-primary" (click)="proceedToFileUpload()">Continue</button>
        </div>
    </div>
</p-dialog>
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { FilesService } from 'src/app/services/files/files.service';
import { PortfolioService } from 'src/app/services/portfolio/portfolio.service';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-concent-modal',
  templateUrl: './invoice-concent-modal.component.html',
  styleUrls: ['./invoice-concent-modal.component.scss','./invoice-concent-modal.component.mobile.scss']
})
export class InvoiceConcentModalComponent {

  toggleShowInvoiceConcentModal: boolean = true;
  isDesktopView: boolean = true;
  invoiceNumber:string = "";
  isCheckboxChecked: boolean = false;
  enableContinueButton:boolean = false;
  isSendBtnDisabled:boolean = false;
  newWindow: any;
  interval:any =[];
  @Output() showGenerateInvoiceUpload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refreshDate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedRecord:any;
  constructor(private fileUploadService: FilesService, private responsiveService: ResponsiveService, private portfolioService: PortfolioService, private _loader:NgxSpinnerService) { }

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop
    })
    
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedRecord'] ) {
      this.displayPDF(changes['selectedRecord'].currentValue);
    }
  }
  ngAfterViewInit(changes: SimpleChanges): void {
  }

  cancel() {
    this.toggleShowInvoiceConcentModal = false;
    this.refreshDate.emit(false);
    this.showGenerateInvoiceUpload.emit(false);
  }

  validInvoiceNo(){
    if(this.invoiceNumber.trim().length >0 ){
      this.enableContinueButton = true;
      console.log("enabled");
    }
    else{
      this.enableContinueButton = false;
      console.log("disabled");
    }
     
  }

  updateCheckboxStatus(event: Event) {
    this.isCheckboxChecked = (event.target as HTMLInputElement).checked;
  }

  handleScreen(action:string){
   switch(action){
    case "CANCEL": this.cancel() ;
      break;
    case "SEND": this.callAPI();
      break;
   }

  }

  displayPDF(reqBody:any) {
    // const pdfUrl = 'https://d33hwwhv3yi266.cloudfront.net/kyc-doc/1694596396649_4_2.pdf'; // Replace with the path to your PDF file
    // const pdfUrl = 'assets/pdf/test.pdf#toolbar=0&navpanes=0';
    // fetch(pdfUrl)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const url = URL.createObjectURL(blob);
    //     const iframe: HTMLIFrameElement = document.getElementById('pdf-frame') as HTMLIFrameElement;
    //     iframe.src = url;
    //   })
    //   .catch(error => console.error('Error fetching PDF:', error));
    let req:any ={};
    req['requestId'] = reqBody.selectedRequestId;
    req['monthKey'] = reqBody.selectedMonthKey;
    req['invoiceNumber'] = reqBody.invoiceNumber;

    this.portfolioService.geterateInvoice(req).subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url =URL.createObjectURL(blob);
      let page = 1;
      let viewMode = "FitH";
      let zoom = 200;
      let pdfViewerUrl = url + "#page=" + page + "&view=" + viewMode + "&zoom=200" + "&toolbar=0";
      const iframe: HTMLIFrameElement = document.getElementById('pdf-frame') as HTMLIFrameElement;
        iframe.src = pdfViewerUrl;
     },(error)=>{
      console.error('Error fetching PDF:', error)
    })
  }
  callAPI(){
    if(!this.isCheckboxChecked){
      Swal.fire({
        title: "error",
        icon: "info",
        text:"Please tick the checkbox !1",
      });
    }

    let reqBody ={
      "requestId":this.selectedRecord['selectedRequestId'],
      "kycDocType":"MONTHLY_INVOICE",
      "monthKey":this.selectedRecord['selectedMonthKey']
    }
    this._loader.show();
    this.portfolioService.geterateInvoiceSignUrl(reqBody).subscribe((res: any)=>{
      this._loader.hide();
      if(res['statusCode']>= 300){
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res['message'] || "Technical Error",
        });
      }else{
        let url = res['response'];
        this.toggleShowInvoiceConcentModal = false;
        url = url+"&redirect_uri="+encodeURI("https://d33hwwhv3yi266.cloudfront.net/scripts/closeWindow20.html");
        this.newWindow = window.open(url,"Sign Invoice","toolbar=no,scrollbars=no,location=no,statusbar=no,menubar=no,resizable=0,width=500,height=500,left = 490,top = 262");
        this.keepEye(this.newWindow);
       
      }

    },(error)=>{
      this._loader.hide();
      console.error('Error fetching signing  url:', error)
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
    })


    // this.cancel();
    
  }

  keepEye(window:Window){
  
    let newInterval = setInterval(()=> {
       if(window.closed){
        this.clearAllIntervals();
        this.refreshDate.emit(true);
        
        //  console.log("closed");
        let reqBody ={
          "requestId":this.selectedRecord['selectedRequestId'],
          "kycDocType":"MONTHLY_INVOICE"
        }
        this._loader.show();
        console.log("line 154");
         this.portfolioService.getInvoiceSignStatus(reqBody).subscribe((response:any)=>{
          this._loader.hide();
          this.refreshDate.emit(true);
           if(response.status>=300){
            Swal.fire({
              title: 'Error',
              icon: 'info',
              text: response.message
            });
            this.clearAllIntervals();
           }
           console.log("line 166 ",response.response);
           if(response.response== true){
            console.log("line 168 ");
            console.log("event")
            this.refreshDate.emit(true);
             let message= "Invoice Signed Successfully!!"
            Swal.fire({
              title: 'Success',
              icon: 'info',
              text: message
            }).then(()=>{
              
              let reqBody ={
                "requestId":this.selectedRecord['selectedRequestId'],
                "kycDocType":"MONTHLY_INVOICE",
                "monthKey":this.selectedRecord['selectedMonthKey']
              }
              this._loader.show();
              this.portfolioService.updateInvoiceSignStatus(reqBody).subscribe((response:any)=>{
                this._loader.hide();
                this.refreshDate.emit(false);
                this.cancel();
                if(response.status>=300){
                  Swal.fire({
                    title: 'Error',
                    icon: 'info',
                    text: response.message
                  });
                  this.clearAllIntervals();
                 }else{
                  this.refreshDate.emit(false);
                  this.cancel();
                  Swal.fire({
                    title: 'Success',
                    icon: 'info',
                    text: "Document uploaded to server successfully !!"
                  }).then(()=>{
                    this.refreshDate.emit(true)
                  })
                 }
              },(error)=>{
                this.refreshDate.emit(false);
                this.cancel();
                this._loader.hide();
                Swal.fire({
                  title: 'error',
                  icon: 'error',
                  text: "Something went wrong !!"
                })
              })
            });
           
            this.clearAllIntervals();
           }else {
            this._loader.hide();
            this.cancel();
            Swal.fire({
              title: 'Info',
              icon: 'info',
              text: "Document signing process failed!!"
            });
            this.clearAllIntervals();
           }
         })

         
       }
  },1000);
      this.interval.push(newInterval);
   }

   clearAllIntervals(){
    for (let i = 0; this.interval &&  i < this.interval.length; i++) {
      clearInterval(this.interval[i]);
      }
      if(this.interval)
        this.interval.length = 0;
   }



}

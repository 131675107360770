<ng-template [ngIf]="isDesktopView">
  <div *ngIf="isDesktopView">
    <div class="card interactive-card">
      <div class="wrapper">
        <div class="d-flex justify-content-between">
        <p class="heading" style="margin-top:8px">Return Calculator</p>
        <p-dropdown
        [options]="vehicleOptions"
        optionLabel="name"
        [(ngModel)]="selectedCar"
        (onChange)="calculateCarReturn()"
        [style]="{ width: '270px' }"
        >
          <ng-template let-item pTemplate="selectedItem">
              <img class='car-image' [src]="item.id">
              <span>{{ item.name }}</span>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <img class='car-image' [src]="option.id">
            <span>{{ option.name }}</span>
          </ng-template>
        </p-dropdown>
      </div>
        <div class="card mt-3">
          <div class="progress-bar-container">
            <div class="progress-bar-counter-container">
              <div>Number of Cars:</div>
              <div>
                {{noOfCars}}
              </div>
            </div>

            <div class="slider">
              <p-slider [(ngModel)]="sliderNoOfCars" [min]="0" [max]="maxValueForSlider" (onChange)="handleNoOfCars()"
                #sliderRef></p-slider>
            </div>
          </div>
        </div>

        <div>
          <div class="details-container" *ngFor="let item of labelToDisplay">
            <div class="title-text">
              {{ item.title }}
            </div>
            <div class="blue-text">
              {{ item.amount | numberFormat }}
            </div>
          </div>
          <div class="details-container">
            <div class="title-text">
              Pre tax IRR
            </div>
            <div class="blue-text">
            {{irrValueUI}}
            </div>
          </div>
        </div>



      </div>

      <hr *ngIf="parentComponentName == 'RETURN_CALCULATOR'" />

      <div>
        <div class="user-action-container">
          <div>
            <span class="blue-text item visualise-return" (click)="openVisualiseModel()">Visualise Returns</span>
          </div>
          <div>
            <button type="button" class="btn btn-primary custom-button"
              *ngIf="parentComponentName == 'RETURN_CALCULATOR'" (click)="goToRequestPage('UPFRONT_PAYMENT')">Invest
              Now</button>
            <button type="button" class="btn btn-primary btn-large" *ngIf="parentComponentName == 'INVEST'"
              (click)="goToRequestPage('UPFRONT_PAYMENT')">Invest Now</button>
          </div>
          <br />
          <div class="notes">
            <p>Note:</p>
            <ol class="note">
              <li>
                Investor pay-outs may be reduced on account of tax levies on the income distributed by
                the Issuer to the investors, depending on the status/category of the investor.
              </li>
              <li>
                Vehicle prices are just for reference and may vary slightly at the actual time of investment, based on
                the most recent quotation as received from our dealerships.
              </li>
            </ol>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-template>
<ng-template [ngIf]="!isDesktopView">
  <div class="mobile-view" *ngIf="!isDesktopView">
    <div class="card interactive-card">
      <div class="wrapper">
        <p class="heading">Return Calculator</p>
        <div>
          <div class="car-variant">Select car variant: </div>
          <p-dropdown
            [options]="vehicleOptions"
            optionLabel="name"
            [(ngModel)]="selectedCar"
            (onChange)="calculateCarReturn()"
            [style]="{ width: '100%'}"
            >
            <ng-template let-item pTemplate="selectedItem">
              <img class='car-image' [src]="item.id">
              <span>{{ item.name }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <img class='car-image' [src]="option.id">
              <span>{{ option.name }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="progress-bar-container" style="margin-top:16px">
          <div class="progress-bar-counter-container">
            <div>Number of Cars:</div>
            <div>
              {{noOfCars}}
            </div>
          </div>

          <div class="slider">
            <p-slider [(ngModel)]="noOfCars" [min]="0" [max]="maxValueForCars" (onChange)="handleNoOfCars()"
              #sliderRef></p-slider>
          </div>
        </div>

        <div class="details-table-container">
          <div class="details-container" *ngFor="let item of labelToDisplay">
            <div class="title-text">
              {{ item.title }}
            </div>
            <div class="blue-text">
              {{ item.amount | numberFormat }}
            </div>
          </div>
          <div class="details-container">
            <div class="title-text">
              Pre tax IRR
            </div>
            <div class="blue-text">
             {{irrValueUI}}
            </div>
          </div>
        </div>



      </div>
      <div>
        <div class="user-action-container">
          <div class="action">
            <button class="btn btn-secondary" (click)="openVisualiseModel()">Visualise Returns</button>
            <button type="button" class="btn btn-primary custom-button"
              *ngIf="parentComponentName == 'RETURN_CALCULATOR'" (click)="goToRequestPage('UPFRONT_PAYMENT')">Invest
              Now</button>
            <button type="button" class="btn btn-primary" *ngIf="parentComponentName == 'INVEST'"
              (click)="goToRequestPage('UPFRONT_PAYMENT')">Invest Now</button>
          </div>
          <br />
          <div class="notes">
            <p>Note:</p>
            <ol class="note">
              <li>
                Investor pay-outs may be reduced on account of tax levies on the income distributed by
                the Issuer to the investors, depending on the status/category of the investor.
              </li>
              <li>
                Vehicle prices are just for reference and may vary slightly at the actual time of investment, based on
                the most recent quotation as received from our dealerships.
              </li>
            </ol>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-template>


<p-dialog [(visible)]="toggleShowWarning" [modal]="true" [style]="{'width': (isDesktopView) ? '400px' : '90%'}"
  [draggable]="false" [resizable]="false" header="Are you sure you want to proceed with Full Payment Method?"
  *ngIf="toggleShowWarning">
  <div class="modal-content-wrapper">
    <div class="modal-secondary-text">
      Confirming your choice means you acknowledge that the down payment is binding and will contribute towards the
      total cost or purchase. By proceeding with the full Payment Method, you understand and accept the associated
      <u><strong style="font-weight: 500;"> terms and conditions.</strong></u>
    </div>
    <br *ngIf="!isDesktopView">
    <div class="modal-button-wrapper">
      <button type="button" class="btn btn-outline-primary" (click)="goToRequestPage('DOWN_PAYMENT')">Switch to Finance
        your purchase</button>
      <button type="button" class="btn btn-primary" (click)="goToRequestPage('UPFRONT_PAYMENT')">Proceed with Full
        Payment</button>
    </div>

  </div>
</p-dialog>
<app-visualise-return [toggleShowModel]="toggleVisualiseReturn" [noOfCars]="noOfCars" [apiRes]="apiRes"
  (closeModal)="closeVisualiseModle($event)" [paymentType]="'UPFRONT_PAYMENT'"></app-visualise-return>
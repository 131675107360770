<ng-template [ngIf]="isDesktopView">
    <div class="wrapper">
        <div class="text-contaoner">
            <div class="user-name">
                <span>Hey, {{userName}}!</span>
            </div>
            <div class="primary-title">
                <span>Portfolio Analytics</span>
            </div>
            <div class="secondary-title">
                <span>Monitor the performance of your assets and earnings</span>
            </div>
        </div>

        <div class="top-section">
            <div class="main-block">
                <div class="small-card">
                    <div class="custom-card">
                        <div class="card-data">
                            <div class="investment-container">
                                <span>Earnings To Date</span>
                                <div class="total-investment-container">
                                    <span>₹{{returnDto.totalReturns}}</span>
                                    <button class="invest-cta" (click)="investAction()">Invest Now</button>
                                </div>
                                <br>
                                <div class="return-main-container">
                                    <div *ngFor="let item of smallCardValues" class="investment-sub-container">
                                        <img [src]="item.icon" alt="">
                                        <div class="return-container">
                                            <span class="return-amount">₹{{item.primaryText}}</span>
                                            <span class="return-message">{{item.secondaryText}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sub-block">
                    <div class="co2-card">
                        <div class="co2">
                            <p class="heading">Total CO<sub>2</sub> saved by BluSmart</p>
                            <p class="numbers">14,500+ tonnes</p>
                            <!-- <p class="details">The cars deployed in the BluSmart fleet have saved tonnes of
                                CO<sub>2</sub> till date.</p> -->
                        </div>
                        <img src="../../../../../assets/svgs/co2-saving.svg" alt="" srcset="" class="co2-img">
                    </div>
                </div>
            </div>

            <div class="main-block">
                <div class="small-card">
                    <div class="custom-card2">
                        <div class="card-data">
                            <div class="multiple-selection-main-container">
                                <span class="car-count-title">Your Fleet</span>
                                <div class="multiple-selection-sub-container">
                                    <div class="investment-sub-container car-count-main" *ngFor="let item of fleets">
                                        <img [src]="item.url" alt="">
                                        <div class="car-count-container">
                                            <span class="car-count">{{item.count}}</span>
                                            <span class="car-message">No. of {{item.label}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>    
            </div>
        </div>



        <div class="top-section">
            <div class="main-graph-block">
                <div class="graph-card custom-card">
                    <div class="graph-header">
                        <div class="graph-title">
                            <div class="car-count-title">
                                Portfolio Details
                            </div>
                            <!-- <div class="card-primary-text">
                                {{upcomingReturn | currency:'INR':'symbol':'0.0-0'}}
                            </div> -->
                        </div>
                        <div class="graph-actions">
                            <!-- <div>
                                <button type="button" class="btn btn-disabled" icon="pi pi-download"><i class="pi pi-download" style="padding: 4px;"></i>Download Return History</button>
                            </div>
                            <div class="graph-action-visualise">
                                Visualise Return
                            </div> -->
                        </div>
                    </div>

                    <div class="portfolio-container">
                        <div *ngIf="completedPortfolio.length == 0" class="no-return-container">
                            <img src="../../../../../assets/svgs/no-file-search.svg" alt="">
                        </div>

                        <div *ngIf="completedPortfolio.length != 0">
                        <div class="request-container" *ngFor="let request of completedPortfolio; index as i">
                            <div *ngIf="request['status'] == 'COMPLETED'" class="portfolio">
                            <div class="portfolio-details">    
                            <span class="portfolio-index">{{i+1}}</span>
                            <span class="portfolio-caption">Order ID - <span class="portfolio-value">{{request['requestId']}}</span></span>
                            <span class="portfolio-caption">Portfolio Value - <span class="portfolio-value">₹{{request['totalReturnedToUser']}}</span></span>
                            <span class="portfolio-caption">Invested Amount - <span class="portfolio-value">₹{{request['totalInvestedByUser']}}</span></span>
                            <button class="portfolio-cta" (click)="toggleGraph()">{{!isGraphVisible ? 'View' : 'Hide'}}</button>
                            </div>
                            
                            <div class="chart-container" [ngStyle]="{display: isGraphVisible ? 'flex' : 'none'}">
                                <br><br>
                                <p-chart type="line" height="240" style="width: 100%;" [data]="data" [options]="options" [plugins]="plugins"></p-chart> 
                            </div>
                            </div>

                        </div>
                        </div>
                    </div>

                   

                </div>
            </div>
        </div>
    </div>

</ng-template>
<ng-template [ngIf]="!isDesktopView">
    <div class="mobile-wrapper-portfolio">
        <div class="text-contaoner">
            <div class="user-name">
                <span>Hey, {{userName}}!</span>
            </div>
            <div class="primary-title">
                <span>Portfolio Analytics</span>
            </div>
            <div class="secondary-title">
                <span>Monitor the performance of your assets and earnings</span>
            </div>
        </div>
        <div class="main-block">
            <div class="sub-block-mobile">
                <div class="custom-card">
                    <div class="card-data">
                        <div class="earnings-container">
                        <img src="assets/svgs/earning_icon.svg" alt="">
                        <div class="total-earnings-container">
                            <span>Earnings Till Date</span>
                            <span>₹{{returnDto.totalReturns}}</span>
                            <button class="invest-cta" (click)="investAction()">Invest Now</button>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="small-card info-card" *ngFor="let item of smallCardValues">
                    <div class="custom-card">
                        <div class="card-data">
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br>

        <div class="main-block">
            
                <div class="small-card info-card">
                    <div class="custom-card">
                        <div class="card-data">
                            <p>Your Fleet</p>
                            <div *ngFor="let item of fleets" class="card-data-container"> 
                                <div class="car-count-container">
                                    <span class="car-count">{{item.count}}</span>
                                    <span class="car-message">No. of {{item.label}}</span>
                                </div>

                                <div class="car-image-container">
                                    <img [src]="item.mobileUrl" alt="">
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
        </div>

        <br>


        <div class="main-block">
            
            <div class="small-card info-card">
                <div class="custom-card">
                    <div class="card-data">
                        <p>Portfolio Details</p>

                        <div class="portfolio-container">
                            <div *ngIf="completedPortfolio.length == 0" class="no-return-container">
                                <img src="../../../../../assets/svgs/no-file-search.svg" alt="">
                            </div>
    
                            <div *ngIf="completedPortfolio.length != 0">
                            <div class="request-container" *ngFor="let request of completedPortfolio; index as i">
                                <div *ngIf="request['status'] == 'COMPLETED'" class="portfolio">
                                <div class="portfolio-details">    
                                <span class="portfolio-index">{{i+1}}</span>
                                <span class="portfolio-caption">Order ID - <span class="portfolio-value">{{request['requestId']}}</span></span>
                                <span class="portfolio-caption">Portfolio Value - <span class="portfolio-value">₹{{request['totalReturnedToUser']}}</span></span>
                                <span class="portfolio-caption">Invested Amount - <span class="portfolio-value">₹{{request['totalInvestedByUser']}}</span></span>
                                <button class="portfolio-cta" (click)="toggleGraph()">{{!isGraphVisible ? 'View' : 'Hide'}}</button>
                                </div>
                                
                                <div class="chart-container" [ngStyle]="{display: isGraphVisible ? 'flex' : 'none'}">
                                    <br><br>
                                    <p-chart type="line" height="240" style="width: 100%;" [data]="data" [options]="options" [plugins]="plugins"></p-chart> 
                                </div>
                                </div>
    
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>

    <div class="main-block">
    <div class="sub-block">
        <div class="co2-card">
            <div class="co2">
                <p class="heading">Total CO<sub>2</sub> saved by BluSmart</p>
                <p class="numbers">14,500+ tonnes</p>
                <!-- <p class="details">The cars deployed in the BluSmart fleet have saved tonnes of
                    CO<sub>2</sub> till date.</p> -->
            </div>
            <img src="../../../../../assets/svgs/co2-saving.svg" alt="" srcset="" class="co2-img">
        </div>
    </div>
    </div>

    </div>
</ng-template>
<div class="individual-corporate-screen" [ngClass]="{'mobile-view':!isDesktopView}">
    <p class="header">
        Please select the business entity that best suits your needs
    </p>
    <div class="individual-corporate-card">
        <div class="individual-card">
            <p>Individuals</p>
            <p>
                Choose this option if the vehicle will be purchased in the name of an individual and relevant financial
                documents would be provided for vehicle financing (if applicable).
            </p>
            <br [ngClass]="{'hide':!isDesktopView}">
            <button class="btn btn-secondary" (click)="showDocumentModel('INDIVIDUAL')">Proceed as an
                Individual</button>
            <br [ngClass]="{'hide':!isDesktopView}">
        </div>
        <div class="corporate-card">
            <p>Corporate/LLP</p>
            <p>
                Choose this option if the vehicle will be purchased in the name of a company or LLP and relevant
                financial documents would be provided for vehicle financing (if applicable).
            </p>
            <br [ngClass]="{'hide':!isDesktopView}">
            <button class="btn btn-secondary" (click)="showDocumentModel('CORPORATE')">Proceed as a Corporate</button>
            <br [ngClass]="{'hide':!isDesktopView}">
        </div>
    </div>
</div>
<ng-template [ngIf]="isDesktopView">
    <div>
        <section>
            <div class="about-container"
                [ngClass]="{'show-about-section': showAboutBluAssure , 'hide-about-section': !showAboutBluAssure}">
                <div class="details-card card">
                    <div class="row card-content">
                        <div class="col-md-8 about-container">
                            <h3 class="content-title">About Assure by BluSmart</h3>
                            <p class="grey-text">
                                Assure by BluSmart: Your way to a sustainable future and smart earnings.
                            </p>
                            <ul class="grey-text">
                                <li><strong>Ownership: </strong>We are democratizing the EV revolution. Join this green
                                    movement
                                    by owning and leasing our electric cars to us.</li>
                                <li><strong>Monthly Earnings: </strong>Earn from the comfort of your home with a
                                    predetermined
                                    monthly rental.</li>
                                <li><strong>Tax Benefits: </strong>Unlock tax advantages and depreciation allowances for
                                    optimized gains.</li>
                            </ul>
                            <div>
                                <button type="button" class="btn btn-primary custom-button"
                                    (click)="changeAssetsScreen('INVEST')">Invest Now</button>
                                &nbsp;
                                <button type="button" class="btn btn-outline-primary custom-button"
                                    (click)="handleMenu(2)">Calculate your return</button>
                            </div>
                        </div>
                        <div class="lease-vehicle-container" style="color:'red'">
                            <h3 class="content-title">Lease electric cars</h3>
                            <p class="grey-text">
                                With the Assure program, you can buy and lease out the Tata Tigor, MG EV and BYD. 
                                It is a pioneering electric sedan that provides a sustainable, 
                                stylish and practical urban mobility solution with a range of 200 km+.
                            </p>
                            <div class="image-container">
                                <img src="../../../../../assets/images/leaseElectricCars.png" />
                                <a (click)="handleMenu(3)">Know more</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="details-container">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="menu card" [ngClass]="{'expanded': activeSection == 'ABOUT_BLUASSURE'}">
                                <ul class="menu-item-list">
                                    <div class="menu-item grey-text" [ngClass]="{'menu-item-active': item.isActive}"
                                        *ngFor="let item of menuItemsList" (click)="handleMenu(item.id)">
                                        <li> <img [src]="item.image"
                                                [ngClass]="{'normal-icon': !item.isActive ,'active-icon': item.isActive}">
                                            &nbsp; {{item.title}}</li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <!-- About BluAssure Section starts -->
                            <app-about-bluassure *ngIf="checkActiveSection('ABOUT_BLUASSURE')" />
                            <!-- About BluAssure Section Ends -->

                            <!-- Return calculator Section starts  -->
                            <app-return-calculator *ngIf="checkActiveSection('RETURN_CALCULATOR')" />
                            <!-- Return calculator Section ends  -->

                            <!-- Return calculator Section starts  -->
                            <app-about-vehicle *ngIf="checkActiveSection('ABOUT_VEHICLE')" />
                            <!-- Return calculator Section ends  -->

                            <!-- FAQ Section starts  -->
                            <app-faq *ngIf="checkActiveSection('FAQ')" />
                            <!-- FAQ vehicle Section ends  -->

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-template>

<ng-template [ngIf]="!isDesktopView">
    <div class="mobile-view">
        <section>
            <div class="menu-bar">
                <div class="menu-bar-item" [ngClass]="{'menu-bar-item-active': item.isActive}"
                    *ngFor="let item of menuItemsList" (click)="handleMenu(item.id)">
                    {{item.title}}
                </div>
            </div>
            <div class="about-assure"
                [ngClass]="{'show-about-section': showAboutBluAssure , 'hide-about-section': !showAboutBluAssure}">
                <div class="assure">
                    <div class="title">
                        About Assure by BluSmart
                    </div>
                    <div class="sub-title">
                        Assure by BluSmart: Your way to a sustainable future and smart earnings.
                    </div>
                    <div class="details">
                        <ul>
                            <li>
                                <strong>Ownership: </strong>We are democratizing the EV revolution. Join this green
                                movement
                                by owning and leasing our electric cars to us.
                            </li>
                            <li>
                                <strong>Monthly Earnings: </strong>Earn from the comfort of your home with a
                                predetermined
                                monthly rental.
                            </li>
                            <li><strong>Tax Benefits: </strong>Unlock tax advantages and depreciation allowances for
                                optimized gains.</li>
                        </ul>
                    </div>
                    <div class="action">
                        <button type="button" class="btn btn-outline-primary" (click)="handleMenu(2)">Calculate your
                            return</button>
                        <button type="button" class="btn btn-primary" (click)="changeAssetsScreen('INVEST')">Invest
                            Now</button>
                    </div>
                </div>
                <div class="assure lease">
                    <div class="title">Lease electric cars</div>
                    <div class="sub-title">With the Assure program, you can buy and lease out the Tata Tigor, MG EV and BYD. 
                        It is a pioneering electric sedan that provides a sustainable, stylish and practical urban mobility 
                        solution with a range of 200 km+.</div>
                    <div class="image-container">
                        <img src="../../../../../assets/images/leaseElectricCars.png" />
                        <a (click)="handleMenu(3)" class="cursor">Know more</a>
                    </div>
                </div>
            </div>
            <div>
                <!-- About BluAssure Section starts -->
                <app-about-bluassure *ngIf="checkActiveSection('ABOUT_BLUASSURE')" />
                <!-- About BluAssure Section Ends -->

                <!-- Return calculator Section starts  -->
                <app-return-calculator *ngIf="checkActiveSection('RETURN_CALCULATOR')" />
                <!-- Return calculator Section ends  -->

                <!-- Return calculator Section starts  -->
                <app-about-vehicle *ngIf="checkActiveSection('ABOUT_VEHICLE')" />
                <!-- Return calculator Section ends  -->

                <!-- FAQ Section starts  -->
                <app-faq *ngIf="checkActiveSection('FAQ')" />
                <!-- FAQ vehicle Section ends  -->
            </div>
        </section>
    </div>
</ng-template>
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { UIChart } from 'primeng/chart';
import { PortfolioService } from 'src/app/services/portfolio/portfolio.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-portfolio-home',
  templateUrl: './portfolio-home.component.html',
  styleUrls: ['./portfolio-home.component.scss','./portfolio-home.component.mobile.scss']
})

 

export class PortfolioHomeComponent  implements OnInit{
  constructor(private portfolioService: PortfolioService, private responsiveService: ResponsiveService, private router: Router) { }

  smallCardValues:any = []
  portfolioValue:number =0;
  totalInvestment:number = 0;
  data: any;
  options: any;
  plugins:any;
  showNoReturns = true;
  upcomingReturn =0;
  graphLabels:any =[];
  graphValues:any =[];
  pointerStyleRadius:any = [];
  pointerStyleArr:any = []
  idxToHighlight =0;
  userName:any = ''
  isDesktopView: boolean = true;
  fleets = [{label: 'TATA TIGOR XM', count: 0, url: 'assets/images/tigor.png', id: 1, mobileUrl: 'assets/images/mg_new.png'},
            {label: 'MG ZS EV', count: 0, url: 'assets/images/mg_2.png', id: 2, mobileUrl: 'assets/images/mg_new.png'},
            {label: 'BYD', count: 0, url: 'assets/images/byd_2.png', id: 4, mobileUrl: 'assets/images/mg_new.png'},
            {label: 'Citroen EC3 Feel', count: 0, url: 'assets/images/citroen_2.png', id: 3, mobileUrl: 'assets/images/mg_new.png'}
  ];
  returnDto= {
    totalReturns: 0,
    returnsTillDate: 0,
    investedAmount: 0
  }

  isGraphVisible = false;
  portfolio= [];
  completedPortfolio:any[] = [];

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop=>{
      this.isDesktopView = isDesktop;
    })
    this.callAPI();
    this.renderSmallCards();
    this.userName =localStorage.getItem('firstName');
    
  }

  renderChart(){
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    // const pointImage = new Image();
    // pointImage.src = "../../../../../assets/images/graph_point.svg"
      

    this.data = {
        labels: this.graphLabels,
        datasets: [
            {
                label: 'Projected Returns',
                data: this.graphValues,
                fill: true,
                borderColor: "#2C66E3",
                borderWidth:2,
                tension: 0.4,
                // backgroundColor: 'rgba(255,167,38,0.2)',
                backgroundColor: this.createGradientFill(),
                pointRadius:2,
                pointBackgroundColor:"#2C66E3"
                // pointRadius: [0, 0, 0, 10, 0, 0, 0,0,0,0,0,0],
            }
        ]
    };

    const customBorder = {
      id: 'customBorder',
      afterDatasetsDraw(chart:any, args:any, pluginOptions:any) {
        const {
          ctx,
          chartArea: {
            top,
            bottom,
            left,
            right,
            width,
            height
          }
        } = chart;
    
    
        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#92A5CD";
    
        ctx.moveTo(left - 1, top + 3);
        ctx.lineTo(left + 5, top + 10);
        ctx.moveTo(left + 1, top + 3);
        ctx.lineTo(left - 5, top + 10);
        ctx.moveTo(left, top + 5);
        ctx.lineTo(left, bottom);
        ctx.lineTo(right - 5, bottom);
        ctx.moveTo(right - 3, bottom + 1)
        ctx.lineTo(right - 10, bottom - 5);
        ctx.moveTo(right - 3, bottom - 1);
        ctx.lineTo(right - 10, bottom + 5);
        ctx.stroke();
        ctx.closePath();
      }
    }

    const lineMarker = {
      id: 'lineMarker',
      afterDatasetsDraw: (chart:any,args:any,pluginOptions:any) =>{
        const {
          ctx,
          data,
          chartArea: {
            top,
            bottom,
            left,
            right
          },
          scales:{x,y}
        } = chart;


        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = '#2C66E3';
        ctx.lineWidth = 1;
        ctx.setLineDash([3, 3]);
        ctx.moveTo(x.getPixelForValue(pluginOptions.index),y.getPixelForValue(data.datasets[0].data[pluginOptions.index]));
        ctx.lineTo(x.getPixelForValue(pluginOptions.index),bottom);
        ctx.stroke();
        ctx.moveTo(x.getPixelForValue(pluginOptions.index),y.getPixelForValue(data.datasets[0].data[pluginOptions.index]));
        ctx.lineTo(x.getPixelForValue(0),y.getPixelForValue(data.datasets[0].data[pluginOptions.index]));
        ctx.stroke();
        ctx.setLineDash([]);
      }
    }
    
    
    this.options = {
      responsive: false,
      hover: {
        mode: 'null' // Set the hover mode to 'null' to disable tooltips on hover
    },
      elements:{
        point:{
          pointStyle:this.pointerStyleArr,
          pointRadius:this.pointerStyleRadius
        }
      },
      maintainAspectRatio: false,
        // aspectRatio: 0.99,
        plugins: {
          legend: {
            display: false
         },
         tooltip: {
          enabled: true, // Set to false to remove or hide tooltips
          backgroundColor: '#2C66E3', // Background color of the tooltip
          titleColor: '#DFE9FF', // Color of the tooltip title
          bodyColor: '#fff', // Color of the tooltip body text
          borderColor: '#2C66E3', // Border color of the tooltip
          borderWidth: 1, // Border width of the tooltip
          cornerRadius: 5, // Border radius of the tooltip
          displayColors: false, // Hide color boxes next to the tooltip items
          intersect: false //
        },
        lineMarker:{
          index:this.idxToHighlight
        }
        },
        scales: {
            x: {
                ticks: {
                    color: "#92A5CD",
                },
                grid: {
                    color: "#C8E1FF",
                    display: false,
                },
                border: {
                  dash: [5,5],
                  color: "#C8E1FF",
              },
                title: {
                  display: true,
                  text: 'Months', // Define your X-axis label here,
                  color:"#92A5CD"
                },
            },
            y: {
                ticks: {
                    color: "#92A5CD",
                    callback: function (value:any) {
                      return '₹' + value;
                    },
                },
                grid: {
                    color: "#C8E1FF",
                    display: false,
                },
                border: {
                  dash: [5,5],
                  color: "#C8E1FF",
              },
                title: {
                  display: true,
                  text: 'Returns', // Define your X-axis label here,
                  color:"#92A5CD"
                },
            }
        }
    };

  // this.plugins =[customBorder,lineMarker];
  this.plugins =[lineMarker];
  }

  renderSmallCards(){

    this.smallCardValues = [
      {
        id:1,
        primaryText: this.returnDto.returnsTillDate,
        secondaryText:"Returns Till Date",
        icon:".././../../../../assets/svgs/rupee_icon.svg"
      },
      {
        id:2,
        primaryText:this.returnDto.investedAmount,
        secondaryText:"Total Gains",
        icon:".././../../../../assets/svgs/totalGains.svg"
      }
    ]
  }

  createGradientFill() {
    let  canvasCollection = document.getElementsByTagName('canvas');
    const canvasElement: HTMLCanvasElement = canvasCollection[0];
    const ctx = canvasElement.getContext("2d");
    console.log(ctx);
    let w = canvasElement.width;
    let h = canvasElement.height;  
    
    if(ctx){
      // for reference - https://ovdss.com/apps/online-gradient-generator
    const gradient = ctx.createLinearGradient(0, 75, 300, 75); 
    gradient.addColorStop(0, "rgb(229, 234, 255,0.6)");
    gradient.addColorStop(0.5, "rgb(229, 234, 255,0.6)");
    gradient.addColorStop(1, "rgb(215, 255, 238,0.6)");
    return gradient;
    } 
    
    return null;
  }

  callAPI(){
    let userId = localStorage.getItem('userId');
    this.portfolioService.getPortfolioData(userId).subscribe((res:any)=>{
      if(res.statusCode >=300){
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res['message'] || "Technical Error",
        })
        this.showNoReturns = true;    
      }else{

        this.portfolio = res['response']['requests'];

       
        for (const iterator of res['response']['requests']) {
            if(iterator['status']=='COMPLETED'){
       
            this.completedPortfolio.push(iterator);
            localStorage.setItem("hasCompletedRequest", "true");

              this.returnDto = {
                returnsTillDate: iterator["totalReturnedToUser"],
                investedAmount: iterator["totalInvestedByUser"],
                totalReturns: this.returnDto['returnsTillDate'] + this.returnDto['investedAmount']
              }
      
        this.renderSmallCards();
        this.renderFleetCount(iterator['requestDetails']);
        this.portfolioValue =iterator['totalReturnedToUser'];
        this.totalInvestment = iterator['totalAssets'];
        let data:any;
        iterator['requestDetails'].forEach((request:any)=>{
           this.upcomingReturn += request['totalReturnToUser'];
           data = this.generateMonthYearArray(request['holdingStartDate'],request['holdingEndDate'])
        });
        let returnPerMonth = this.upcomingReturn/(data['totalMonths']-1);
        this.upcomingReturn = this.upcomingReturn - iterator['totalReturnedToUser'];

        // let totalInstallmentsReceived = iterator['returnedReceivedTillDate'] /returnPerMonth;
        let investmentArr = [];

        const pointImage = new Image();
        pointImage.src = "../../../../../assets/images/graph_point.svg";
        let ptrToHighlight = Math.round(this.portfolioValue/returnPerMonth);
        console.log(ptrToHighlight)
        for (let i =0;i< data['totalMonths'] ;i++) {
          investmentArr.push(Math.round(returnPerMonth*(i)));
          if(ptrToHighlight == i)
            {
              this.pointerStyleRadius.push(10);
              this.pointerStyleArr.push(pointImage);
              this.idxToHighlight = i;
            }else{
              this.pointerStyleRadius.push(0);
              this.pointerStyleArr.push(false)
            }
        }
        // this.data['labels'] = data['months'];
        // this.data['datasets']['data'] = investmentArr;
        this.graphLabels = data['months'];
        this.graphValues = investmentArr;
        this.renderSmallCards()
        this.showNoReturns = false; 
            }
        }
        
         
      }
    },err =>{
      Swal.fire({
        title: "Error",
        icon: "error",
        text:  "Technical Error",
      })
      this.showNoReturns = true; 
    })
  }

  generateMonthYearArray(startDateString: number, endDateString: number): {
    months: string[],
    totalMonths: number,
    totalMonthsTillDate: number,
  } {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const currentDate = new Date();
  
    const monthYearArray: string[] = [];
    const totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth()) + 1;
    let totalMonthsTillDate = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + (currentDate.getMonth() - startDate.getMonth()) + 1;
  
    for (let date = startDate; date <= endDate; date.setMonth(date.getMonth() + 1)) {
      const formattedDate = date.toLocaleString('default', { month: 'short', year: '2-digit' });
      monthYearArray.push(formattedDate);
    }
  
    return {
      months: monthYearArray,
      totalMonths,
      totalMonthsTillDate,
    };
  }
  

  renderFleetCount(data:any){
    data.forEach((variant:any) => {
        this.fleets.forEach((fleet,index)=>{
          if(fleet.id == variant['assetsDetails']['vehicleId']){
            fleet.count += variant.noOfAssets;
          }
        })
    });
  }


  toggleGraph(){
    this.isGraphVisible = !this.isGraphVisible
    if(this.isGraphVisible){
      this.renderChart();
    }
  }
  

  investAction(){
  this.router.navigate(['/dashboard/request']);
  }
}

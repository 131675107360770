import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ScreenService } from 'src/app/services/screen/screen.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss', 'side-nav.component.mobile.scss']
})
export class SideNavComponent implements OnInit {

  openNavBar = true;
  isDesktopView: boolean =  true;
  menuItemsList:any = [];


  constructor(private screenService: ScreenService, private responsiveService: ResponsiveService, private route:ActivatedRoute) {

  }


  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    this.menuItemsList = [
      {
        id:1,
        title:"Dashboard",
        image: "../../../../assets/images/about-blusmart.svg",
        isActive: true,
        sectionName:"SIDE_NAV_DASHBOARD"
      },
      {
        id:2,
        title:"Agreement",
        image: "../../../../assets/images/agreement-icon.svg",
        isActive: false,
        sectionName: "SIDE_NAV_AGREEMENT"
      },
      // {
      //   id:3,
      //   title:"Financials",
      //   image: "../../../../assets/images/risk-disclosure-icon.svg",
      //   isActive: false,
      //   sectionName: "SIDE_NAV_FINANCIALS"
      // },
      {
        id:3,
        title:"Vehicle Summary",
        image: "../../../../assets/images/vehicle-summary.svg",
        isActive: false,
        sectionName: "SIDE_NAV_VEHICLE_SUMMARY"
      },
      {
        id:4,
        title:"Invoices",
        image: "../../../../assets/images/invoices.svg",
        isActive: false,
        sectionName: "INVOICES"
      },
      // {
      //   id:5,
      //   title:"FAQ",
      //   image: "../../../../assets/images/faqs-icon.svg",
      //   isActive: false,
      //   sectionName: "SIDE_NAVFAQ"
      // }

      
    ]

  
  }

  ngAfterViewInit(){
    this.route.queryParams.subscribe(params => {
      const myParam = params['target']; // replace 'myParamKey' with the actual key
      console.log("hello"+myParam)
      if(myParam=='invoice'){
        console.log("inside fn")
        for (const ele of this.menuItemsList) {
          if(ele.id==4){
            console.log("inside")
            ele.isActive=true;
            this.changeActiveTab(ele.id)
           }
           else
            ele.isActive=false;
        }
        
        
      }
        
    })
  }

  toggleNavBar() {
    this.openNavBar = !this.openNavBar;
  }

  changeActiveTab(id:number){
    for (const iterator of this.menuItemsList) {
        if(iterator.id == id){
          iterator['isActive'] = true;
          this.screenService.setPortfolioActiveScreenName(iterator['sectionName']);
          document.documentElement.scrollTop = 0;
        }
        else
          iterator['isActive'] = false;
    }
  }



}

import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { NgxOtpInputComponent, NgxOtpInputConfig } from 'ngx-otp-input';
import { Dialog } from 'primeng/dialog';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss'],
})
export class OtpModalComponent implements OnInit, OnDestroy {
  @Input() visible: boolean = false;
  @Input() isMobileView: boolean = false;
  @Input() receiver: string = '';
  @Input() userId: string | undefined;
  @Output() verifyClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() resendOtpClicked: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(Dialog) dialog: any;
  @ViewChild('ngxotp')
  ngxOtp!: NgxOtpInputComponent;
  countdownInterval: any;
  otpEntered: boolean = false;
  input1: number | undefined ;
  input2: number | undefined ;
  input3: number | undefined ;
  input4: number | undefined ;
  input5: number | undefined ;
  input6: number | undefined ;
  timerText = '';
  resendOtpEnabled = false;
  attenpts = 0;
  showFailedOTPMessage = false;
  otpValue:any;
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    numericInputMode: true,
    classList: {
      input: 'my-new-special-class',
      inputFilled: 'my-new-special-class',
    },
  };

  ngOnInit(): void {}
  ngOnChanges() {
    if (this.visible) {
      this.resetOTPModal();
      this.startCountdown();
      
    } else {
      this.clearCountdown();
      this.otpEntered = false;
      this.dialog.maskVisible = false;
    }
  }

  ngOnDestroy() {
    this.clearCountdown();
  }

  constructor(private authenticationService:AuthenticationService){}

  startCountdown() {
    let remainingSeconds = 59;

    this.timerText = remainingSeconds + ` second remaining`
    this.countdownInterval = setInterval(() => {
      remainingSeconds--;

      if (remainingSeconds >= 0) {
        this.timerText = remainingSeconds + ` second remaining`
      } else {
        this.resendOtpEnabled = true;
        this.clearCountdown();
      }
    }, 1000);
  }

  clearCountdown() {
    clearInterval(this.countdownInterval);
  }

  updateCountdownElement(text: string | number) {
    const countdownElement = document.getElementById('countdown');
    if (countdownElement) {
      countdownElement.textContent = `${text} `;
    }
  }

  move(e: any, previous: any, current: any, next: any) {
    let length = current.value.length;
    let maxLength = current.getAttribute('maxlength');
    if (length == maxLength) {
      if (next != '') {
        next.focus();
      }
    }
    if (e.key === 'Backspace') {
      previous.focus();
    }
    this.verifyInputs();
  }
  verifyInputs() {
    if (this.allInputsFilledWithNumbers()) {
      this.otpEntered = true;
    } else {
      this.otpEntered = false;
    }
  }
  allInputsFilledWithNumbers(): boolean {
    const inputs = [
      this.input1,
      this.input2,
      this.input3,
      this.input4,
      this.input5,
      this.input6,
    ];

    for (const input of inputs) {
      if (isNaN(Number(input))) {
        return false;
      }
    }
    return true;
  }
  onVerifyClick(resendOtp=false) {
    if(this.otpEntered){
      let otp = "" + this.input1 + this.input2 + this.input3 + this.input4 + this.input5 + this.input6;
      let data:any = {
        "userId": this.userId,
        "otp":this.otpValue
      }
              
      this.authenticationService.verifyOtp(data).subscribe((result)=>{
        if(result.statusCode >= 300){
          this.clearOtp();
          this.otpEntered = false;
          this.attenpts = this.attenpts+1;
          this.showFailedOTPMessage = true;
          if(this.attenpts>2){
            Swal.fire({
              title: "Error",
              icon: "error",
              text: "OTP validation failed",
            });
            this.visible = false;
            this.verifyClicked.emit(false);
          }
        }else{
          this.attenpts =0
          this.visible= false;
          // Swal.fire({
          //   title: "Success",
          //   icon: "info",
          //   text: result['message'] || "Technical Error",
          // }).then((res)=>{
            this.verifyClicked.emit(result.response);
          // });
          this.resetOTPModal();
          
        }
      }, (error)=>{
        console.log("Error while validating OTP ", error);
        this.clearOtp()
        Swal.fire({
          title: "Error",
          icon: "error",
          text:  "Technical Error",
        });
      })
    }
  }

  resetOTPModal(){
    this.input1 = undefined;
    this.input2 = undefined;
    this.input3 = undefined;
    this.input4 = undefined;
    this.input5 = undefined;
    this.input6 = undefined;
    this.otpEntered = false;
    this.attenpts =0
    this.resendOtpEnabled = false;
    this.showFailedOTPMessage = false;

  }

  resendOtp(){
    this.attenpts = this.attenpts+1;
    this.resendOtpClicked.emit();
    this.resendOtpEnabled = false;
    this.startCountdown();
  }
  handeOtpChange(value: any): void {
    // console.log(value);
    for (const iterator of value) {
      if(iterator =='')
        this.otpEntered = false;
    }
  }

  handleFillEvent(value: any): void {
    this.otpEntered =true;
    this.otpValue =value;
  }
  clearOtp(){
    this.ngxOtp.clear();
  }
}



import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss', './faq.component.mobile.scss']
})
export class FaqComponent implements OnInit {
  constructor(private responsiveService: ResponsiveService) { };
  isDesktopView: boolean = true;
  faqQuestions = [
    {
      id: 1,
      questionTitle: "What is the structure of the Assure by BluSmart Product?",
      questionTitleClose: "What is the structure of the Assure by BluSmart Product?",
      questionContent: `<li>The Assure by BluSmart Product is a vehicle lease. Whereby you, the Lessor, buys and leases the EVs to BluSmart, the Lessee, for which the Lessor receives monthly lease rentals. In this structure, the Lessor remains the owner of the vehicle at all times.</li>`,
      isOpened: true
    },
    {
      id: 2,
      questionTitle: "Can I lease out the EVs to BluSmart in my individual capacity? Can I lease out the EVs through my Company?",
      questionTitleClose: "Can I lease out the EVs to BluSmart in my individual capacity? Can I lease out the EVs through my Company?",
      questionContent: `<li>We allow both individuals and corporations to lease vehicles.</li>`,
      isOpened: false
    },
    // {
    //   id: 3,
    //   questionTitle: "Can I seek a loan to finance my car purchase?",
    //   questionTitleClose: "Can I seek a loan to finance my car purchase?",
    //   questionContent: `<li>Yes. BluSmart has tied-up with NBFCs and banks to facilitate financing for the lessors. Lessors are also free to secure financing from their preferred partners. BluSmart is purely a facilitator to this end and has no bearing on the outcome of the credit assessment.</li>`,
    //   isOpened: false
    // },
    {
      id: 3,
      questionTitle: "Till when will my lease rentals continue? What is the term of my lease?",
      questionTitleClose: "Till when will my lease rentals continue? What is the term of my lease?",
      questionContent: `<li>The investment is for a period of 4 years.</li>`,
      isOpened: false
    },
    {
      id: 4,
      questionTitle: "How often do I get my returns?",
      questionTitleClose: "How often do I get my returns?",
      questionContent: `<li>You will be paid a monthly lease rental at the start of every month of the lease tenure.</li>`,
      isOpened: false
    },
    {
      id: 5,
      questionTitle: "How will the payments be made?",
      questionTitleClose: "How will the payments be made?",
      questionContent: `<li>All lease payments will be made via one of the following payments methods: escrow/ eNACH/ bank account transfer. The lessor will be responsible for providing and enabling the relevant payment methods and associated information.</li>`,
      isOpened: false
    },
    {
      id: 6,
      questionTitle: "Will I be able to track the Lease Payments made to me?",
      questionTitleClose: "Will I be able to track the Lease Payments made to me?",
      questionContent: `<li>Yes. You can track all investment-related information on the BluAssure Portal. Additionally, monthly reports will be made and emailed to you containing all investment-related information.</li>`,
      isOpened: false
    },
    {
      id: 7,
      questionTitle: "Are the returns assured?",
      questionTitleClose: "Are the returns assured?",
      questionContent: `<li>The returns are contractually agreed to and are subject to the terms mentioned in the lease agreement between you and BluSmart.</li>`,
      isOpened: false
    },
    {
      id: 8,
      questionTitle: "Where will BluSmart deploy my cars? Do I need to belong to the same state as where the cars are being deployed to be a part of the program?",
      questionTitleClose: "Where will BluSmart deploy my cars? Do I need to belong to the same state as where the cars are being deployed to be a part of the program?",
      questionContent: `<li>BluSmart would deploy the leased cars across states/ cities where we operate our services, in line with requirements. Currently, our services are operational in the National Capital Region and Bengaluru.</li>`,
      isOpened: false
    },
    {
      id: 9,
      questionTitle: "Will I have to arrange for regular repair and maintenance of the car?",
      questionTitleClose: "Will I have to arrange for regular repair and maintenance of the car?",
      questionContent: `<li>No. BluSmart will take care of all regular repairs and maintenance of the EV.</li>`,
      isOpened: false
    },
    {
      id: 10,
      questionTitle: "What happens if my car is damaged while on the road? Who is liable for this?",
      questionTitleClose: "What happens if my car is damaged while on the road? Who is liable for this?",
      questionContent: `<li>As per the terms of the lease agreement, the lessee shall be responsible for any damage or on-road liability with regards to the car.</li>`,
      isOpened: false
    },
    {
      id: 11,
      questionTitle: "What is the tax applicable on my return? What do I have to do to avail all the tax benefits that have been outlined?",
      questionTitleClose: "What is the tax applicable on my return? What do I have to do to avail all the tax benefits that have been outlined?",
      questionContent: `<li>Interested lessors are advised to seek tax advice from experts in order to assess implications specific to them. Typically, lease income is taxable and asset owners are eligible to avail depreciation benefits. Lessors will be responsible for all formalities pertaining to taxes.</li>`,
      isOpened: false
    },
    {
      id: 12,
      questionTitle: "Do I need a GST registration to participate in the program?",
      questionTitleClose: "Do I need a GST registration to participate in the program?",
      questionContent: `<li>A GST registration will be necessary to participate in the program. Lessors will be responsible for all execution with regard to GST input credit collection, rebates, and payouts.</li>`,
      isOpened: false
    },
    {
      id: 13,
      questionTitle: "What information will I have on my EVs?",
      questionTitleClose: "What information will I have on my EVs?",
      questionContent: `<li>You will have access to a Management Information System on the Assure by BluSmart Portal. This will provide you data on the following terms: (i) Total distance traveled and geography, (ii) No. of trips completed (iii) Maintenance and Servicing Information (iv) Insurance related data.</li>`,
      isOpened: false
    },
    {
      id: 14,
      questionTitle: "What happens if I want to opt out of the program?",
      questionTitleClose: "What happens if I want to opt out of the program?",
      questionContent: `<li>Opt-out is subject to terms signed by both parties in the lease agreement.</li>`,
      isOpened: false
    }
  ];
  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
  }

  // Uncomment below line and comment out line after the commented line to shown FAQs in chunks of 6
  // activeFaq = this.faqQuestions.slice(0, 6);
  activeFaq = this.faqQuestions;
  toggleActiveFaq() {
    if (this.activeFaq.length === 6) {
      this.activeFaq = this.faqQuestions;
    } else {
      this.activeFaq = this.faqQuestions.slice(0, 6);
    }
  }
  toggleQuestion(question: any) {
    this.faqQuestions.forEach(q => {
      if (q === question) {
        q.isOpened = !q.isOpened;
      } else {
        q.isOpened = false;
      }
    });
  }
}

import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PortfolioService } from 'src/app/services/portfolio/portfolio.service';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vehicle-summary',
  templateUrl: './vehicle-summary.component.html',
  styleUrls: ['./vehicle-summary.component.scss']
})
export class VehicleSummaryComponent implements OnInit, OnChanges {

  noOfCars: number = 0;
  carDetails: any = []
  displayNone = true;
  isDesktopView: boolean = true;

  constructor(private portfolioService: PortfolioService, private requestService: RequestService, private responsiveService: ResponsiveService) { }

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    let userId = localStorage.getItem('userId');
    let requestIds:any = []
    this.portfolioService.getData(userId).subscribe((res: any) => {
      if (res.statusCode >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res['message'] || "Technical Error",
        })
      } else {
        for (const request of res['response']) {
          if (request['status'] == 'COMPLETED') {
            requestIds.push(request['requestId'])
          }
        }
        this.setVehicleSummaryData(requestIds);
      }
    }, err => {
      requestIds = []
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      })
    })
    //   this.carDetails = [{
    //     srNo:1,
    //     carName:"Tata Tigor",
    //     vehicleNo:"HR 26 AB 1234",
    //     fuelType:"Battery",
    //     range:315,
    //     batterySize:26,
    //     leaseStarts:1691640952000,
    //     leaseEnds:1849493752000,
    //     odometerReading:0,
    //     insurancePolicy:"",
    //     documentRc:"",
    //     co2Saved:"0",
    //     geography:"Delhi - NCR"
    //   },
    //   {
    //     srNo:2,
    //     carName:"Tata Tigor",
    //     vehicleNo:"HR 26 AB 1234",
    //     fuelType:"Battery",
    //     range:315,
    //     batterySize:26,
    //     leaseStarts:1691640952000,
    //     leaseEnds:1849493752000,
    //     odometerReading:0,
    //     insurancePolicy:"",
    //     documentRc:"",
    //     co2Saved:"0",
    //     geography:"Delhi - NCR"
    //   }

    // ];
  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  setVehicleSummaryData(requestIds: any) {
    let data = {
      requestIds: requestIds
    }
    this.portfolioService.getAllRequestVehicleSummary(data).subscribe((res: any) => {
      if (res['statusCode'] >= 300) {
        Swal.fire({
          title: "Error",
          icon: "info",
          text: res['message'] || "Technical Error",
        });
      } else {
        this.carDetails = res['response']
        for (const iterator of this.carDetails) {
          iterator['co2Saved'] = iterator?.odometerReading ? (iterator.odometerReading *0.0729).toFixed(2) : 0
        }
        this.noOfCars = res['response'].length;
        this.displayNone = false;
      }
    }, (err: any) => {
      Swal.fire({
        title: "Error",
        icon: "info",
        text: "Technical Error",
      });
    })
  }


}

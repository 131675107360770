import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit{
  files: File[] = [];
  maxFileSize = 5000000;
  allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];
  @Input() allowMultiple: boolean = false;
  @Input() showSkip: any = false;
  @Output() processUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() processSkip: EventEmitter<any> = new EventEmitter<any>();
  isDesktopView: boolean = true;
  constructor(private responsiveService: ResponsiveService) { }
  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
  }
  onSelect(event: any) {
    const selectedFiles: File[] = event.addedFiles;
    if (selectedFiles == undefined)
      return;
    for (const file of selectedFiles) {
      if (file.size >= this.maxFileSize) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "File Size exceeds max file size of 5MB",
        });
        return;
      }
      if (!this.allowedFileTypes.includes(file.type)) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Invalid file type. Only PDF and image files are allowed.",
        });
        return;
      }
    }
    if (!this.allowMultiple && this.files.length > 0) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "You can select only one file at a time",
      });
      return;
    }
    if (!this.allowMultiple) {
      this.files = [selectedFiles[0]];
    } else {
      this.files.push(...selectedFiles);
    }
  }
  hasFilesSelected(): boolean {
    return this.files.length > 0;
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }
  uploadFiles() {
    const fileNames: string[] = [];
    for (const file of this.files) {
      fileNames.push(file.name);
    }

    if (fileNames.length > 0) {
      // Swal.fire({
      //   title: "Success",
      //   icon: "info",
      //   text: "File Upload Successful",
      // });
    } else {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "No files uploaded",
      });
    }
    this.processUpload.emit(this.files);
    this.files = [];
  }

  skipFile() {
    this.processSkip.emit();
  }
}

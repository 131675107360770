<ng-template [ngIf]="isDesktopView">
    <div id="mySidenav" class="sidenav" [ngClass]="{'open': openNavBar, 'close': !openNavBar}">
        <div class="closebtn">
            <!-- (click)="toggleNavBar()" -->
            <span><img src="../../../../assets/images/hamburger.svg" height="15px" width="15px" /></span>
            <img class="blusmart-logo" src="../../../../assets/svgs/blu_logo.svg" width="150" height="25"
                alt="BluSmart" />
        </div>
        <ul class="menu-list">
            <li *ngFor="let item of menuItemsList" class="list-item-li" [ngClass]="{'active-list-item': item.isActive}"
                (click)="changeActiveTab(item.id)">
                <div class="list-item cursor">
                    <div class="list-item-icon" [ngClass]="{'active-list-item-icon': item.isActive}"> <img
                            [src]="item.image" alt=""> </div>
                    <div class="list-item-text">{{item.title}}</div>
                </div>
            </li>
        </ul>
    </div>
</ng-template>
<ng-template [ngIf]="!isDesktopView">
    <div class="menu-bar-container">
        <div class="menu-bar">
            <div class="menu-bar-item" *ngFor="let item of menuItemsList" [ngClass]="{'menu-bar-item-active': item.isActive}" (click)="changeActiveTab(item.id)">
                {{item.title}}
            </div>
        </div>
        <!-- <ul class="menu-bar">
            <li *ngFor="let item of menuItemsList" class="menu-bar-item" [ngClass]="{'menu-bar-item-active': item.isActive}"
                (click)="changeActiveTab(item.id)">
                <div class="menu-bar-item cursor">
                    <div class="list-item-text">{{item.title}}</div>
                </div>
            </li>
        </ul> -->
    </div>
</ng-template>
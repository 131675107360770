<div class="agreement-container" [ngClass]="{'mobile-view': !isDesktopView}">
    <br>
    <img src="../../../../../assets/svgs/agreement-done.svg" alt="" srcset="">
    <br>
    <p class="heading">
        Agreement done
    </p>
    <p class="sub-heading">
        Please find attached the digital copy of the agreement document, which outlines the terms and conditions of our
        partnership.
    </p>
    <br>
    <div class="action">
        <button class="btn btn-primary" (click)="goToPaymentSummary()">Proceed for payment</button>
    </div>
</div>
export function convertFileSize(bytes:number) {
    if (bytes < 1024) {
      return bytes + ' bytes';
    } else if (bytes < 1024 * 1024) {
      const kilobytes = (bytes / 1024).toFixed(2);
      return kilobytes + ' KB';
    } else {
      const megabytes = (bytes / (1024 * 1024)).toFixed(2);
      return megabytes + ' MB';
    }
  }
import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-about-vehicle',
  templateUrl: './about-vehicle.component.html',
  styleUrls: ['./about-vehicle.component.scss', './about-vehicle.component.mobile.scss']
})
export class AboutVehicleComponent implements OnInit {
  isDesktopView: boolean = true;
  carsData = [{
    carName: 'Tata Tigor Xpres-T EV',
    spec: 'XM++',
    bootSpace: '264 L',
    fastCharging: '65 mins',
    slowCharging: '8 h 45 mins',
    range: '210km+ on road',
    batteryCapacity: '25.9 kWh Lithium ion battery pack',
    vehicleImagePath:"../../../../../assets/images/tigorLease.png"
  },
  {
    carName: 'MG',
    spec: 'Executive',
    bootSpace: '448 L',
    fastCharging: '60 mins',
    slowCharging: '8 h 30 mins',
    range: '461Kms',
    batteryCapacity: '50.3 kWh Lithium ion battery pack',
    vehicleImagePath:"../../../../../assets/images/mg.png"
  },
  {
    carName: 'Citroen',
    spec: 'EC3 Feel',
    bootSpace: '315 L',
    fastCharging: '57 mins',
    slowCharging: '10 h 30 mins',
    range: '320 km',
    batteryCapacity: '29.2 kWh Lithium ion battery pack',
    vehicleImagePath:"../../../../../assets/images/citroen.jpg"
  }
  ]
  constructor(private responsiveService: ResponsiveService) { }
  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
  }

}

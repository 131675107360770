import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  private assetsActiveScreenName = new Subject<string>();
  public assetsActiveScreenName$ = this.assetsActiveScreenName.asObservable();

  private portfolioActiveScreen = new Subject<string>();
  public portfolioActiveScreenName$ = this.portfolioActiveScreen.asObservable();

  constructor() { }

  setAssetsScreenName(data: string) {
    this.assetsActiveScreenName.next(data);
  }
  setPortfolioActiveScreenName(data: string) {
    this.portfolioActiveScreen.next(data);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL_AUTHENTICATE, API_URL_CHECK_TOKEN, API_URL_LOGIN, API_URL_SIGNUP, API_URL_VERIFY_OTP_LOGIN } from 'src/app/config/api.routes';

@Injectable()
export class AuthenticationService {

  constructor(private _http: HttpClient) { }

  login(data:any): Observable<any> {
    return this._http.post(API_URL_LOGIN, data);
  }

  authenticate(data:any): Observable<any> {
    return this._http.post(API_URL_AUTHENTICATE, data);
  }

  verifyOtp(data:any): Observable<any> {
    return this._http.post(API_URL_VERIFY_OTP_LOGIN, data);
  }

  signup(data:any): Observable<any> {
    return this._http.post(API_URL_SIGNUP, data);
  }

  checkToken() {
    return this._http.get(API_URL_CHECK_TOKEN);
  }

 
}

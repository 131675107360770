import { Component, OnInit } from '@angular/core';
import { checkActiveSection } from '../../../../utils/helper/navigation.helper';
import { RequestService } from 'src/app/services/request/request.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenService } from 'src/app/services/screen/screen.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
declare var gtag:any;

@Component({
  selector: 'app-assets-home',
  templateUrl: './assets-home.component.html',
  styleUrls: ['./assets-home.component.scss', './assets-home.component.mobile.scss']
})
export class AssetsHomeComponent implements OnInit {

  constructor(private screenService:ScreenService,private requestService:RequestService,private router:Router, private route: ActivatedRoute, private responsiveService: ResponsiveService){}

  menuItemsList:any =[]
  uspCards:any =[]
  showAboutBluAssure = true;
  activeSection = 'ABOUT_BLUASSURE';
  isDesktopView: boolean =  true;


  ngOnInit() {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    this.checkForLatestRequest();
    //dont change the order of menu items list 
    this.menuItemsList = [
      {
        id:1,
        title:"About Assure by BluSmart",
        image: "../../../../../assets/images/about-blusmart.svg",
        isActive: true,
        sectionName:"ABOUT_BLUASSURE"
      },
      {
        id:2,
        title:"Return Calculator",
        image: "../../../../../assets/images/return-calculator-icon.svg",
        isActive: false,
        sectionName: "RETURN_CALCULATOR"
      },
      {
        id:3,
        title:"About Vehicle",
        image: "../../../../../assets/svgs/about-vehicle.svg",
        isActive: false,
        sectionName: "ABOUT_VEHICLE"
      },
      {
        id:4,
        title:"FAQ",
        image: "../../../../../assets/images/faqs-icon.svg",
        isActive: false,
        sectionName: "FAQ"
      }
    ]

    this.uspCards = [
      {
        id:1,
        title:'10 car',
        text:'Minimum buy in',
        img:'../../../../../assets/images/rupee-blue.svg'
      },
      {
        id:2,
        title:'FAME subsidy',
        text:'Subsidised purchase',
        img:'../../../../../assets/images/car-blue.svg'
      },
      {
        id:3,
        title:'14% +',
        text:'Returns (IRR)',
        img:'../../../../../assets/images/percentage-blue.svg'
      },
      {
        id:4,
        title:'Management',
        text:'End to end account',
        img:'../../../../../assets/images/rupee-blue.svg'
      },
      {
        id:5,
        title:'4 Years',
        text:'Time to Maturity',
        img:'../../../../../assets/images/calendar-blue.svg'
      },
      {
        id:6,
        title:'Every Month',
        text:'Advance Payment',
        img:'../../../../../assets/images/rupee-return-blue.svg'
      },
      {
        id:7,
        title:'40% YoY',
        text:'Depreciation Allowance',
        img:'../../../../../assets/images/security-blue.svg'
      },
      {
        id: 8,
        title: 'Assured BuyBack',
        text: 'End of term',
        img: '../../../../../assets/images/security-blue.svg'
      }
    ]
   
    this.route.queryParams.subscribe(params => {
      if(params['type'] ==null)
      {
        let assetHome = 1;
        this.handleMenu(assetHome)
        return;
      }
      this.activeSection = params['type']
      this.menuItemsList[0].isActive = false;
      this.menuItemsList[1].isActive = true;
      this.checkActiveSection(this.activeSection);
    });
  }

  checkForLatestRequest() {
    let userId = localStorage.getItem("userId");
    this.requestService.getLatestRequestStatusFromBE(userId).subscribe((result): any => {
      if (result.statusCode >= 300) {
        Swal.fire({
          title: "Error",
          icon: "info",
          text: result['message'],
        });
      } else {
        let request = result['response'];
        request.forEach((req: any) => {
          if (req.status != null && this.activeSection!='RETURN_CALCULATOR' && !['START', 'INITIATED', 'KYC_INITIATED', 'COMPLETED','PAYMENT_COMPLETED'].includes(req.status)) {
            this.requestService.setLatestRequestStatus(req.status)
            this.requestService.setLatestRequestId(req.requestId);
            // this.router.navigateByUrl('/dashboard/request')
          }
        });
      }
    }, (err: any) => {
      console.log("Error while getting latest request status", err);
      Swal.fire({
        title: "Error",
        icon: "info",
        text: "Technical Error",
      });
    })
  }

  handleMenu(id:number){
    if(id == 3){
      gtag('event', 'ASSURE_APP_ASSETS_KNOW_MORE', {
        'event_category': 'KNOW_MORE_CLICKED',
        'event_label': 'Know More',
        'value': 'User clicked on know more button'   
      })
    }
    for (const iterator of this.menuItemsList) {
        if(iterator.id != id){
          iterator.isActive = false;
        }
        else{
          iterator.isActive = true;
          this.activeSection = iterator.sectionName; 
        }
       
    }
    this.showAboutBluAssure = this.menuItemsList[0].isActive;
  }

  checkActiveSection(sectionName:string){
    return checkActiveSection(sectionName, this.activeSection);
  }

  changeAssetsScreen(screenKey:any){
    if(screenKey == 'INVEST'){
      gtag('event', 'ASSURE_APP_ASSETS_INVEST_NOW', {
        'event_category': 'INVEST_NOW_CLICKED',
        'event_label': 'Invest Now',
        'value': 'User clicked on invest now button'   
      })
    }
    this.screenService.setAssetsScreenName(screenKey);
    this.requestService.setLatestRequestStatus(`INVEST_NOW`);
    // this.router.navigateByUrl("/dashboard/request");
    document.documentElement.scrollTop = 0;
  }

}

<ng-template [ngIf]="isDesktopView">
    <section>
        <div class="card">
            <div class="inner-container">
                <div class="row">
                    <div class="tab-item-details">
                        <div *ngIf="allCarDetails && allCarDetails.length">
                            <app-upfront-payment *ngIf="checkActiveTab('UPFRONT_PAYMENT')"
                                [parentComponentName]="'RETURN_CALCULATOR'" [allCarDetails]="allCarDetails" [apiRes]="apiRes" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-template>
<ng-template [ngIf]="!isDesktopView">
    <section class="mobile-view">
        <div class="tab-item-details">
            <div *ngIf="allCarDetails && allCarDetails.length">
                <app-upfront-payment *ngIf="checkActiveTab('UPFRONT_PAYMENT')"
                    [parentComponentName]="'RETURN_CALCULATOR'" [allCarDetails]="allCarDetails" [apiRes]="apiRes" />
            </div>
        </div>
    </section>
</ng-template>
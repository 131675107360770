import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss', './navbar.component.mobile.scss'],
})
export class NavbarComponent implements OnInit {
  isDesktopView: boolean = true;
  userName: any = '';
  avatarLabel: any = 'A';
  items: MenuItem[] = [];
  @ViewChild('myMenu') myMenu!: Menu;
  icon:string = "fa fa-chevron-down";

  constructor(private router: Router, private userService:UserService, private responsiveService: ResponsiveService){}


  scrollToTop() {
    document.documentElement.scrollTop = 0;
  }

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop=>{
      this.isDesktopView = isDesktop;
    })
    this.userName = localStorage.getItem('firstName');
    if (this.userName.length > 0)
      this.avatarLabel = this.userName.charAt(0).toUpperCase();
    this.populateMenuItems();
  }

  populateMenuItems() {
    this.items = [
      {
        items: [
          {
            label: 'Account Setting',
            icon: 'pi pi-user',
            command: () => {
              // this.update();
            },
          },
          {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => {
              this.logout();
            },
          },
        ],
      },
    ];
  }

  logout(){
    localStorage.clear();
    this.userService.clearValues()
    this.router.navigateByUrl("/auth/login");
  }

  handleIcon(){
    if(this.icon == 'fa fa-chevron-down')
      this.icon = 'fa fa-chevron-up';
    else
      this.icon ='fa fa-chevron-down';
  }

}

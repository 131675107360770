import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateRouteGuard } from './guards/can-activate-route.guard';
import { LoginGuard } from './guards/login-route.guard';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard/assets', pathMatch: 'full',canActivate:[CanActivateRouteGuard] }, // Set the default route to /dashboard
  { path: 'auth', loadChildren: () => import('./main/login/login.module').then(m => m.LoginModule), canActivate:[LoginGuard] },
  { path: 'dashboard', loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate:[CanActivateRouteGuard]},
  // Other routes if you have any
  { path: '**', redirectTo: '/dashboard' }, // Redirect any unknown route to /dashboard

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';
declare var gtag: any;
@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss', './request.component.mobile.scss'],
})
export class RequestComponent implements OnInit {
  // @ViewChildren('.step-current') targetElements: QueryList<ElementRef> | undefined;
  latestReqStatus: any = 'INITIATED';
  activeScreen: any = 'USER_TYPE_SCREEN';
  toggleDocumentModel: any = false;
  togglePaymentSlipUpload: any = false;
  requestTypeSelected: any = '';
  updateBankDetailsToBE: any = false;
  isDesktopView: boolean = true;
  constructor(private requestService: RequestService, private responsiveService: ResponsiveService) { }
  
  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    this.latestReqStatus = this.requestService.getLatestRequestStatus();
    this.switchScreenUsingRequestStatus();
  }

  switchScreenUsingRequestStatus() {
    switch (this.latestReqStatus) {
      case 'INVEST_NOW':
        this.activeScreen = 'INVESTMENT_SCREEN';
        break;
      case 'INITIATED':
        this.activeScreen = 'USER_TYPE_SCREEN';
        break;
      case 'KYC_INITIATED':
        this.activeScreen = 'FILE_UPLOAD_SCREEN';
        break;
      case 'KYC_VERIFICATION_PENDING':
      case 'KYC_VERIFICATION_COMPLETED':
      case 'AGREEMENT_SIGN_PENDING':
      case 'AGREEMENT_UNDER_REVIEW':
        this.activeScreen = 'DOCUMENT_VERIFICATION_SCREEN';
        break;
      case 'PAYMENT_SUMMARY':
        this.activeScreen = 'PAYMENT_DETAILS_SCREEN';
        break;
      case 'AGREEMENT_SIGNED':
        this.activeScreen = 'AGREEMENT_DONE_SCREEN';
        break;
      case 'BANK_DETAILS_PENDING':
      case 'BANK_DETAILS_COMPLETED':
        this.activeScreen = 'BANK_DETAILS_SCREEN';
        break;
      case 'PAYMENT_PENDING':
      case 'PAYMENT_FAILED':
      case 'PAYMENT_SUCCESS':
        this.activeScreen = 'PAYMENT_VERIFY_SCREEN';
        break;
    }
  }
  
  getProgressBarStatus() {
    if(this.latestReqStatus === 'INVEST_NOW')
    {
      return {
        invest: {imgSource: "../../../../assets/svgs/step-current.svg", class: "step-current"},
        document: { imgSource: "../../../../assets/svgs/step-pending.svg", class: "step-pending" },
        paymnet: { imgSource: "../../../../assets/svgs/step-pending.svg", class: "step-pending" },
        return: { imgSource: "../../../../assets/svgs/step-pending.svg", class: "step-pending" },
        agreement: { imgSource: "../../../../assets/svgs/step-pending.svg", class: "step-pending" },
      };
    }
    else if (["START", "INITIATED", "KYC_INITIATED", "KYC_VERIFICATION_PENDING", "KYC_VERIFICATION_FAILED"].includes(this.latestReqStatus || '')) {
      return {
        invest: {imgSource: "../../../../assets/svgs/step-done.svg", class: "step-done"},
        document: { imgSource: "../../../../assets/svgs/step-current.svg", class: "step-current" },
        paymnet: { imgSource: "../../../../assets/svgs/step-pending.svg", class: "step-pending" },
        return: { imgSource: "../../../../assets/svgs/step-pending.svg", class: "step-pending" },
        agreement: { imgSource: "../../../../assets/svgs/step-pending.svg", class: "step-pending" },
      };
    } else if (["KYC_VERIFICATION_COMPLETED", "AGREEMENT_UNDER_REVIEW", "AGREEMENT_SIGN_PENDING"].includes(this.latestReqStatus || '')) {
      return {
        invest: {imgSource: "../../../../assets/svgs/step-done.svg", class: "step-done"},
        document: { imgSource: "../../../../assets/svgs/step-done.svg", class: "step-done" },
        paymnet: { imgSource: "../../../../assets/svgs/step-pending.svg", class: "step-pending" },
        return: { imgSource: "../../../../assets/svgs/step-pending.svg", class: "step-pending" },
        agreement: { imgSource: "../../../../assets/svgs/step-current.svg", class: "step-current" },
      };
    } else if (["COMPLETED", "PAYMENT_COMPLETED"].includes(this.latestReqStatus || '')) {
      return {
        invest: {imgSource: "../../../../assets/svgs/step-done.svg", class: "step-done"},
        document: { imgSource: "../../../../assets/svgs/step-done.svg", class: "step-done" },
        paymnet: { imgSource: "../../../../assets/svgs/step-done.svg", class: "step-done" },
        return: { imgSource: "../../../../assets/svgs/step-done.svg", class: "step-done" },
        agreement: { imgSource: "../../../../assets/svgs/step-done.svg", class: "step-done" },
      };
    } else {
      return {
        invest: {imgSource: "../../../../assets/svgs/step-done.svg", class: "step-done"},
        document: { imgSource: "../../../../assets/svgs/step-done.svg", class: "step-done" },
        paymnet: { imgSource: "../../../../assets/svgs/step-current.svg", class: "step-current" },
        return: { imgSource: "../../../../assets/svgs/step-pending.svg", class: "step-pending" },
        agreement: { imgSource: "../../../../assets/svgs/step-done.svg", class: "step-done" },
      };
    }
  }
  updateLatestReqStatus() {
    this.latestReqStatus = this.requestService.getLatestRequestStatus();
    this.switchScreenUsingRequestStatus();
  }

  handleDocumentsModel(value: string) {
    this.toggleDocumentModel = true;
    this.requestTypeSelected = value;
  }

  hideDocListModel(value: boolean) {
    this.toggleDocumentModel = value;
  }

  handlePaymentSlipModel(value: any) {
    this.togglePaymentSlipUpload = value;
  }

  handlePaymentSlipUpload(isSuccess: any) {
    if (isSuccess) {
      this.handlePaymentSlipModel(false);
      this.updateBankDetailsToBE = true;
      this.requestService.setLatestRequestStatusToBE(localStorage.getItem("latestRequestId"), 'PAYMENT_PENDING').subscribe((res) => {
        if (res['statusCode'] >= 300) {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res['message'] || "Technical Error",
          });
        } else {
          gtag('event', 'ASSURE_APP_PAYMENT_COMPLETED', {
            'event_category': 'PAYMENT_COMPLETED',
            'event_label': 'Payment receipt uploaded',
            'value': 'User uploaded the payment receipt'
          })
          this.requestService.setLatestRequestStatus('PAYMENT_PENDING');

        }
      }, (err: any) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Technical Error",
        });
      })
    } else {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Receipt uploaded failed, Please try again",
      });
      this.handlePaymentSlipModel(false)
    }
    this.latestReqStatus = this.requestService.getLatestRequestStatus();
    this.switchScreenUsingRequestStatus();

  }
}

import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  constructor(private responsive: BreakpointObserver) { }
  isDesktopScreen(): Observable<boolean> {
    return this.responsive.observe([
      Breakpoints.TabletLandscape,
      Breakpoints.HandsetPortrait,
      Breakpoints.WebLandscape
    ]).pipe(
      map((result: { breakpoints: any; }) => {
        const breakpoints = result.breakpoints;

        if (breakpoints[Breakpoints.TabletLandscape]) {
          console.log("Screen matches Tablet");
          return true;
        }
        else if (breakpoints[Breakpoints.HandsetPortrait]) {
          console.log("Screen matches Handset");
          return false;
        }
        else {
          console.log("Screen matches Web");
          return true;
        }
      })
    );
  }
}

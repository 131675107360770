import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FilesService } from 'src/app/services/files/files.service';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-generate-modal',
  templateUrl: './invoice-generate-modal.component.html',
  styleUrls: ['./invoice-generate-modal.component.scss','./invoice-generate-model-component.mobile.scss']
})
export class InvoiceGenerateModalComponent {

  toggleShowInvoiceCreationModal: boolean = true;
  isDesktopView: boolean = true;
  invoiceNumber:string = "";
  enableContinueButton:boolean = false;
  @Output() showGenerateInvoiceUpload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isContinueClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedRecord:any;
  constructor(private fileUploadService: FilesService, private responsiveService: ResponsiveService, private requestService: RequestService) { }

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop
    })
  }
  ngOnChanges(changes: SimpleChanges): void {

  }

  cancel() {
    this.toggleShowInvoiceCreationModal = false;
    this.showGenerateInvoiceUpload.emit(false);
  }

  validInvoiceNo(){
    if(this.invoiceNumber.trim().length >0 ){
      this.enableContinueButton = true;
      console.log("enabled");
    }
    else{
      this.enableContinueButton = false;
      console.log("disabled");
    }
    
  }
  handleContinueButton(){
    if(this.enableContinueButton){
      this.isContinueClicked.emit({
        "invoiceNumber":this.invoiceNumber
      });
      this.cancel()
    }
  }

}
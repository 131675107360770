<app-navbar></app-navbar>
<div class="container" [ngClass]="{'mobile-view':!isDesktopView}">
    <img src="../../../../assets/svgs/contact-us.svg" alt="" srcset="" *ngIf="!isDesktopView">
    <p class="heading">Contact Us</p>
    <p class="sub-heading">Please fill out your inquiries or questions about the Assure program. Our dedicated team will
        get back to you promptly.</p>
    <div class="message-container">
        <form (ngSubmit)="onSubmit()">
            <label for="message">Message</label>
            <textarea id="message" name="message" [(ngModel)]="message" pInputTextarea [autoResize]="true"
                placeholder="Enter your message"></textarea>
            <button class="btn btn-primary">Submit</button>
        </form>
    </div>
</div>
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthenticationService} from 'src/app/services/authentication/authentication.service';
import {ResponsiveService} from 'src/app/services/responsive.service';
import {UserService} from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';
import {MessageService} from 'primeng/api';
import {environment} from '../../../../environments/environment';
import {RequestService} from "src/app/services/request/request.service";

declare var gtag: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [MessageService]
})
export class SignupComponent implements OnInit {
  otpModalVisible: boolean = false;
  isVerified: boolean = false;
  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';
  entity: string = '';
  query: string = '';
  noOfCars: string = '';
  userId: string = '';
  isDesktopView = true;


  constructor(private authenticationService: AuthenticationService, private userService: UserService, private router: Router, private responsiveService: ResponsiveService,
              private requestService: RequestService, private messageService: MessageService) {

  }

  ngOnInit() {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    });
    this.userId = this.userService.getUserId();
  }

  verifyInputs(): void {
    if (
      this.firstName.trim() !== '' &&
      this.lastName.trim() !== '' &&
      /^\d{10}$/.test(this.phoneNumber)
    ) {
      this.isVerified = true;
    } else {
      this.isVerified = false;
    }
  }

  onInputChange(): void {
    this.phoneNumber = this.phoneNumber.replace(/\D/g, '');
    this.verifyInputs();
  }

  sendOTP(resendOtp = false): void {
    if (!this.validateInput()) {
      return;
    }

    gtag('event', 'ASSURE_APP_SIGNUP_DATA_ENTERED', {
      'event_category': 'SIGNUP_FORM_INITIATED',
      'event_label': 'Signup form initiated',
      'value': 'User filled up signup form'
    })

    if (!this.userId) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please try again"
      }).then(() => {
        this.router.navigateByUrl('/auth/login')
      })
    }

    let data: any = {
      "phone": this.phoneNumber,
      "userId": this.userId
    };

    if (resendOtp)
      data['resendOtp'] = true;

    gtag('event', 'ASSURE_APP_PHONE_OTP_REQUESTED', {
      'event_category': 'SIGNUP_FORM_SUBMITTED',
      'event_label': 'Signup form submitted',
      'value': 'User requested otp for signup'
    })

    this.authenticationService.login(data).subscribe((result: any) => {
      if (result.statusCode >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: result['message'] || "SignUp failed",
        });
      } else {
        if (!environment.production) {
          this.messageService.add({severity: 'success', summary: 'OTP', detail: result.response.otp});
        }
        this.otpModalVisible = true;
      }
    }, (error) => {
      console.log("Error validating user while login");
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
    });
  }

  responseFromOtpModal(otpRes: any) {
    this.otpModalVisible = false;
    if (otpRes['isOtpVerified']) {
      this.signUpUser(otpRes)
    } else {
      Swal.fire({
        icon: "error",
        title: "error",
        text: "Something went Wrong !!"
      })
    }

  }

  signUpUser(otpRes: any) {
    let data = {
      "userId": this.userId,
      "firstName": this.firstName,
      "lastName": this.lastName,
      "phone": this.phoneNumber
    }
    this.authenticationService.signup(data).subscribe((result) => {
      if (result.statusCode >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: result['message'] || "SignUp failed",
        });
      } else {
        this.requestService.sendAssureQueries({
          name: this.firstName + " " + this.lastName,
          emailId: result['response']['emailId'],
          phoneNo: this.phoneNumber,
          "query": this.query,
          "entity": this.entity,
          "noOfCars": this.noOfCars
        }).subscribe((result) => {


        })
        this.userService.setAuthToken(result['response']['ssoDetails']['access_token']);
        this.userService.setAccessToken(result['response']['ssoDetails']['access_token']);
        this.userService.setFirstName(result['response']['firstName']);
        this.userService.setLastName(result['response']['lastName']);
        this.userService.setUserId(result['response']['userId']);

        localStorage.setItem('authToken', result['response']['ssoDetails']['access_token']);
        localStorage.setItem('accessToken', result['response']['ssoDetails']['access_token']);
        localStorage.setItem('firstName', result['response']['firstName']);
        localStorage.setItem('lastName', result['response']['lastName']);
        localStorage.setItem('userId', result['response']['userId']);

        gtag('event', 'ASSURE_APP_SIGNUP_SUCCESS', {
          'event_category': 'SIGNUP_SUCCESS',
          'event_label': 'Successfully signed up',
          'value': 'User successfully signed up'
        })


        this.router.navigateByUrl('/dashboard/assets');

      }
    })

  }

  validateInput() {
    const nameRegex = /^[A-Za-z]+$/;
    if (!nameRegex.test(this.firstName)) {
      Swal.fire({
        icon: "error",
        title: "error",
        text: "Please enter correct value in first name"
      })
      return false;
    }
    if (!nameRegex.test(this.lastName)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter correct value in last name"
      })
      return false;
    }
    return true;
  }

  resendOtpClicked() {
    this.sendOTP(true);
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { convertFileSize } from '../../../../utils/helper/files.helper';
import { FilesService } from 'src/app/services/files/files.service';
import Swal from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
declare var gtag:any;


@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss','./document-upload.component.mobile.scss']
})
export class DocumentUploadComponent implements OnInit{
  @Output() changeScreen = new EventEmitter<string>();
  expandedIndex = 0;
  currentDocumentName = '';
  currentDocumentType ='';
  allFilesUploaded = false;
  isSingleFileUploaded = false;
  isDesktopView: boolean = true;
  documentsList = [{
    type: ``,
    documents: [
      {
        name: ``,
        status: ``,
        isOptional: ``,
        type: ``,
        fileName: ``,
        fileSize: ``,
      }]
    }];

  constructor(private fileUploadService: FilesService, private requestService: RequestService, private responsiveService: ResponsiveService) { }
  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    this.generateDocList();
  }

  generateDocList(){
    let requestType = localStorage.getItem('requestType');
    let paymentType = 'UPFRONT_PAYMENT'; 
    this.requestService.getDocumentsList(requestType, paymentType).subscribe((res:any)=>{
      if(res['statusCode']>=300){
        Swal.fire({
          title: "Error",
          icon: "error",
          text:  res['message'] || "Technical Error",
        });
      }else{
        this.documentsList = []
        let docList = res['response']['docDetails'];
        for (const singleDocType of docList) {
            
            let docList =[];
            for (const iterator of singleDocType['doc']) {
                let obj ={
                  name: iterator['docName'],
                  type: iterator['docType'],
                  isOptional: iterator['isOptional'],
                  status: `NOT_VERIFYED`,
                  fileName: "",
                  fileSize: "",
                };
                docList.push(obj);
            }
            let singleDocObj:any = {
              "type": singleDocType['docTitle'],
              "documents":docList
            }
            this.documentsList.push(singleDocObj);
        }
        const firstNotVerifiedDoc = this.findFirstNotVerifiedDocument();
        this.currentDocumentName = firstNotVerifiedDoc
          ? firstNotVerifiedDoc.name
          : '';
        this.currentDocumentType = firstNotVerifiedDoc ? firstNotVerifiedDoc.type :'';
        this.expandedIndex = this.findExpandedIndex(this.currentDocumentName);
      }
    },(err)=>{
      Swal.fire({
        title: "Error",
        icon: "error",
        text:  "Technical Error",
      });
    })
  }

  
  isAllVerified(documents: any[]): boolean {
    return documents.every((doc) => doc.status === 'VERIFIED' || doc.status === 'SKIPPED');
  }

  uploadCompleted(files: any) {
    // file upload api call...
    let userId = localStorage.getItem('userId') || "";
    let requestId = localStorage.getItem('latestRequestId') || "";
    let formData = new FormData();
    formData.append('file', files[0]);
    formData.append('userId',userId );
    formData.append('requestId', requestId);
    formData.append('kycDocType', this.currentDocumentType); //update 
    this.fileUploadService.uploadFile(formData).subscribe((res:any)=>{
      if(res['statusCode']>=300){
        Swal.fire({
          title: "Error",
          icon: "error",
          text:  res['message'] || "Technical Error",
        });
      }else{
        for (const group of this.documentsList) {
          for (const doc of group.documents) {
            if (doc.name === this.currentDocumentName) {
              doc.status = `VERIFIED`;
              doc.fileName = files[0].name;
              doc.fileSize = convertFileSize(files[0].size);
            }
          }
        }
        this.updateIsSingleFileUploaded();
        const nextNotVerifiedDoc = this.findFirstNotVerifiedDocument();
        this.currentDocumentName = nextNotVerifiedDoc
        ? nextNotVerifiedDoc.name
        : '';
        this.currentDocumentType = nextNotVerifiedDoc ? nextNotVerifiedDoc.type :'';
        this.expandedIndex = this.findExpandedIndex(this.currentDocumentName);

      }
    },(err:any)=>{
      Swal.fire({
        title: "Error",
        icon: "error",
        text:  "Technical Error",
      });
    })
   
  }
  getIconSource(status: string): string {
    return status === 'VERIFIED' || status === 'SKIPPED'
      ? '../../../../assets/svgs/check-green.svg'
      : '../../../../assets/svgs/check.svg';
  }

  onDeleteWrapper(doc:any){
    Swal.fire({
      title: "Warning",
      html: `Are you sure you want to <b>Delete</b> this file.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2C66E3",
      cancelButtonColor: "#fff",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      // console.log('result',result);
      if (result.value) {
        this.onDelete(doc)
      }
    });
  }
  onDelete(doc: any): void {
    
    // api call to SOFT DELETE
    doc.status = 'NOT_UPLOADED';
    const nextNotVerifiedDoc = this.findFirstNotVerifiedDocument();
    this.currentDocumentName = nextNotVerifiedDoc
    ? nextNotVerifiedDoc.name
    : '';
    this.currentDocumentType = nextNotVerifiedDoc ? nextNotVerifiedDoc.type :'';
    this.expandedIndex = this.findExpandedIndex(this.currentDocumentName);
    this.updateIsSingleFileUploaded();
  }
  toggleExpansion(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? -1 : index;
  }

  findFirstNotVerifiedDocument(): any {
    for (const group of this.documentsList) {
      for (const doc of group.documents) {
        if (doc.status !== 'VERIFIED') {
          if( doc.status !== 'SKIPPED') {
          return {"name":doc.name, "type":doc.type};
          }
        }
      }
    }
    this.allFilesUploaded = true;
    return null;   
  }
  findExpandedIndex(documentName: string): number {
    for (let i = 0; i < this.documentsList.length; i++) {
      const group = this.documentsList[i];
      for (const doc of group.documents) {
        if (doc.name === documentName) {
          return i;
        }
      }
    }
    return 0;
  }
  lockFilesUploaded() {
    let requestId = localStorage.getItem("latestRequestId");
    this.requestService.setLatestRequestStatusToBE(requestId, `KYC_VERIFICATION_PENDING`).subscribe((result) => {
      if (result.statusCode >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: result['message'] || "Update Status failed",
        });
      } else {
        gtag('event', 'ASSURE_APP_DOCUMENTS_UPLOADED', {
          'event_category': 'ALL_DOCUMENTS_UPLOADED',
          'event_label': 'Proceed',
          'value': 'User uploaded all the requested documents'   
        })
        this.requestService.setLatestRequestStatus(`KYC_VERIFICATION_PENDING`);
        this.changeScreen.emit();
      }
    })
  }
  updateIsSingleFileUploaded() {
    const allVerifiedDocs = this.documentsList
      .flatMap(group => group.documents).some(doc => doc.status === 'VERIFIED');

    this.isSingleFileUploaded = allVerifiedDocs;
  }

  isFileOptional(name: string) {
    for (const doc of this.documentsList) {
      for (const document of doc.documents) {
        if (document.name === name) {
          return document.isOptional;
        }
      }
    }
    return true; 
  }
  skipFileUpload() {
    for (const group of this.documentsList) {
      for (const doc of group.documents) {
        if (doc.name === this.currentDocumentName) {
          doc.status = `SKIPPED`;
          doc.fileName = `File Upload Skipped`;
          doc.fileSize = `Zero`;
        }
      }
    }
    this.updateIsSingleFileUploaded();
    const nextNotVerifiedDoc = this.findFirstNotVerifiedDocument();
    this.currentDocumentName = nextNotVerifiedDoc
    ? nextNotVerifiedDoc.name
    : '';
    this.currentDocumentType = nextNotVerifiedDoc ? nextNotVerifiedDoc.type :'';
    this.expandedIndex = this.findExpandedIndex(this.currentDocumentName);
  }
  
}

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import '../../../../../../src/leegalityv6.js';
import { catchError, delay, of, retryWhen, switchMap, take, tap, throwError, timer } from 'rxjs';
declare const Leegality: any;
declare var gtag:any;

@Component({
  selector: 'app-document-verification',
  templateUrl: './document-verification.component.html',
  styleUrls: ['./document-verification.component.scss', './document-verification.component.mobile.scss']
})
export class DocumentVerificationComponent implements OnInit, OnDestroy {
  @Output() changeScreen = new EventEmitter<string>();
  constructor(private requestService: RequestService, private spinner: NgxSpinnerService, private responsiveService: ResponsiveService) { }
  isDocVerified: boolean = false;
  showAgreementList: boolean = false;
  screen: string = 'KYC_SCREEN';
  interval: any = null;
  isDesktopView: boolean = true;
  isEsignCtaDisabled = true;
  private isComponentAlive = true;
  newWindow:any;
  

  agreements = [
    {
      id: 1,
      name: 'VEHICLE_LEASE_AGREEMENT',
      title: "Vehicle Lease Agreement",
      description: "A Vehicle Lease Agreement is a legal document that records the terms and conditions of a vehicle lease signed by a vehicle owner (the lessor) and Blusmart (the lessee).",
      isSigned: false,
      isOpened: true
    },
    {
      id: 2,
      name: 'SERVICE_AGREEMENT',
      title: "Services Agreement",
      description: "Service Agreement is a legal contract between a client and a service provider. The agreement outlines important details about the business relationship and the duties owed to one another.",
      isSigned: false,
      isOpened: false
    }
  ]

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    if(localStorage.getItem("hasCompletedRequest") && localStorage.getItem("hasCompletedRequest") == "true"){
      this.agreements =[
        {
          id: 1,
          name: 'ADDENDUM',
          title: "Addendum Agreement",
          description: "A Vehicle Lease Agreement is a legal document that records the terms and conditions of a vehicle lease signed by a vehicle owner (the lessor) and Blusmart (the lessee).",
          isSigned: false,
          isOpened: true
        }
      ]
    }else{
      this.agreements = [
        {
          id: 1,
          name: 'VEHICLE_LEASE_AGREEMENT',
          title: "Vehicle Lease Agreement",
          description: "A Vehicle Lease Agreement is a legal document that records the terms and conditions of a vehicle lease signed by a vehicle owner (the lessor) and Blusmart (the lessee).",
          isSigned: false,
          isOpened: true
        },
        {
          id: 2,
          name: 'SERVICE_AGREEMENT',
          title: "Services Agreement",
          description: "Service Agreement is a legal contract between a client and a service provider. The agreement outlines important details about the business relationship and the duties owed to one another.",
          isSigned: false,
          isOpened: false
        }
      ]
    }
    let userId = localStorage.getItem("userId");
    this.requestService.getLatestRequestStatusFromBE(userId).subscribe(async (result) => {
      if (result.statusCode >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: result['message'] || "Update Status failed",
        });
      } else {
        let request = result['response'];
        console.log(request)
        request.forEach((req: any) => {

          if(req.status == `KYC_VERIFICATION_PENDING`){
            this.screen = `KYC_PENDING`;
          }

          if (req.status == `KYC_VERIFICATION_COMPLETED`) {
            this.screen = `KYC_COMPLETED`;
            this.isDocVerified = req['allAgreementSigned'] ? true : false;
          }

          if (req.vehicleLeaseAgreementStatus === "UNDER_REVIEW" || req.vehicleLeaseAgreementStatus === "SIGNED") {
            this.agreements[0].isSigned = true;
          }
          if ((req.serviceAgreementStatus === "UNDER_REVIEW" || req.serviceAgreementStatus === "SIGNED")  && this.agreements.length>1) {
            this.agreements[1].isSigned = true;
          }
        });
        if (this.areAllAgreementsSigned()) {

          this.requestService.setLatestRequestStatus('AGREEMENT_UNDER_REVIEW');
          this.screen = 'AGREEMENT_UNDER_REVIEW';

          for (const agreement of this.agreements) {
            await this.processAgreements(agreement.name);
          }

          this.requestService.setLatestRequestStatus('AGREEMENT_SIGNED');
          this.changeScreen.emit();
        }

      }
    })

  }
  async callback(agreementName: string, response: any) {
    if (response.error) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "e-sign Failed",
      });
    } else {
      this.agreementSignSuccessful(agreementName);
    }
  }

  sign(signUrl: string, obj: any) {
    var leegality = new Leegality(obj);
    leegality.init();
    leegality.esign(signUrl);
  }

  lockFilesUploaded(agreementName: string) {
    const reqId = this.requestService.getLatestRequestId()
    console.log("agreementName" , agreementName);
    this.signLeegalityAgreement(reqId, agreementName);
    
    
  }

  signLeegalityAgreement(reqId:any ,agreementName: string){
    this.requestService.initializeLegalitySignProcess(reqId, agreementName).subscribe((result) => {
      if (result.statusCode >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: result['message'] || "e-sign failed",
        });
      } else {
        let signUrl = result['response'];

        var obj = {
          logoUrl: "d1bw6nzj7jblqg.cloudfront.net/static/footerLogo.png",
          callback: this.callback.bind(this, agreementName)
        };
        this.sign(`${signUrl}?timer=0`, obj);
      }

    }, err => {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
    })
  }

  agreementSignSuccessful(agreementName: string) {
    this.requestService.updateAgreementSigned(this.requestService.getLatestRequestId(), agreementName).subscribe(async (res) => {
      if (res['statusCode'] >= 300) {
        if (res['message'] != "Failed to generate e sign url for agreement") {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res['message'] || "Technical Error",
          });
        }

      } else {
        for (const agreement of this.agreements) {
          if (agreement.name === agreementName) {
            agreement.isSigned = true;
            break;
          }
        }
        if (this.areAllAgreementsSigned()) {
          gtag('event', 'ASSURE_APP_AGREEMENT_COMPLETED', {
            'event_category': 'AGREEMENT_COMPLETED',
            'event_label': 'All agreements completed',
            'value': 'User completed all thre requested agreements'   
          })
          this.requestService.setLatestRequestStatus('AGREEMENT_UNDER_REVIEW');
          this.screen = 'AGREEMENT_UNDER_REVIEW';
          for (const agreement of this.agreements) {
            await this.processAgreements(agreement.name);
          }
          this.requestService.setLatestRequestStatus('AGREEMENT_SIGNED');
          this.changeScreen.emit();
        }
      }
    }, (err: any) => {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
    })
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.isComponentAlive = false
  }

  displayAgreementList() {
    console.log('working')
    this.screen = 'AGREEMENT_LIST';
  }

  toggleAgreement(agreemenToToggle: any) {
    this.agreements.forEach(agreement => {
      if (agreement === agreemenToToggle) {
        agreement.isOpened = !agreement.isOpened;
      } else {
        agreement.isOpened = false;
      }
    });
  }

  areAllAgreementsSigned() {
    for (const agreement of this.agreements) {
      if (!agreement.isSigned) {
        return false;
      }
    }
    return true;
  }

  async processAgreements(agreementNames: string) {
    try {
      let message = '';

      while (message !== 'SUCCESS' && this.isComponentAlive) {
        const requestId = this.requestService.getLatestRequestId();
        const res = await this.requestService.sendLegalityDocId(requestId, agreementNames).toPromise();
        message = res['message'];

        if (res['statusCode'] >= 300 && message !== 'Failed to generate e sign url for agreement') {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res['message'] || "Technical Error",
          });
        }
        // add a delay to poll the server at intervals
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
    } catch (err) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ScreenService } from 'src/app/services/screen/screen.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss','portfolio.component.mobile.scss']
})
export class PortfolioComponent implements OnInit{

  activeScreen = 'SIDE_NAV_DASHBOARD';
  isDesktopView: boolean = true;
  private screenNameSubscription: Subscription;
  
  constructor(private screenService:ScreenService, private responsiveService: ResponsiveService){
    this.screenNameSubscription = this.screenService.portfolioActiveScreenName$.subscribe((data) => {
      this.activeScreen = data;
    });
  }
  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop=>{
      this.isDesktopView = isDesktop
    })
  }

}

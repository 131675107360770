import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';
import { AssetsService } from 'src/app/services/assets/assets.service';
@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss','./payment-details.component.mobile.scss'],
})
export class PaymentDetailsComponent implements OnInit {
  @Output() changeScreen = new EventEmitter<string>();
  disableContinueButton = true;
  isDesktopView: boolean = true;
  totalAmount: number = 0;
  paymnetData = {
    investmentValue: 0,
    noOfCars: 0,
  };
  carVariants:any[] = [];
  vehicleIdToCarMap:any= {};
  constructor(private requestService: RequestService, private responsiveService: ResponsiveService, private assetsService: AssetsService) { }


  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    this.getVehicleDetails()
  }


  getVehicleDetails()
  {
    this.assetsService.getUnitEconmonics().subscribe((result:any)=>{
      if(result.statusCode >= 300){
          Swal.fire({
            title: "Error",
            icon: "error",
            text: result['message'],
          });
        }
      else
      {
        result.response.map((vehicle:any) => {
          this.vehicleIdToCarMap[vehicle.unitFinancialDetailsId] = vehicle.assetName;
        })
        this.getRequestDetails();
      }
      },(err)=>{
        Swal.fire({
          title: "Error",
          icon: "info",
          text: "Technical Error",
        });
      }
    )
  }
  getRequestDetails(){
    let requestId = localStorage.getItem('latestRequestId');
    this.requestService.getRequestDetails(requestId).subscribe((res:any)=>{
     if(res['statusCode']>=300){
      this.disableContinueButton = true;
      Swal.fire({
        title: "Error",
        icon: "info",
        text:  res['message'] || "Technical Error",
      });
     }else{
      this.disableContinueButton = false;
      let details = res['response'];
      this.paymnetData['investmentValue'] = details['totalInvestedByUser'];
      this.paymnetData['noOfCars'] = details['noOfAssets']
      this.totalAmount = details['totalInvestedByUser'];
      res.response.paymentContractList.map((paymentContract:any) => {
        this.carVariants.push({amountInvested:  Math.floor(paymentContract.amountInvestedByUser),
          carName: this.vehicleIdToCarMap[paymentContract.vehicleId],
          carPrice: Math.floor(paymentContract.amountInvestedByUser / paymentContract.noOfAssets),
          numCars: paymentContract.noOfAssets,
          id: paymentContract.id,
          paymentUploadStatus: paymentContract.paymentStatus
        })
      })
     }
    },(err)=>{
      this.disableContinueButton= true;
      Swal.fire({
        title: "Error",
        icon: "info",
        text: "Technical Error",
      });
    })

  }
  

  isCheckboxChecked: boolean = false;

  updateCheckboxStatus(event: Event) {
    this.isCheckboxChecked = (event.target as HTMLInputElement).checked;
  }

  handleScreen(){
    this.requestService.setLatestRequestStatusToBE(localStorage.getItem("latestRequestId"), 'BANK_DETAILS_PENDING').subscribe((res) => {
      if (res['statusCode'] >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res['message'] || "Technical Error",
        });
      } else {
        this.requestService.setLatestRequestStatus('BANK_DETAILS_PENDING');
        this.changeScreen.emit();
      }
    }, (err: any) => {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
    })
  }
}

<ng-template [ngIf]="isDesktopView">
  <div class="container">
    <!-- <img src="../../../../assets/svgs/backBtn.svg" alt="Back Button" class="back-btn" /> -->
    <div class="main-container">
      <img src="../../../../assets/svgs/blu_logo.svg" alt="" class="logo"/>
      <p class="header">Let’s get to know you better</p>
      <span class="sub-title sub-header">Yay, almost done. Please help us <br/> with a few details.</span>
      <div class="form-container">
        <div class="form-item">
          <div class="p-float-label form-element-width">
            <input pInputText type="text" name="first-name" class="form-control customFormInput input-text" value=""
                   placeholder="Enter your First Name" id="first-name" [(ngModel)]="firstName"
                   (ngModelChange)="onInputChange()" maxlength="50"/>
            <label for="first-name">First Name</label>
          </div>
        </div>
        <div class="form-item">
          <div class="p-float-label form-element-width">
            <input pInputText type="text" name="last-name" class="form-control customFormInput input-text" value=""
                   placeholder="Enter your Last Name" id="last-name" [(ngModel)]="lastName"
                   (ngModelChange)="onInputChange()"
                   maxlength="50"/>
            <label for="first-name">Last Name</label>
          </div>
        </div>
        <div class="form-item">
          <div class="dropdown-and-input">
            <select class="form-control dropdown input-text">
              <option value="+91">+91</option>
              <!-- <img src="../../../../assets/svgs/flag_india.svg" alt="" srcset=""> -->
              <!-- <option value="+971">+971</option> -->
            </select>
            <div class="p-float-label form-element-width">
              <input pInputText type="text" name="phone-number" class="form-control customFormInput input-text" value=""
                     placeholder="Enter your Phone Number" id="phone-number" [(ngModel)]="phoneNumber"
                     (ngModelChange)="onInputChange()" pattern="[0-9]{10}" maxlength="10"/>
              <label for="first-name">Phone Number</label>
            </div>
          </div>
        </div>

        <div class="form-item">
          <div class="p-float-label form-element-width">
            <input pInputText type="text" name="no-of-cars" class="form-control customFormInput input-text" value=""
                   placeholder="Enter the number of cars" id="no-of-cars" [(ngModel)]="noOfCars"
                   pattern="[0-9]+"/>
            <label for="first-name">No. of cars</label>
          </div>
        </div>
        <div class="form-item">
          <div class="p-float-label form-element-width">
            <input pInputText type="text" name="entity" class="form-control customFormInput input-text" value=""
                   placeholder="Enter your entity" id="entity" [(ngModel)]="entity"
            />
            <label for="first-name">Enter your entity</label>
          </div>
        </div>
        <div class="form-item">
          <div class="p-float-label form-element-width">
            <textarea rows="3" pInputText type="text" name="query" class="form-control customFormInput input-text"
                      value=""
                      placeholder="Enter your query" id="query" [(ngModel)]="query"
            >
            </textarea>
            <label for="first-name">Enter your query</label>
          </div>
        </div>
        <div class="form-item">
          <button type="button" class="btn btn-primary" [ngClass]="{'btn-disabled': !isVerified}" (click)="sendOTP()">
            Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template [ngIf]="!isDesktopView">
  <div class="mobile-view">
    <div class="main-container">

      <img src="../../../../assets/svgs/blu_logo.svg" alt="" class="logo"/>
      <p class="header">Let’s get to know you better</p>
      <span class="sub-title sub-header">Yay, almost done. Please help us <br/> with a few details.</span>
      <div class="form-container">
        <div class="form-item">
          <div class="p-float-label form-element-width">
            <input pInputText type="text" name="first-name" class="form-control customFormInput input-text" value=""
                   placeholder="Enter your First Name" id="first-name" [(ngModel)]="firstName"
                   (ngModelChange)="onInputChange()" maxlength="50"/>
            <label for="first-name">First Name</label>
          </div>
        </div>

        <div class="form-item">
          <div class="p-float-label form-element-width">
            <input pInputText type="text" name="last-name" class="form-control customFormInput input-text" value=""
                   placeholder="Enter your Last Name" id="last-name" [(ngModel)]="lastName"
                   (ngModelChange)="onInputChange()"
                   maxlength="50"/>
            <label for="first-name">Last Name</label>
          </div>
        </div>

        <div class="form-item">
          <div class="dropdown-and-input">
            <select class="form-control dropdown input-text">
              <option value="+91">+91</option>
              <!-- <img src="../../../../assets/svgs/flag_india.svg" alt="" srcset=""> -->
              <!-- <option value="+971">+971</option> -->
            </select>
            <div class="p-float-label form-element-width">
              <input pInputText type="text" name="phone-number" class="form-control customFormInput input-text" value=""
                     placeholder="Enter your Phone Number" id="phone-number" [(ngModel)]="phoneNumber"
                     (ngModelChange)="onInputChange()" pattern="[0-9]{10}" maxlength="10"/>
              <label for="first-name">Phone Number</label>
            </div>
          </div>
        </div>

        <div class="form-item">
          <button type="button" class="btn btn-primary" [ngClass]="{'btn-disabled': !isVerified}" (click)="sendOTP()">
            Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-otp-modal [receiver]="phoneNumber" [visible]="otpModalVisible" [userId]="userId"
               (verifyClicked)="responseFromOtpModal($event)" (resendOtpClicked)="resendOtpClicked()"
               *ngIf="otpModalVisible"
               [isMobileView]="!isDesktopView"></app-otp-modal>
<p-toast></p-toast>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PortfolioService } from 'src/app/services/portfolio/portfolio.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ScreenService } from 'src/app/services/screen/screen.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss', 'order.component.mobile.scss']
})
export class OrderComponent implements OnInit {
  activeScreen = '';
  isDesktopView: boolean = true;
  private screenNameSubscription: Subscription;
  cols = [{label: 'Order Id', field: 'Order_Id', width: '10%'},
          {label: 'Order Created', field: 'Order_Created', width: '15%'},
          {label: 'Order Description', field: 'Order_Description', width: '30%'},
          {label: 'Order Status', field: 'Order_Status', width: '25%'},
          {label: 'Action', field: 'Action', width: '20%'}
  ]
  requestsTableDto:any = [];

  constructor(private screenService:ScreenService, private responsiveService: ResponsiveService, private portfolioService: PortfolioService, private router: Router){
    this.screenNameSubscription = this.screenService.portfolioActiveScreenName$.subscribe((data) => {
      this.activeScreen = data;
    });
  }

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop=>{
      this.isDesktopView = isDesktop
      this.callAPI();
    })
  }

  callAPI(){
    this.requestsTableDto = [];
    let userId = localStorage.getItem('userId');
    this.portfolioService.getPortfolioData(userId).subscribe((res:any)=>{
      if(res.statusCode >=300){
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res['message'] || "Technical Error",
        })
      }else{
          let requests = res['response']['requests'];
          for (const request of requests) {
            if(request['status'] != 'START'){
              let obj = {orderId: request['requestId'],
                orderCreated: request['requestDetails'][0]['holdingStartDate'],
                orderDescription: request['requestDetails'],
                orderStatus: request['status']
               }
               this.requestsTableDto.push(obj);
          }
          if(request['status'] == 'COMPLETED')
            localStorage.setItem("hasCompletedRequest", "true");

        console.log(this.requestsTableDto);
      } 

    }
    })
  }

clickAction(action: string){
  if(action == 'COMPLETED'){
    this.router.navigate(["/dashboard/portfolio"])
  }
  else{
    this.router.navigate(["/dashboard/request"])
  }
}

}

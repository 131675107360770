<!-- <p class="info" *ngIf="!agreementStatus">Agreement submitted successfully and the document is under verification</p> -->
<div class="agreement-container" [ngClass]="{'mobile-view': !isDesktopView}">

    <div *ngIf="isAgreementSignedYet">
        <div class="header">
            <p class="heading">
                Agreement
            </p>
            <!-- <p class="status" [ngClass]="!agreementStatus ? 'un-verified':''"> -->
            <p class="status">
                <!-- {{ !agreementStatus ? 'Under verification' : 'Verified' }} -->
                Verified
            </p>
        </div>
        <h5 class="sub-header">
            We kindly request you to carefully review the agreement to ensure all the terms and conditions align with
            our mutual understanding. If you have any questions, concerns, or require any clarifications, we encourage
            you to reach out to us directly. Our team is readily available to assist you.
        </h5>
        <br>
        <div class="uploaded-files">
            <p class="uploaded-files-heading">
                Download Files
            </p>
            <div *ngFor="let agreement of agreementData">
                <div class="uploaded-files-content">
                    <p class="uploaded-files-name">
                        BluAssure-Agreement-{{agreement.userId}}-{{agreement.requestId}}-{{agreement.agreementName}}.pdf
                    </p>
                    <div class="action">
                        <!-- <a href="">Preview</a> -->
                        <a class="cursor"
                            (click)="previewAgreement(agreement.requestId, agreement.agreementName)">Preview</a>
                        <a class="cursor"
                            (click)="downloadAgreement(agreement.userId,agreement.requestId,agreement.agreementName)">Download</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isAgreementSignedYet" class="agreement-pending">
        <!-- <img src="../../../../../assets/svgs/agreement-pending.svg" alt="Agreement yet to be signed"> -->
        <img src="../../../../../assets/images/no-return.svg" alt="">
        <p class="pending-header">No Agreement Found</p>
    </div>

</div>
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-visualise-return',
  templateUrl: './visualise-return.component.html',
  styleUrls: ['./visualise-return.component.scss', './visualise-return.mobile.component.scss']
})
export class VisualiseReturnComponent implements OnInit, OnChanges {

  @Input() noOfCars = 10;

  @Input() toggleShowModel: boolean = false;
  @Input() paymentType: string = '';
  @Input() apiRes: any = {
    "assetName": "",
    "unitTotalCost": 0,
    "unitDownPayment": 0,
    "unitLoanAmount": 0,
    "unitEmi": 0,
    "perEmiIrr": 0,
    "irr": 0
  };
  costOfSingleAsset = this.apiRes['unitTotalCost'];
  downPaymentOfSingleAsset = this.apiRes['unitDownPayment'];
  loanAmountByBluSmart = this.costOfSingleAsset - this.downPaymentOfSingleAsset;
  emiToPaidByBlusmart = this.apiRes['unitEmi'];
  preEmiIrr = this.apiRes['perEmiIrr'];
  irrRate = this.apiRes['irr'];
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>;
  tableData: any[] = [];
  showEmi: boolean = this.paymentType == 'DOWN_PAYMENT' ? true : false;

  cols = [
    { field: 'month', header: 'Month', isVisible: true, width: 'auto', exportable: true },
    { field: 'monthlyEarning', header: 'Monthly Earning', isVisible: true, width: 'auto', exportable: true },
    { field: 'gst', header: 'GST', isVisible: true, width: 'auto', exportable: true },
    { field: 'emi', header: 'EMI', isVisible: true, width: 'auto', exportable: true },
    { field: 'netEarning', header: 'Net Earnings(inc GST)', isVisible: true, width: 'auto', exportable: true }
  ]
  constructor(private requestService: RequestService, private router: Router, private responsiveService: ResponsiveService) {
  }


  displayNone: boolean = true;
  isDesktopView: boolean = true;
  gst = 5;
  unitBasedDetails: any = {
    monthlyEarning: 0,
    gstPercentage: 0,
    emi: 0,
    netEarnings: 0,
  }
  returnDetails: any = [
  ];
  lastMonth = null;
  totalMonthlyEarning = 0;
  totalEmi = 0;
  totalNetEarning = 0;
  totalInvestment = 2000000;


  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    this.calculateReturnValues();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['noOfCars']) {
      this.calculateReturnValues()
    }
    if (changes['apiRes']) {
      this.calculateReturnValues()
    }

  }

  calculateReturnValues() {
    if (this.returnDetails.length > 0) {
      this.displayNone = false;
    }
    if (this.paymentType === 'UPFRONT_PAYMENT') {
      this.noOfCars = this.requestService.getNoOfUpFrontPaymentCars();
      this.totalInvestment = this.apiRes.unitTotalCost * this.noOfCars
      this.handleEmiColumn(false)
    }
    else if (this.paymentType === 'DOWN_PAYMENT') {
      this.noOfCars = this.requestService.getNoOfDownPaymentCars();
      this.totalInvestment = this.apiRes.unitDownPayment * this.noOfCars
      this.handleEmiColumn(true)
    }
    const tableRows: any[] = [];
    const datesArray = this.getDates();
    datesArray.forEach((date, index) => {
      const formattedDate = date.toLocaleDateString('en-UK', {
        month: 'long',
        year: 'numeric'
      });
      if (index == 48) {
        let tableRow: any = {
          month: formattedDate,
          monthlyEarning: this.apiRes.perEmiIrr * this.noOfCars * 48,
          gst: this.gst,
          emi: this.paymentType == 'DOWN_PAYMENT' ? this.apiRes.unitEmi * this.noOfCars * 48 : 0,
        }
        let gstVal = tableRow.monthlyEarning * (tableRow.gst / 100);
        tableRow['netEarning'] = (tableRow.monthlyEarning + gstVal - tableRow.emi);
        tableRows.push(tableRow);
      } else {
        let tableRow: any = {
          month: formattedDate,
          monthlyEarning: this.apiRes.perEmiIrr * this.noOfCars,
          gst: this.gst,
          emi: this.paymentType == 'DOWN_PAYMENT' ? this.apiRes.unitEmi * this.noOfCars : 0,
        }
        let gstVal = tableRow.monthlyEarning * (tableRow.gst / 100);
        tableRow['netEarning'] = (tableRow.monthlyEarning + gstVal - tableRow.emi);
        tableRows.push(tableRow);
      }

    });
    let sum = {
      month: "Total",

    }
    tableRows.push()
    this.tableData = tableRows;
    this.returnDetails = this.tableData;
  }

  cancel() {
    this.toggleShowModel = false;
    this.closeModal.emit(false);
  }
  getDates() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    const dates = [];

    for (let i = 0; i < 49; i++) {
      const newDate = new Date(currentDate);
      newDate.setMonth(currentDate.getMonth() + i);
      dates.push(newDate);
    }

    return dates;
  }

  handleEmiColumn(value: boolean) {
    for (const iterator of this.cols) {
      if (iterator.field == 'emi') {
        iterator.isVisible = value;
        iterator.exportable = value;
      }

    }
  }

  goToRequestPage() {
    this.requestService.setNoOfAssets(this.noOfCars);
    this.requestService.setUnitDetailsId(this.apiRes['unitFinancialDetailsId']);
    this.requestService.setPaymentType(this.paymentType);
    this.requestService.setLatestRequestStatus('INVEST_NOW');
    this.router.navigateByUrl("/dashboard/request");
  }

}

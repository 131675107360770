import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-return-calculator',
  templateUrl: './return-calculator.component.html',
  styleUrls: ['./return-calculator.component.scss','./return-calculator.component.mobile.scss']
})
export class ReturnCalculatorComponent implements OnInit{

  @Input() activeSection:string ='';
  isDesktopView: boolean = true;
  tabBarItems:any= [];
  activeTab = 'UPFRONT_PAYMENT';
  apiRes:any = {
    "assetName": "",
    "unitTotalCost":0,
    "unitDownPayment":0,
    "unitLoanAmount":0,
    "unitEmi":0,
    "perEmiIrr":0,
    "irr":0

  }
  allCarDetails: any[] = [];
  constructor(private assetsService: AssetsService, private spinner: NgxSpinnerService, private responsiveService: ResponsiveService) { }

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop=>{
      this.isDesktopView = isDesktop;
    })

    this.tabBarItems = [   
      {
        id:1,
        title:'Full Payment',
        isActive:true,
        tabName:'UPFRONT_PAYMENT'
      },
      {
        id:2,
        title:'Finance your purchase',
        isActive:false,
        tabName:"DOWN_PAYMENT"
      },
    ]
    this.activeTab = this.tabBarItems[0].tabName;
    this.getUnitEconomics();
  }

  getUnitEconomics(){
    this.spinner.show();
    this.assetsService.getUnitEconmonics().subscribe((result:any)=>{
      this.spinner.hide();
      if(result.statusCode >= 300){
          Swal.fire({
            title: "Error",
            icon: "error",
            text: result['message'],
          });
          this.apiRes = {
            "assetName": "",
            "unitTotalCost":0,
            "unitDownPayment":0,
            "unitLoanAmount":0,
            "unitEmi":0,
            "perEmiIrr":0,
            "irr":0
          }
        }else{
          this.apiRes = result['response'][0];
          this.allCarDetails = result['response'];
        }
      
    },(err)=>{
      this.spinner.hide();
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
      this.apiRes = {
        "assetName": "",
        "unitTotalCost":0,
        "unitDownPayment":0,
        "unitLoanAmount":0,
        "unitEmi":0,
        "perEmiIrr":0,
        "irr":0
      }
    })
  }

  handleTabChange(id:number){
    for (const iterator of this.tabBarItems) {
      iterator.isActive =false;
      if(iterator.id ==id){
        iterator.isActive =true;
        this.activeTab = iterator.tabName;
      }
        
    }
  }

  checkActiveTab(tabName:string){
    return tabName == this.activeTab
  }

}

import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PortfolioService } from 'src/app/services/portfolio/portfolio.service';
import { RequestService } from 'src/app/services/request/request.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Swal from 'sweetalert2';



const classOnState: any= {
  SIGNED: 'approved',
  GENERATED: 'uploaded',
  NOT_GENERATED: 'notSigned',
  INITIATED:'notSigned'
  
};

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss','./invoices.component.mobile.scss']
})
export class InvoicesComponent implements OnInit, OnChanges {

  noOfInvoices: number = 0;
  allInvoices: any = []
  displayNone = true;
  isDesktopView: boolean = true;
  gstNumber:string ="";
  toggleInvoiceGenerate:boolean = false;
  toggleInvoiceConcent:boolean = false;
  selectedRecord:any ={
    selectedRequestId:null,
    selectedMonthKey:  null
  }

  concentData:any ={
    selectedRequestId:null,
    selectedMonthKey:  null,
    invoiceNumber: null
  }

  

  constructor(private portfolioService: PortfolioService, private requestService: RequestService, private responsiveService: ResponsiveService) { }

  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
    let userId = localStorage.getItem('userId');
    let requestIds:any = []
    this.getAllInvoices();
  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  getAllInvoices() {   
    this.portfolioService.getAllInvoices().subscribe((res: any) => {
      if (res['statusCode'] >= 300) {
        Swal.fire({
          title: "Error",
          icon: "info",
          text: res['message'] || "Technical Error",
        });
      } else {
        let allData= res['response']
        let invoices:any =[]
        console.log("response ", res['response'])
        for (const iterator of allData) {
          let enableGenerateBtn = true;
          if(iterator['invoiceUrl'] !=  null && iterator['invoiceUrl'].length>0)
            enableGenerateBtn =false;

          invoices.push({
            requestId:iterator['requestId'],
            createdAt:iterator['monthKey'],
            isInvoiceGenerated: !enableGenerateBtn,
            invoiceUrl:iterator['invoiceUrl'],
            status:iterator['status'],
          })
        }
        this.allInvoices =  invoices;
        this.noOfInvoices = res['response'].length;
        this.displayNone = false;
      }
     
    }, (err: any) => {
      Swal.fire({
        title: "Error",
        icon: "info",
        text: "Technical Error",
      });
    })
  }

  applyClass(status:any) {
    return classOnState[status];
  }

  generateInvoice(requestId:any, monthKey:any ){
    this.selectedRecord.selectedMonthKey = monthKey;
    this.selectedRecord.selectedRequestId = requestId;
    this.toggleInvoiceGenerate= true;
  }

  handlePaymentSlipModel(value:any){
    this.toggleInvoiceGenerate = value;
  }

  handlePaymentConcentModel(value:any){
    this.toggleInvoiceConcent = value;
    if(value == false){
      setTimeout(()=>{this.handlePageRefresh(true)},3000)
    }
  }

  handleContinueButtonOnInvoiceModal(value:any){
    this.gstNumber = value.invoiceNumber;
    this.concentData= {
      selectedRequestId:this.selectedRecord.selectedRequestId,
      selectedMonthKey:  this.selectedRecord.selectedMonthKey,
      invoiceNumber: value.invoiceNumber
    }
    this.toggleInvoiceConcent = true;
  }

  handlePageRefresh(value:any){
    setTimeout(()=>this.getAllInvoices(), 5000);
    
  }


}

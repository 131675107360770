import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-agreement-done',
  templateUrl: './agreement-done.component.html',
  styleUrls: ['./agreement-done.component.scss','./agreement-done.component.mobile.scss']
})
export class AgreementDoneComponent implements OnInit {

  @Output() changeScreen = new EventEmitter<string>();
  isDesktopView: boolean = true;
  constructor(private requestService: RequestService, private responsiveService: ResponsiveService) { }
  ngOnInit(): void {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    })
  }
  goToPaymentSummary() {
    this.requestService.setLatestRequestStatus(`PAYMENT_SUMMARY`);
    this.changeScreen.emit();

  }
  downloadAgreement() {
    this.requestService.downloadSignedAgreementCopy(localStorage.getItem('latestRequestId'), 'VEHICLE_LEASE_AGREEMENT').subscribe((res: any) => {
      if (res['statusCode'] >= 300) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res['message'] || "Technical Error",
        });
      } else {
        saveAs(res, `Blu-Assure-agreement-${localStorage.getItem('latestRequestId')}.pdf`);
      }
    }, (err: any) => {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Technical Error",
      });
    })
  }

}

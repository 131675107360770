<div class="document-verification-screen" [ngClass]="{'mobile-view': !isDesktopView}">
    <ng-container [ngSwitch]="screen">
      
        <ng-container *ngSwitchCase="'KYC_PENDING'">
            <img src="../../../../../assets/svgs/doc-verification.svg" alt="" srcset="">
            <div class="heading">
                Thanks for uploading documents!
            </div>
            <br>
            <div class="sub-heading">
                Sit back and relax while your documents are verified. Please note that the process will take up to a week & you will be notified when it's done. Thank you for your patience.
            </div>
        </ng-container>


        <ng-container *ngSwitchCase = "'KYC_COMPLETED'">
            <img src="../../../../../assets/svgs/docVerified.svg" alt="" srcset="" *ngIf="isDocVerified">
            <img src="../../../../../assets/svgs/docPending.svg" alt="" srcset="" *ngIf="!isDocVerified">

            <div class="heading" *ngIf="!isDocVerified">
                Pending Completion: Awaitance of Signature from BluSmart
            </div>
            <div class="heading" *ngIf="isDocVerified">
                Proceed to e-sign your document
            </div>

            <br>
            <div class="sub-heading" *ngIf="!isDocVerified">
                Thank you for submitting your documents. Before proceeding towards agreement, Anmol Jaggi and Amit’s Signatures are required. The agreement is being sent to them, once is it approved, we can begin e-signof  your agreement.
            </div>
            <div class="sub-heading" *ngIf="isDocVerified">
                Thank you for submitting your documents. Before proceeding towards agreement, Anmol Jaggi and Amit’s Signatures are required. The agreement is being sent to them, once is it approved, we can begin e-sign of  your agreement.
            </div>
            <br>
            <button class="btn btn-primary" [ngClass]="{'btn-disabled': !isDocVerified}" [disabled]="!isDocVerified"
                (click)="displayAgreementList()">E - sign your agreement</button>

        </ng-container>

        <ng-container *ngSwitchCase="'AGREEMENT_LIST'">
            <p class="heading">E-sign your agreements</p>
            <p class="sub-heading">Take a moment to thoroughly review the agreement to ensure that all the terms are
                clear
                and satisfactory. We request you to e-sign your agreements to proceed further.</p>
            <div *ngFor="let agreement of agreements" class="agreement-item">
                <div class="agreement-header cursor" (click)="toggleAgreement(agreement)">
                    <div class="agreement-title">
                        <img *ngIf="agreement.isSigned" src="../../../../../assets/svgs/check-green.svg" alt=""
                            srcset="">
                        <img *ngIf="!agreement.isSigned" src="../../../../../assets/svgs/check.svg" alt="" srcset="">
                        <div>{{ agreement.title }}</div>
                    </div>
                    <img *ngIf="agreement.isOpened" src="../../../../../assets/svgs/up-arrow.svg" alt="" srcset="">
                    <img *ngIf="!agreement.isOpened" src="../../../../../assets/svgs/down-arrow.svg" alt="" srcset="">
                </div>
                <div class="agreement-details" *ngIf="agreement.isOpened">
                    <div [innerHTML]="agreement.description"></div>
                    <button class="btn btn-primary cursor" (click)="lockFilesUploaded(agreement.name)"
                        [ngClass]="{ 'btn-disabled': agreement.isSigned }">E - sign</button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'AGREEMENT_UNDER_REVIEW'">
            <img src="../../../../../assets/svgs/agreement-under-review.svg" alt="" srcset="" style="width: 100px;">
            <div class="heading">
                Agreement under review
            </div>
            <div class="sub-heading">
                Thank you for uploading your e-sign. We request you to wait as the document verification will take up to
                5-10 seconds.
            </div>
            <br>
        </ng-container>
    </ng-container>
</div>
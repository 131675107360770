<ng-container *ngIf="isDesktopView">
    <app-navbar></app-navbar>
    <div class="main-container" [ngSwitch]="activeScreen">
        <app-assets-home *ngSwitchCase="'ASSETS_HOME'"></app-assets-home>
        <app-request *ngSwitchCase="'INVEST'"></app-request>
    </div>
</ng-container>
<ng-container *ngIf="!isDesktopView">
    <app-navbar></app-navbar>
    <div class="main-container" [ngSwitch]="activeScreen" style="margin-bottom: 140px;">
        <app-assets-home *ngSwitchCase="'ASSETS_HOME'"></app-assets-home>
        <app-request *ngSwitchCase="'INVEST'"></app-request>
    </div>
</ng-container>